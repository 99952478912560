import React from 'react'
import { Snackbar, SnackbarContent } from '@material-ui/core'
import { green, amber } from '@material-ui/core/colors'
import theme from '../../../../../../services/theme'
import { IResultState } from '../../../Types/NotificationFormTypes'

type variants = 'success' | 'danger' | 'warning'

type SnackProps = {
  config: IResultState
  open: boolean
  onClose: () => void
}

const styles: { [key in variants]: {} } = {
  success: { backgroundColor: green[600] },
  danger: { backgroundColor: theme.palette.error.dark },
  warning: { backgroundColor: amber[700] },
}

const SnackResult: React.SFC<SnackProps> = ({ config, open, onClose }) => {
  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      open={open}
      autoHideDuration={5000}
      onClose={onClose}
    >
      <SnackbarContent
        style={styles[config.variant]}
        aria-describedby="result-snackbar"
        message={
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span id="message-id">{config.message}</span>
            {(() => {
              if (config.description)
                return <small color="">{config.description}</small>
            })()}
          </div>
        }
      />
    </Snackbar>
  )
}

export default SnackResult
