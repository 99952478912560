import React, { useState, useEffect } from 'react'

import { capitalize } from '../../../Utils'

import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import DateFnsUtils from '@date-io/date-fns'
import { format } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { Grid } from '@material-ui/core'
import { IDateTimeInput } from '../../../Types/NotificationFormTypes'

const DateTimeInput: React.SFC<IDateTimeInput> = ({
  info,
  hidden,
  errors,
  handleDate,
}) => {
  const [date, setDate] = useState<MaterialUiPickersDate>(info.dateTime)

  const [mounted, setMounted] = useState(false)
  useEffect(() => setMounted(true), [])

  useEffect(() => {
    setDate(info.dateTime)
  }, [info])

  const handleDateTimeChange = (date: MaterialUiPickersDate) => {
    handleDate(date)
    setDate(date)
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
      {!mounted ? (
        <div />
      ) : (
        <Grid
          container
          spacing={2}
          style={{ display: hidden ? 'none' : 'flex' }}
        >
          <Grid item xs={12}>
            <DateTimePicker
              clearable
              error={errors.date.length != 0}
              label="Data e Horário"
              helperText="Data e horário em que o push será disparado"
              value={date}
              onChange={handleDateTimeChange}
              labelFunc={date =>
                !date
                  ? ''
                  : capitalize(
                      format(date, "EEEE, dd 'de' MMMM 'de' yyyy 'às' HH:mm", {
                        locale: ptBR,
                      }),
                    )
              }
              disablePast={true}
              inputVariant="outlined"
              fullWidth={true}
              minutesStep={15}
              ampm={false}
            />
            <ul style={{ color: '#F44336' }}>
              {(() => {
                if (errors.date.length != 0) {
                  return errors.date.map((error, index) => (
                    <li key={index}>{error}</li>
                  ))
                }
              })()}
            </ul>
          </Grid>
        </Grid>
      )}
    </MuiPickersUtilsProvider>
  )
}

export default DateTimeInput
