import React from 'react'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'

interface ICueDeleteDialogProps {
  // Função para fechar o dialog e passar a resposta
  handleClose: (state?: boolean) => void
  // Se o dialog está vísivel
  openDialog: boolean
}

const CueDeleteDialog: React.FC<ICueDeleteDialogProps> = ({
  openDialog,
  handleClose,
}) => {
  return (
    <Dialog
      open={openDialog}
      onClose={() => handleClose()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {'Tem certeza que deseja excluir esta linha?'}
      </DialogTitle>
      <DialogActions>
        <Button onClick={() => handleClose()}>Não</Button>
        <Button onClick={() => handleClose(true)} autoFocus>
          {'Sim'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CueDeleteDialog
