import React, { useEffect, useState } from 'react'

import { MenuItem } from '@material-ui/core'
import SnackResult from './SnackResult'

import SelectInput from '../../../../../../components/formFields/SelectInput'
import TextInput from '../../../../../../components/formFields/TextInput'

import { fetchUserFromEmail } from '../../../Functions/FormRequest'
import { validateEmail } from '../../../Utils'

import { fbAuth, fbRemote } from '../../../../../../services/firebase'
import { throwNotification } from '../../../Functions/PushSender'
import { schedulePush, updateSchedule } from '../../../Functions/Scheduler'
import {
  IDateTimeErrors,
  IDateTimeValidator,
  IFieldType,
  IFormData,
  INotificationForm,
  IResultState,
  IScheduleInfo,
} from '../../../Types/NotificationFormTypes'
import DateTimeInput from './DateTimeInput'
import PushConfirmation from './PushConfirmation'
import SubmitButtons from './SubmitButtons'

type FormActionType = 'schedule' | 'throw'

const NotificationForm: React.SFC<INotificationForm> = ({
  pushToEdit,
  cancelEditMode,
  groupSelected,
  onProgress,
}) => {
  const TYPE = {
    NORMAL: 'Normal',
    DICIONARIO: 'Dicionario',
    HUGOENSINA: 'Hugo Ensina',
    INTERNAL_LINK: 'Link Interno',
    EXTERNAL_LINK: 'Link Externo',
    SIGN_MESSAGE: 'Mensagem em Libras',
    FULL_SCREEN_SIGN_MESSAGE: 'Mensagem em Libras com Tela Interna',
  }

  const [dialogVisible, setDialogVisible] = useState<boolean>(false)
  const [sendDisabled, setSendDisabled] = useState<boolean>(false)
  const [snackOpened, setSnackOpened] = useState<boolean>(false)
  const [resultState, setResultState] = useState<IResultState>({
    variant: 'success',
    message: 'Push enviado com sucesso',
    description: undefined,
  })

  const [scheduleInfo, setScheduleInfo] = useState<IScheduleInfo>({
    dateTime: null,
    timezone: '-3',
    // Timezone estática como GMT-3 enquanto não é necessário uma implementação mais robusta
    // de mudança de timezone
  })

  const emptyFormData = {
    title: '',
    type: '',
    group: '',
    shortMessage: '',
    longMessage: '',
    imageURL: '',
    link: '',
    signMessage: '',
    emails: '',
    emailData: {
      tokens: [],
      emails: [],
      origin: [],
    },
  }
  const [formData, updateFormData] = useState<IFormData>(emptyFormData)
  const [topics, setTopics] = useState<string[]>([])

  useEffect(() => {
    if (pushToEdit) {
      const { group } = pushToEdit.body
      const { dateTime, timezone } = pushToEdit.scheduleInfo

      validator.link.required =
        group == TYPE.EXTERNAL_LINK || group == TYPE.INTERNAL_LINK
      validator.signMessage.required = group == TYPE.SIGN_MESSAGE

      setScheduleInfo({
        dateTime: new Date(dateTime),
        timezone: timezone,
      })

      updateFormData({
        ...formData,
        ...pushToEdit.body,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pushToEdit])

  const [scheduleValidator, setScheduleValidator] = useState<
    IDateTimeValidator
  >({
    date: { required: groupSelected != 'E-mail' },
    timezone: { required: groupSelected != 'E-mail' },
  })

  const [scheduleErrors, setScheduleErrors] = useState<IDateTimeErrors>({
    date: [],
    timezone: [],
  })

  // PHYLLIPE: Validadores que serão utilizados para validar os inpus ao submeter o form
  const [validator, setValidator] = useState<IFieldType['validator']>({
    title: { required: true, maxLength: 45 },
    type: { required: true },
    group: { required: true },
    shortMessage: { required: true, maxLength: 55 },
    longMessage: { required: true, maxLength: 985 },
    imageURL: { required: false },
    link: {
      required:
        formData.type != TYPE.INTERNAL_LINK ||
        formData.type != TYPE.EXTERNAL_LINK,
    },
    signMessage: { required: formData.type == TYPE.SIGN_MESSAGE },
    emails: { required: false },
  })

  const [fieldErrors, setFieldErrors] = useState<IFieldType['error']>({
    title: [],
    type: [],
    group: [],
    shortMessage: [],
    longMessage: [],
    imageURL: [],
    link: [],
    signMessage: [],
    emails: [],
  })

  const watchForGroupChange = () => {
    const emailSelected = groupSelected === 'E-mail'

    setValidator({
      ...validator,
      emails: { required: emailSelected },
      group: { required: !emailSelected },
    })

    setScheduleValidator({
      date: { required: !emailSelected },
      timezone: { required: !emailSelected },
    })

    const formattedData = {
      ...formData,
      group: emailSelected ? 'E-mail' : '',
    }
    updateFormData(formattedData)
  }

  const addEmailGroup = () => {
    const emailSelected = groupSelected === 'E-mail'

    const formattedData = {
      ...formData,
      group: emailSelected ? 'E-mail' : '',
    }
    updateFormData(formattedData)
    return formattedData
  }

  useEffect(watchForGroupChange, [groupSelected])

  const handleCancelEditMode = () => {
    updateFormData(emptyFormData)
    setScheduleInfo({
      dateTime: null,
      timezone: '',
    })
    cancelEditMode()
  }

  const handleValueChange = (
    event: React.ChangeEvent<{
      name?: string | undefined
      value: unknown
    }>,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    child?: React.ReactNode
  ) => {
    const { value, name } = event.target

    if (name) {
      if (name == 'type') {
        setValidator({
          ...validator,
          link: {
            required:
              value == TYPE.EXTERNAL_LINK || value == TYPE.INTERNAL_LINK,
          },
          signMessage: { required: value == TYPE.SIGN_MESSAGE },
        })
      }

      updateFormData({
        ...formData,
        [name]:
          name != 'emails' ? value : (value as string).trimLeft().trimRight(),
      })
    }
  }

  const scheduleFunction = () => {
    if (fbAuth.currentUser) {
      schedulePush(scheduleInfo, formData)
        .then((res) => {
          console.log(res)
          showSnack(
            'success',
            'Agendado com sucesso',
            scheduleInfo.dateTime!.toString()
          )
          handleCancelEditMode()
        })
        .catch((err: Error) => {
          console.log(err)
          showSnack('danger', 'Ocorreu um erro ao agendar', err.message)
        })
        .finally(() => setOnProgress(false))
    }
  }

  const setOnProgress = (value: boolean) => {
    setSendDisabled(value)
    onProgress(value)
  }

  const validateScheduleInfo = async () => {
    let hasErrors = false
    const errors: IDateTimeErrors = {
      date: [],
      timezone: [],
    }

    if (scheduleValidator.date.required === true) {
      if (!scheduleInfo.dateTime) {
        hasErrors = true
        errors.date.push('Esse campo é necessário')
      }

      if (scheduleInfo.timezone == '') {
        hasErrors = true
        errors.timezone.push('Esse campo é necessário')
      }
    }

    setScheduleErrors(errors)
    return hasErrors
  }

  const validateForm = async (
    type: FormActionType
  ): Promise<{
    hasError: boolean
    errors: IFieldType['error']
  }> => {
    let hasError = false

    if (groupSelected != 'E-mail' && type == 'schedule') {
      hasError = await validateScheduleInfo()
    }

    const errors: IFieldType['error'] = {
      title: [],
      type: [],
      group: [],
      emails: [],
      shortMessage: [],
      longMessage: [],
      imageURL: [],
      link: [],
      signMessage: [],
    }

    // Verificando cada field, se é requerido e se tem tamanho máximo
    Object.keys(validator).forEach((key: string) => {
      const fieldKey = key as keyof IFieldType['validator']
      const { required, maxLength } = validator[fieldKey]
      const fieldValue = formData[fieldKey]

      if (required && fieldValue === '') {
        hasError = true
        errors[fieldKey].push('Esse campo é necessário')
      }
      if (maxLength && fieldValue.length > maxLength) {
        hasError = true
        errors[fieldKey].push(`Tamanho máximo de ${maxLength} caracteres`)
      }
    })

    return new Promise(async (resolve, reject) => {
      if (groupSelected == 'E-mail') {
        const emails = formData.emails.split(',')

        await Promise.all(
          emails.map(async (email: string) => {
            if (email != '') {
              if (!validateEmail(email)) {
                hasError = true
                errors.emails.push(`(${email}) Não é um e-mail válido`)
              } else {
                const userResponse = await fetchUserFromEmail(email)
                const { origin, firebase_token } = userResponse.data
                const { emailData } = formData

                if (!origin || (origin !== 'iOS' && origin !== 'Android')) {
                  hasError = true
                  errors.emails.push(`Não existe registros para "${email}"`)
                } else if (
                  !emailData.tokens.includes(firebase_token as string)
                ) {
                  emailData.tokens.push(firebase_token as string)
                  emailData.emails.push(email)
                  emailData.origin.push(origin)
                }
              }
            }
          })
        )
          .then(() => resolve({ hasError, errors }))
          .catch((err) => reject({ err, hasError, errors }))
      } else resolve({ hasError, errors })
    })
  }

  const handleSubmit = async (
    event: React.FormEvent<HTMLFormElement> | null,
    type: FormActionType = 'schedule'
  ) => {
    if (event) event.preventDefault()
    setOnProgress(true)
    setDialogVisible(false)

    let fieldErrors: IFieldType['error']

    validateForm(type)
      .then((errorResult) => {
        const { hasError, errors } = errorResult
        fieldErrors = errors
        if (!hasError) {
          if (groupSelected !== 'E-mail' && type == 'schedule') {
            scheduleFunction()
          } else sendNotification()
        } else setOnProgress(false)
      })
      .catch((errorResult) => {
        const { err, errors } = errorResult
        fieldErrors = errors
        console.error(err)

        showSnack(
          'danger',
          'Não foi possível verificar os e-mails',
          err.message
        )
        setOnProgress(false)
      })
      .finally(() => {
        setFieldErrors(fieldErrors)
        setOnProgress(false)
      })
  }

  const handleUpdate = async () => {
    setOnProgress(true)

    let fieldErrors: IFieldType['error']

    const displayError = (err: Error) => {
      console.error(err)
      showSnack('danger', 'Ocorreu um erro ao tentar editar', err.message)
      setOnProgress(false)
    }

    validateForm('schedule')
      .then((errorResult) => {
        const { hasError, errors } = errorResult
        fieldErrors = errors

        if (!hasError) {
          updateSchedule(pushToEdit!.id, formData, scheduleInfo)
            .then(() => {
              setOnProgress(false)
              handleCancelEditMode()

              showSnack('success', 'Atualizado com sucesso')
            })
            .catch(displayError)
        } else setOnProgress(false)
      })
      .catch((errorResult) => {
        const { err, errors } = errorResult
        fieldErrors = errors
        displayError(err)
      })
      .finally(() => setFieldErrors(fieldErrors))
  }

  const showSnack = (
    variant: 'success' | 'danger' | 'warning',
    message: string,
    description?: string
  ) => {
    setResultState({
      variant,
      message,
      description,
    })
    setSnackOpened(true)
  }

  const sendNotification = () => {
    let formattedData = formData
    if (groupSelected === 'E-mail') {
      formattedData = addEmailGroup()
    }

    throwNotification(formattedData)
      .then((res) => {
        if (res.status == 200) {
          showSnack(
            'success',
            scheduleInfo.dateTime
              ? 'Agendado com sucesso'
              : 'Enviado com sucesso',
            scheduleInfo.dateTime
              ? scheduleInfo.dateTime.toString()
              : 'Tudo ok!'
          )
          handleCancelEditMode()
        } else {
          showSnack(
            'danger',
            'Ocorreu um erro ao tentar enviar o push',
            res.statusText
          )
        }
      })
      .catch((err) => {
        console.log(err)
        showSnack(
          'danger',
          'Ocorreu um erro ao tentar enviar o push',
          'Erro adicionado ao LOG'
        )
      })
  }

  const handleGetTopicsFromRemoteConfig = async () => {
    await fbRemote.fetchAndActivate()
    const topics = fbRemote.getValue('admin_topic_list').asString()
    setTopics(JSON.parse(topics ?? '[]'))
  }

  useEffect(() => {
    handleGetTopicsFromRemoteConfig()
  }, [])

  return (
    <form onSubmit={handleSubmit} autoComplete="off" style={{ padding: 10 }}>
      <SnackResult
        config={resultState}
        open={snackOpened}
        onClose={() => setSnackOpened(false)}
      />

      <TextInput
        id="push-title"
        label={`Título (${validator.title.maxLength} caracteres)`}
        helperText={`Este titulo irá aparecer tanto na tela interna (Activity) do aplicativo, quando na notificação na tela do usuário. (Máx. de ${validator.title.maxLength} caracteres)`}
        value={formData.title}
        onChange={handleValueChange}
        name="title"
        validator={validator.title}
        errors={fieldErrors.title}
      />

      <TextInput
        id="push-short-message"
        label="Mensagem Curta (40 caracteres)"
        helperText="Este texto irá aparecer apenas na notificação na tela do usuário. Não serve para o texto interno. Lembrando que há um limite de 40 caracteres para não comprometer a visualização em smartphones menores."
        value={formData.shortMessage}
        onChange={handleValueChange}
        name="shortMessage"
        validator={validator.shortMessage}
        errors={fieldErrors.shortMessage}
      />

      <SelectInput
        id="notification-type"
        label="Tipo de notificação"
        helper="Tipo de notificação que irá aparecer para o usuário"
        value={formData.type}
        onChange={handleValueChange}
        name="type"
        validator={validator.type}
        errors={fieldErrors.type}
      >
        <MenuItem value={TYPE.NORMAL} selected>
          Normal
        </MenuItem>
        <MenuItem value={TYPE.DICIONARIO}>Dicionário</MenuItem>
        <MenuItem value={TYPE.HUGOENSINA}>Hugo Ensina</MenuItem>
        <MenuItem value={TYPE.INTERNAL_LINK}>Link Interno</MenuItem>
        <MenuItem value={TYPE.EXTERNAL_LINK}>Link Externo</MenuItem>
        <MenuItem value={TYPE.SIGN_MESSAGE}>Mensagem em Libras</MenuItem>
        <MenuItem value={TYPE.FULL_SCREEN_SIGN_MESSAGE}>
          Mensagem em Libras com Tela Interna
        </MenuItem>
      </SelectInput>

      {groupSelected != 'E-mail' ? (
        <SelectInput
          id="notification-group"
          label="Grupo para notificação"
          helper="Grupo para qual o push será disparado (Android | iOS | General)"
          value={formData.group}
          onChange={handleValueChange}
          name="group"
          validator={validator.group}
          errors={fieldErrors.group}
        >
          {topics?.map((topic, i) => {
            const groupSelectedValue = groupSelected + ` ${topic}`
            return (
              <MenuItem value={groupSelectedValue} key={i}>
                {groupSelectedValue}
              </MenuItem>
            )
          })}
        </SelectInput>
      ) : (
        <TextInput
          id="push-emails"
          label="E-mails para notificação"
          helperText="Insira todos os e-mails para enviar o push, separados por vírgula."
          value={formData.emails}
          onChange={handleValueChange}
          name="emails"
          validator={validator.emails}
          errors={fieldErrors.emails}
        />
      )}

      <TextInput
        id="push-long-message"
        label="Mensagem Longa"
        helperText="Texto utilizado na teça interna (Activity) do aplicativo, quando o usuário irá visualizar a notificação completa com todas as informações."
        value={formData.longMessage}
        onChange={handleValueChange}
        name="longMessage"
        validator={validator.longMessage}
        errors={fieldErrors.longMessage}
      />

      <TextInput
        disabled={formData.type != TYPE.SIGN_MESSAGE}
        hidden={formData.type != TYPE.SIGN_MESSAGE}
        id="push-sign-message"
        label="Mensagem em Libras"
        helperText="Após o usuário entrar na Activity (tela) da notificação e ler a mensagem, poderá apertar no botão para o Hugo poder sinalizar os sinais que desejar."
        value={formData.signMessage}
        onChange={handleValueChange}
        name="signMessage"
        validator={validator.signMessage}
        errors={fieldErrors.signMessage}
      />

      <TextInput
        id="push-image-url"
        type="url"
        label="Imagem customizada (URL)"
        helperText="Essa URL da imagem servirá para carrega-la dinamicamente na imagem da Activity (tela) da Notificação. O tamanho final da imagem será sempre redimensionado para (250 x 250)."
        value={formData.imageURL}
        onChange={handleValueChange}
        name="imageURL"
        validator={validator.imageURL}
        errors={fieldErrors.imageURL}
      />

      <TextInput
        disabled={
          formData.type != TYPE.INTERNAL_LINK &&
          formData.type != TYPE.EXTERNAL_LINK
        }
        hidden={
          formData.type != TYPE.INTERNAL_LINK &&
          formData.type != TYPE.EXTERNAL_LINK
        }
        id="push-internal-link"
        type="url"
        label="Link interno/externo (URL)"
        helperText='Serve apenas quando o tipo de notificação escolhida para "Link Externo" ou "Link Interno"'
        value={formData.link}
        onChange={handleValueChange}
        name="link"
        validator={validator.link}
        errors={fieldErrors.link}
      />

      {(() => {
        if (groupSelected !== 'E-mail')
          return (
            <DateTimeInput
              info={scheduleInfo}
              hidden={groupSelected == 'E-mail'}
              validators={scheduleValidator}
              errors={scheduleErrors}
              handleDate={(value) => (scheduleInfo.dateTime = value)}
              handleTimezone={(value) => (scheduleInfo.timezone = value)}
            />
          )
      })()}

      <SubmitButtons
        pushToEdit={pushToEdit}
        sendDisabled={sendDisabled}
        groupSelected={groupSelected}
        handleUpdate={handleUpdate}
        handleCancelEditMode={handleCancelEditMode}
        setDialogVisible={setDialogVisible}
      />

      <PushConfirmation
        onConfirm={() => handleSubmit(null, 'throw')}
        opened={dialogVisible}
        setOpened={setDialogVisible}
      />
    </form>
  )
}

export default NotificationForm
