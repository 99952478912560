import React, { useState } from 'react'

import {
  Card,
  CardHeader,
  IconButton,
  Collapse,
  Avatar,
  makeStyles,
  Divider,
  List,
  ListItem,
  ListItemText,
  CardActionArea,
} from '@material-ui/core'
import { ExpandMore, ExpandLess } from '@material-ui/icons'

import { IScheduledData } from '../../../Types/NotificationFormTypes'
import { capitalize } from '../../../Utils'
import { ptBR } from 'date-fns/esm/locale'
import { format } from 'date-fns'

interface ICardItem {
  push: IScheduledData
}

const useStyles = makeStyles({
  loadingView: {
    display: 'flex',
    justifyContent: 'center',
    margin: 30,
  },
  root: {
    borderRadius: 0,
    border: '1px solid #e8e8e8',
    boxShadow: 'none',
  },
  info: {
    width: '100%',
  },
})

const CardItem: React.SFC<ICardItem> = ({ push }) => {
  const classes = useStyles()
  const { body, sender, scheduleInfo } = push

  const formatedDate = capitalize(
    format(
      new Date(scheduleInfo.dateTime),
      "EEEE, dd 'de' MMMM 'de' yyyy 'às' HH:mm",
      {
        locale: ptBR,
      },
    ),
  )

  const [expanded, setExpanded] = useState(false)

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  return (
    <Card
      className={classes.root}
      style={{
        marginTop: expanded ? 10 : 0,
        marginBottom: expanded ? 10 : 0,
      }}
    >
      <CardActionArea onClick={handleExpandClick}>
        <CardHeader
          avatar={
            <Avatar
              variant="rounded"
              alt="Imagem do push"
              src={body.imageURL}
              aria-label="recipe"
            >
              {body.title.charAt(0)}
            </Avatar>
          }
          action={
            <IconButton
              disableRipple
              aria-expanded={expanded}
              aria-label="show more"
            >
              {!expanded ? <ExpandMore /> : <ExpandLess />}
            </IconButton>
          }
          title={body.title}
          subheader={body.shortMessage}
        />
      </CardActionArea>

      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Divider />
        <List dense className={classes.info}>
          <ListItem>
            <ListItemText
              primary="Mensagem Longa"
              secondary={body.longMessage}
            />
          </ListItem>
          <ListItem>
            <ListItemText primary="Tipo" secondary={body.type} />
          </ListItem>
          <ListItem>
            <ListItemText primary="Grupo" secondary={body.group} />
          </ListItem>
        </List>
        <Divider />
        <List dense className={classes.info}>
          <ListItem>
            <ListItemText primary="Remetente" secondary={sender.name} />
          </ListItem>
          <ListItem>
            <ListItemText primary="Horário" secondary={formatedDate} />
          </ListItem>
        </List>
      </Collapse>
    </Card>
  )
}

export default CardItem
