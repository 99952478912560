import {
  Chip,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  Snackbar,
  TableHead,
} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import AddIcon from '@material-ui/icons/Add'
import DoneIcon from '@material-ui/icons/CheckCircle'
import DescriptionIcon from '@material-ui/icons/Description'
import VideocamIcon from '@material-ui/icons/Videocam'
import ImageIcon from '@material-ui/icons/Image'
import DeleteIcon from '@material-ui/icons/Delete'
import PlayArrow from '@material-ui/icons/PlayArrow'
import React, { useState, useEffect } from 'react'
import CueDeleteDialog from './CueDeleteDialog'
import Fade from '@material-ui/core/Fade'
import Fab from '@material-ui/core/Fab'

import {
  extract,
  getCuesFromText,
  timeToSeconds,
} from '../../../services/cuesExtractor'
import { cueToSignLanguageCue } from '../../../services/helper'
import {
  CueReviewStatus,
  ISignCue,
  ISignLanguageEditorData,
  ITableCuesEntry,
  VideoOutputFormat,
} from '../../../services/types'

import { glosaApiToken, functionsApi } from '../../../services/constants'
import {
  getMemoryGlosa,
  setMemoryGlosa,
  addNewCue as addCue,
  deleteCue,
  getCuesRefFromKey,
  saveImportedCues,
  saveTableRow,
} from '../../../services/firebaseCaptions'
import { getAnimationCodesFromCuesReviewed } from '../../../services/cuesExtractor'
import FormatSelector from './FormatSelector'
import { signsMemoryAsl, signsMemoryBzs } from '../../../services/infoCommunity'

const acceptedFormats = ['.vtt', '.srt', '.txt']

const styles = () => ({
  root: {
    padding: 10,
    width: '100%',
  },
})

interface ISign extends ISignCue {
  /** Descrição */
  description?: string
  /** Palavra exata */
  displayName?: string
  /** Id do código de animação*/
  id: string
  /** Referência do sinal */
  searchReference?: string
  /** Palavras relacionadas */
  words?: string[]
}

interface ICaptionsEditorProps {
  /** Grava todas as cues gerando um vídeo */
  recordCues: (
    cues: ISignLanguageEditorData[],
    format: VideoOutputFormat,
  ) => void
  /** Callback para ação de executar todos os sinais */
  signAllCues: (cues: ISignLanguageEditorData[]) => void
  /** Callback para ação de executar sinais */
  signCallback: (glosas: ISign[], interpretationSpeed: number) => void
  /** Classes a serem aplicadas no componente */
  classes?: any
  /** key que se refere ao vídeo */
  captionKey: string
  /** Língua do projeto */
  language: string
}

interface IDraggedItem {
  /** Sinal a ser movido de posição */
  sign: ISign
  /** Número correspondente a posição onde o sinal está no momento */
  index: number
}

interface IVisibleField {
  // Campo referente a label
  field: string
  // Nome do campo
  label: string
}

/** Styles down below */
const paperStyle = {
  border: '1px solid #e8e8e8',
  borderRadius: 0,
  boxShadow: 'none',
  flexGrow: 1,
  padding: '4px 5px',
}

const paperTableStyle = {
  border: '1px solid #e8e8e8',
  boxShadow: 'none',
  overflow: 'auto',
}

const containerButtonFabStyle = {
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  margin: '0 8px',
}

const buttonFabStyle = {
  margin: '10px 5px',
}

const chipStyle = {
  margin: '5px',
}

const paperSuggestionStyle = {
  maxHeight: '200px',
  maxWidth: '400px',
  overflow: 'auto',
  zIndex: 1,
}

const listItemStyle = {
  cursor: 'pointer',
  overflow: 'auto',
}

const cellContainerStyle = {
  minHeight: '20px',
}

const actionContainerStyle = {
  display: 'flex',
}

const inputFileStyle = { display: 'none' }

const toolsAreaStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
}

let debouncer: any = 0
let rowIndex = 0
let cuesRef: any = null
let draggedItem: IDraggedItem = {
  index: 0,
  sign: { text: '', type: 'simple-sign', id: '' },
}

const CaptionsEditor: React.FC<ICaptionsEditorProps> = ({
  captionKey,
  classes,
  signCallback,
  signAllCues,
  recordCues,
  language,
}) => {
  const [data, setData] = useState<ITableCuesEntry[]>([])
  const [key, setKey] = useState('')
  const [fieldEditing, setFieldEditing] = useState('')
  const [inputValue, setInputValue] = useState('')
  const [loading, setLoading] = useState(true)
  const [dataLoading, setDataLoading] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [openFormatSelector, setOpenFormatSelector] = useState(false)
  const [suggestions, setSuggestions] = useState<ISign[]>([])

  /**
   * Altera as cues para ser utilizado na tabela
   */
  const setFilteredCues = async (snap: any) => {
    const aux = snap.val()
    const newCues = (aux && aux.cues) || []
    const signLanguageCues = cueToSignLanguageCue(newCues)
    setData(signLanguageCues)

    if (
      signLanguageCues.length ===
      signLanguageCues.filter((x: any) => x.reviewStatus === 'reviewed').length
    ) {
      setDataLoading(true)
      const newData = await getAnimationCodesFromCuesReviewed(
        signLanguageCues,
        language,
      )
      setData(newData)
      setDataLoading(false)
    }

    setLoading(false)
  }

  /** Recebe snapshot das cues e salva os dados na tabela depois de tratar os dados */
  const setCuesFromSnap = async (currentKey: string, language: string) => {
    if (cuesRef) cuesRef.ref.off('value', setFilteredCues)
    cuesRef = await getCuesRefFromKey(currentKey, language)
    cuesRef.ref.on('value', setFilteredCues)
  }

  useEffect(() => {
    if (key !== captionKey) {
      setLoading(true)
      setKey(captionKey)
      setCuesFromSnap(captionKey, language)
    }

    return () => {
      if (cuesRef) cuesRef.ref.off('value', setFilteredCues)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [captionKey])

  /** Sinaliza uma cue da tabela */
  const handleButtonSignClick = (
    glosas: ISignCue[],
    interpretationSpeed: number,
  ) => {
    signCallback(glosas, interpretationSpeed)
  }

  /** Sinaliza todas cues da tabela */
  const handleButtonSignAllClick = () => {
    signAllCues(data)
  }

  /** Sinaliza todas cues da tabela */
  const handleButtonRecordCuesClick = (format: VideoOutputFormat) => {
    recordCues(data, format)
  }

  /* Salva dados editados e trata de acordo com o tipo do input */
  const saveInputData = async (i: number, field: string, type?: string) => {
    let valueToSave: string | number = inputValue
    handleInputState(i)
    resetSuggestions()

    if (type === 'time') {
      valueToSave = timeToSeconds(inputValue)
    } else if (type === 'speed') {
      valueToSave = +valueToSave
      valueToSave = valueToSave >= 1 && valueToSave <= 1.7 ? valueToSave : 0
    }

    if (valueToSave || type === 'time') {
      const newObj = { ...data[i], [field]: valueToSave }
      saveTableRow(key, i, newObj, language)
    }
  }

  /**
   * Recebe um array de cues e adiciona as glosas referentes trazendo da memoria de tradução se disponível,
   * ou consome as glosas da api de tradução
   * @param cues - Array de cues para adicionar as glosas
   */
  const getCuesWithGlosas = async (cues: ITableCuesEntry[], lang: string) => {

    const getTextMemory = (sign: ISignCue) => {
      const textReplace = sign.text.replace('#', '')
      const textUpperCase = textReplace.toUpperCase()
      if(lang === 'bzs') {
        return signsMemoryBzs[textReplace]?.displayName || textUpperCase
      }
      return signsMemoryAsl[textReplace]?.displayName || textUpperCase
    }

    const filterSigns = (signs: ISignCue[]) => {
      return signs.map((sign: ISignCue) => ({
        ...sign,
        text: getTextMemory(sign),
        id: sign.text.replace('#', '') || '',
      }))
    }
          
    for (const cue of cues) {
      const glosasFromMemory = await getMemoryGlosa(cue, lang)
      if (glosasFromMemory && glosasFromMemory.length) {
        cue.reviewStatus = 'inReview'
        cue.glosas = filterSigns(glosasFromMemory)
      } else {
        const postData = new URLSearchParams(`q=${cue.text}&lang=${lang}`)
        const res = await fetch(`${functionsApi}translations/textToGlosa`, {
          body: postData,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            token: glosaApiToken || '',
          },
          method: 'POST',
        })
        const { signs } = await res.json()
        if (signs) cue.glosas = filterSigns(signs)
      }
    }
    return cues
  }

  /* Debouncer do autocomplete - Pesquisa as palavras digitadas no input */
  const handleChange = ({ target }: any, lang: string, isAutocomplete?: boolean) => {
    setInputValue(target.value || '')

    clearTimeout(debouncer)

    if (!target.value || !isAutocomplete) return resetSuggestions()

    if (String(target.value).length >= 1) {
      debouncer = setTimeout(async () => {
        try {
          let signsMemoryByLanguage: any
          if(lang === 'bzs') {
            signsMemoryByLanguage = signsMemoryBzs
          } else {
            signsMemoryByLanguage = signsMemoryAsl
          }
          const inputValue = target.value
          const signs = Object.keys(signsMemoryByLanguage)
            .filter((itemKey: any) => {
              const words = signsMemoryByLanguage[itemKey]?.words

              if (
                (inputValue && words && words.includes(inputValue)) ||
                words?.includes(inputValue.toLowerCase()) ||
                words?.includes(inputValue.toUpperCase())
              ) {
                return itemKey
              }
            })
            .map((itemKey: any) => {
              return {
                ...signsMemoryByLanguage[itemKey],
                id: itemKey,
                words: signsMemoryByLanguage[itemKey]?.words.slice(0, 5),
              }
            })
            signs.sort((currentSign, nextSign) => {
              return nextSign.frequency - currentSign.frequency
            })

          setSuggestions(signs)
        } catch (error) {
          console.log(error)
          resetSuggestions()
        }
      }, 500)
    }
  }

  /**
   * Seleciona qual linha e coluna está no estado de edição e salva qual linha está sendo atualizada no momento,
   * além de mudar o status de revisão da cue para inReview
   */
  const handleInputState = async (i: number, type?: string) => {
    setFieldEditing(type || '')
    const newObj = { ...data[i], reviewStatus: 'inReview' }
    saveTableRow(key, i, newObj as ITableCuesEntry, language)
  }

  const createGlosaTemplate = (signData: any) => {
    const newGlosa: ISign = {
      id: signData.id,
      text: signData.text,
      type: 'simple-sign',
    }

    return newGlosa
  }

  const handlePlaySign = (signData: any) => {
    const newGlosa = createGlosaTemplate(signData)

    signCallback([newGlosa], 1);
}

  /* Ao selecionar uma sugestão do autocomplete, adiciona uma nova glosa a lista de traduções */
  const handleSelectAutocomplete = async (signData: any, indexRow: number) => {
    const newGlosa = createGlosaTemplate(signData)

    const row = {
      ...data[indexRow],
      glosas: [...data[indexRow].glosas, newGlosa],
    }

    saveTableRow(key, indexRow, row, language)

    setInputValue('')
    resetSuggestions()
    focusAutocomplete()
  }

  /* Coloca o foco no input do autocomplete */
  const focusAutocomplete = () => {
    const autocompleteField = document.getElementById('autocompleteField')
    if (autocompleteField) autocompleteField.focus()
  }

  /* Deleta uma ficha(glosa ou datilologia) de uma tradução */
  const handleDeleteChip = async (indexRow: number, indexChip: number) => {
    const row = {
      ...data[indexRow],
      glosas: data[indexRow].glosas.filter(
        (_: ISign, i: number) => i !== indexChip,
      ),
    }
    saveTableRow(key, indexRow, row as ITableCuesEntry, language)

    resetSuggestions()
    focusAutocomplete()
  }

  /* Adiciona uma nova datilologia ao apertar enter no input de autocomplete e limpa espaços inseridos */
  const handleKeyUp = async (e: any, row: ITableCuesEntry, i: number) => {
    if (e.keyCode === 13) {
      clearTimeout(debouncer)
      setInputValue('')
      resetSuggestions()

      const aux = e.target.value.replace(/\n/gi, '')
      if (aux && !aux.includes(' ')) {
        const newGlosa = {
          text: aux.toUpperCase(),
          type: 'fingerspell',
        }
        const newRow = { ...row, glosas: [...row.glosas, newGlosa] }

        saveTableRow(key, i, newRow as ITableCuesEntry, language)
      }
    }
  }

  /* Muda o estado de revisão de um cue(dependendo do valor anterior) e desativa o status de editação da linha atual */
  const handleReviewStatus = async (row: ITableCuesEntry, i: number) => {
    setFieldEditing('')
    const newRow = {
      ...row,
      reviewStatus: row.reviewStatus === 'reviewed' ? 'inReview' : 'reviewed',
    }

    saveTableRow(key, i, newRow as ITableCuesEntry, language)
    console.log('IDIOMA SELECIONADO: ' + language)

    if (newRow.reviewStatus === 'reviewed') {
      // Se a cue estiver revisada, salvamos a tradução no firebase
      const mappedGlosa: string[] = newRow.glosas.map(
        (sign: ISignCue) =>
          `${sign.type === 'simple-sign' ? sign.id : sign.text}`,
      )
      await setMemoryGlosa(newRow.text, mappedGlosa, language)
    }
  }

  /** Dialog de confirmação de exclusão foi cancelado */
  const handleClose = (choice = false) => {
    if (choice) {
      const newData = data.filter(
        (_: ITableCuesEntry, i: number) => i !== rowIndex,
      )
      deleteCue(key, newData, language)
    }
    setOpenDialog(false)
  }

  /** Salva o item que está sendo arrastado no momento */
  const onDragStart = (sign: ISign, index: number) =>
    (draggedItem = {
      index,
      sign,
    })

  /** Muda a posição do item que foi arrastado para a nova posição ao finalizar de arrastar */
  const onDragOver = (row: ITableCuesEntry, index: number) => {
    clearTimeout(debouncer)

    debouncer = setTimeout(() => {
      if (draggedItem.index === index) return

      const newRow: ITableCuesEntry = { ...row }
      const newGlosas: any = newRow.glosas.filter(
        (_: any, k: number) => k !== draggedItem.index,
      )

      newGlosas.splice(index, 0, draggedItem.sign)

      newRow.glosas = newGlosas

      saveTableRow(key, rowIndex, newRow, language)
    }, 300)
  }

  /** Adiciona nova cue a tabela */
  const addNewCue = async () => {
    await addCue(key, data.length, language)
  }

  /** Recebe e trata o arquivo da legenda para gerar a tabela de cues */
  const handleFileInput = ([subtitle]: any) => {
    if (!acceptedFormats.some(x => subtitle.name.endsWith(x))) {
      setOpenSnackbar(true)
      return setTimeout(() => setOpenSnackbar(false), 3000)
    }

    setLoading(true)
    const fileReader = new FileReader()

    fileReader.addEventListener('loadend', async ({ srcElement }: any) => {
      const text = srcElement.result || ''
      const cues: ITableCuesEntry[] = subtitle.name.endsWith('.vtt')
        ? extract(text)
        : getCuesFromText(text)

      const signLanguageCues = cueToSignLanguageCue(cues)
      const cuesWithGlosas = await getCuesWithGlosas(signLanguageCues, language)

      saveImportedCues(key, cuesWithGlosas, language)
      setLoading(false)
    })

    fileReader.readAsText(subtitle)
  }

  /* Verifica se uma coluna tem os requisitos para ser editada e retorna um boolean equivalente a resposta */
  const isEditable = (
    status: CueReviewStatus | undefined,
    rowField: string,
    i: number,
  ): boolean =>
    !!(status !== 'reviewed' && fieldEditing == rowField && rowIndex == i)

  /* Limpa as sugestões do input de autocomplete */
  const resetSuggestions = () => setSuggestions([])

  /** Abre o dialog de exclusão de uma cue */
  const handleClickOpen = () => setOpenDialog(true)

  /** Exibe dialog para seleção de tipo de contêudo e geração do arquivo JSON para exportação */
  const handleButtonJSONGenerator = (origin: string) => {
    setOpenFormatSelector(false)
    const output = data.map(row => ({
      glosa: row.glosas.map(
        (sign: ISignCue) =>
          `${sign.type === 'simple-sign' ? sign.id : sign.text}`,
      ),
      text: row.text,
    }))

    const a = document.createElement('a')
    const file = new Blob([JSON.stringify(output, null, '\t')], {
      type: 'application/json',
    })
    a.href = URL.createObjectURL(file)
    a.download = `sentence_pt-bzs_${origin}.json`
    a.click()
  }

  return (
    <Paper
      style={{ ...paperStyle, padding: !loading ? '4px 5px' : '' }}
      data-testid="paperBackground"
    >
      <Fade
        in={loading || dataLoading}
        style={{
          transitionDelay: loading ? '100ms' : '0ms',
        }}
      >
        <LinearProgress data-testid="progressbar" />
      </Fade>

      {!loading && (
        <div style={{ width: '100%' }}>
          <div style={containerButtonFabStyle}>
            <div>
              <input
                accept=".vtt, .srt, .txt"
                style={inputFileStyle}
                id="raised-button-file"
                type="file"
                onChange={e => {
                  handleFileInput(e.target.files)
                  e.target.value = ''
                }}
              />

              <label htmlFor="raised-button-file">
                <Button
                  variant="contained"
                  component="span"
                  style={{ whiteSpace: 'pre', marginRight: '1rem' }}
                >
                  <span>Importar vtt | txt</span>
                </Button>
              </label>
            </div>

            <div style={{ ...toolsAreaStyle, flexWrap: 'wrap' }}>
              <Tooltip title="Sinalizar todas as traduções">
                <div>
                  <Fab
                    disabled={
                      !data.length ||
                      !data.every(
                        (x: ITableCuesEntry) => x.reviewStatus === 'reviewed',
                      ) ||
                      dataLoading
                    }
                    onClick={handleButtonSignAllClick}
                    size="small"
                    style={buttonFabStyle}
                  >
                    <PlayArrow />
                  </Fab>
                </div>
              </Tooltip>

              <Tooltip title="Adicionar linha">
                <Fab
                  size="small"
                  style={buttonFabStyle}
                  onClick={() => addNewCue()}
                >
                  <AddIcon />
                </Fab>
              </Tooltip>

              <span style={{ marginLeft: '10px' }}>Saída:</span>

              <Tooltip title="Gerar vídeo no formato .webm">
                <div>
                  <Button
                    disabled={
                      !data.length ||
                      !data.every(
                        (x: ITableCuesEntry) => x.reviewStatus === 'reviewed',
                      ) ||
                      dataLoading
                    }
                    onClick={() => handleButtonRecordCuesClick('webm')}
                  >
                    <VideocamIcon />
                  </Button>
                </div>
              </Tooltip>

              <Tooltip title="Gerar sequencia de imagens(png)">
                <div>
                  <Button
                    disabled={
                      !data.length ||
                      !data.every(
                        (x: ITableCuesEntry) => x.reviewStatus === 'reviewed',
                      ) ||
                      dataLoading
                    }
                    onClick={() => handleButtonRecordCuesClick('png')}
                  >
                    <ImageIcon />
                  </Button>
                </div>
              </Tooltip>

              <Tooltip title="Gerar JSON">
                <div>
                  <Button
                    disabled={
                      !data.length ||
                      !data.every(
                        (x: ITableCuesEntry) => x.reviewStatus === 'reviewed',
                      ) ||
                      dataLoading
                    }
                    onClick={() => setOpenFormatSelector(true)}
                  >
                    <DescriptionIcon />
                  </Button>
                </div>
              </Tooltip>
            </div>
          </div>

          <Paper
            className={classes.root}
            style={{
              ...paperTableStyle,
              backgroundColor: data.every(c => c.reviewStatus === 'reviewed')
                ? '#efefef'
                : '',
            }}
          >
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Frase</TableCell>
                  <TableCell>Tradução</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>

              <TableBody>
                {data.map((row: ITableCuesEntry, i: number) => {
                  return (
                    <TableRow
                      key={i + row.text + 'tr'}
                      onClick={() => (rowIndex = i)}
                      style={{
                        backgroundColor:
                          row.reviewStatus === 'reviewed' ? '#efefef' : '',
                      }}
                    >
                      <TableCell key={i + 'text'}>
                        {isEditable(row.reviewStatus, 'text', i) ? (
                          <TextField
                            defaultValue={row.text}
                            onFocus={(e: any) => handleChange(e, language, true)}
                            onChange={(e: any) => handleChange(e, language, true)}
                            onBlur={() => saveInputData(i, 'text')}
                            multiline
                            fullWidth
                            autoFocus
                          />
                        ) : (
                          <Typography
                            style={cellContainerStyle}
                            onClick={() =>
                              row.reviewStatus !== 'reviewed' &&
                              handleInputState(i, 'text')
                            }
                          >
                            {row.text}
                          </Typography>
                        )}
                      </TableCell>

                      <TableCell key={i + 'translation'}>
                        {isEditable(row.reviewStatus, 'translation', i) ? (
                          <div>
                            <div
                              style={{
                                ...actionContainerStyle,
                                flexWrap: 'wrap',
                              }}
                            >
                              {row.glosas.map((el: ISign, j: number) => (
                                <div
                                  key={el.text + 'div' + j}
                                  onDragOver={() => onDragOver(row, j)}
                                >
                                  <Chip
                                    draggable
                                    onDragStart={() => onDragStart(el, j)}
                                    key={el.text + j + 'chip'}
                                    label={el.text.replace('#', '')}
                                    onDelete={() => handleDeleteChip(i, j)}
                                    style={{
                                      ...chipStyle,
                                      cursor: 'grab',
                                      backgroundColor:
                                        (el.type === 'fingerspell' &&
                                          '#FBE9E7') ||
                                        '',
                                    }}
                                  />
                                </div>
                              ))}
                            </div>

                            <TextField
                              onFocus={() => setInputValue('')}
                              onChange={(e: any) => handleChange(e, language, true)}
                              onKeyUp={(e: any) => handleKeyUp(e, row, i)}
                              value={inputValue}
                              id="autocompleteField"
                              multiline
                              fullWidth
                              autoFocus
                            />

                            <Paper style={paperSuggestionStyle}>
                              {inputValue && !!suggestions.length && (
                                <List>
                                  {inputValue &&
                                    suggestions.map(
                                      (el: ISign, index: number) => (
                                        <ListItem
                                          style={listItemStyle}
                                          key={index}
                                        >
                                          <Tooltip title="Sinalizar tradução">
                                            <IconButton
                                              onClick={(e) => {
                                                e.preventDefault()
                                                handlePlaySign(
                                                  {
                                                    text: el.displayName,
                                                    id: el.id
                                                  }
                                                )
                                              }}
                                            >
                                              <PlayArrow />
                                            </IconButton>
                                          </Tooltip>

                                          <Tooltip title="Adicionar tradução">
                                            <IconButton
                                              onClick={() =>
                                                handleSelectAutocomplete(
                                                  {
                                                    text: el.displayName,
                                                    id: el.id
                                                  },
                                                  i,
                                                )
                                              }
                                            >
                                              <AddIcon />
                                            </IconButton>
                                          </Tooltip>
                                          
                                          <ListItemText
                                            primary={el.displayName}
                                            secondary={
                                              el.words
                                                ? el.words.join(', ')
                                                : ''
                                            }
                                          />
                                        </ListItem>
                                      ),
                                    )}
                                </List>
                              )}
                            </Paper>
                          </div>
                        ) : (
                          <div
                            onClick={() =>
                              row.reviewStatus !== 'reviewed' &&
                              handleInputState(i, 'translation')
                            }
                            style={cellContainerStyle}
                          >
                            {row.glosas.map((el: ISign, i: number) => (
                              <Chip
                                key={el.text + 'lda' + i}
                                label={el.text.replace('#', '')} 
                                style={{
                                  ...chipStyle,
                                  backgroundColor:
                                    (el.type === 'fingerspell' && '#FBE9E7') ||
                                    '',
                                }}
                              />
                            ))}
                          </div>
                        )}
                      </TableCell>

                      <TableCell key={i + 'action'}>
                        <div style={actionContainerStyle}>
                          <Tooltip title="Revisar">
                            <div>
                              <IconButton
                                onClick={() => handleReviewStatus(row, i)}
                                disabled={row.reviewStatusDisabled}
                              >
                                <DoneIcon
                                  style={{
                                    color:
                                      row.reviewStatus === 'reviewed'
                                        ? 'green'
                                        : row.reviewStatus === 'inReview'
                                        ? '#f37024'
                                        : '',
                                  }}
                                />
                              </IconButton>
                            </div>
                          </Tooltip>

                          <Tooltip title="Sinalizar tradução">
                            <IconButton
                              onClick={() => {
                                handleButtonSignClick(
                                  row.glosas,
                                  row.interpretationSpeed,
                                )
                              }}
                            >
                              <PlayArrow />
                            </IconButton>
                          </Tooltip>

                          <Tooltip title="Deletar">
                            <IconButton onClick={handleClickOpen}>
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </Paper>

          <Snackbar
            anchorOrigin={{
              horizontal: 'center',
              vertical: 'bottom',
            }}
            open={openSnackbar}
            message={<span>Formato invalido!</span>}
          />
        </div>
      )}

      {/* Caixa de escolha da deleção de uma linha */}
      <CueDeleteDialog openDialog={openDialog} handleClose={handleClose} />

      {/* Caixa de escolha do formado ao gerar JSON */}
      <FormatSelector
        open={openFormatSelector}
        onClose={() => setOpenFormatSelector(false)}
        onSelect={handleButtonJSONGenerator}
      />
    </Paper>
  )
}

export default withStyles(styles)(CaptionsEditor)
