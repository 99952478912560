import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import React, { useState, useEffect } from 'react';
import { format, isAfter, isEqual, subMonths } from 'date-fns';
import {
  isQuarterEnding,
  getPtBrMonthYear,
  formatPeriodToDate,
} from '../../services/helper';
import {
  changeUnitsVersion,
  DEFAULT_UNITS_VERSION,
  getQuarter,
  getYear,
  UnitsVersions,
} from '../../services/model/UnitsEconomics';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import VersionSelect, { VersionSelectProps } from './VersionSelect';

interface IYearSelectProps {
  name: string;
  filterDataUnitsEconomics?: any;
}

interface IDateItem {
  formatted: string;
  pure: Date;
}

type DateArray = IDateItem[];

interface IPeriods {
  month: DateArray;
  year: DateArray;
  quarter: DateArray;
}

export enum Versions {
  version1 = 'Versão 1',
  version2 = 'Versão 2',
}

export enum Filters {
  year = 'Ano',
  quarter = 'Trimestral',
  qoq = 'QOQ',
  month = 'Mensal',
}

export type Filter = 'Ano' | 'Trimestral' | 'Trimestral QOQ' | 'Mensal';
type Placeholder = 'Período inicial' | 'Período final' | Date;

const YearSelect = (props: IYearSelectProps) => {
  const { name } = props;

  const filterTypes: Filter[] = [
    'Mensal',
    'Trimestral',
    'Trimestral QOQ',
    'Ano',
  ];

  const emptyPeriods = {
    month: [],
    year: [],
    quarter: [],
  };

  const [periods, setPeriods] = useState<IPeriods>(emptyPeriods);
  const [filterTypeOpen, setFilterTypeOpen] = useState(false);
  const [filterType, setFilterType] = useState<Filter | 'Filtros'>('Filtros');
  const [filterSelected, setFilterSelected] = useState(false);
  const [initialPeriodOpen, setInitialPeriodOpen] = useState(false);
  const [finalPeriodOpen, setFinalPeriodOpen] = useState(false);
  const [initialPeriodArray, setInitialPeriodArray] = useState<DateArray>([]);
  const [finalPeriodArray, setFinalPeriodArray] = useState<DateArray>([]);
  const [initialPeriodValue, setInitialPeriodValue] = useState<
    Placeholder | Date
  >('Período inicial');
  const [finalPeriodValue, setFinalPeriodValue] = useState<Placeholder | Date>(
    'Período final'
  );

  const getMonthArrayData = () => {
    // pega o Ano automático do firebase
    getYear(name).then((collectionYear: any) => {
      const arrayMonths: DateArray = [];
      const arrayYear: DateArray = [];
      const arrayQuarter: DateArray = [];

      Object.entries(collectionYear).forEach((element: any) => {
        const year: string = element[0];
        const dataByYear = Object.keys(element[1]).sort();

        dataByYear.forEach((month: string) => {
          const monthFormatted = getPtBrMonthYear(year + month + 1);
          const pure = formatPeriodToDate(year + month + '01');

          const formattedQuarter = getQuarter(month);
          if (isQuarterEnding(month)) {
            const formattedYear = '/' + year;
            const quarterAndYear = formattedQuarter + formattedYear;
            arrayQuarter.push({
              formatted: quarterAndYear,
              pure: subMonths(pure, 2),
            });
          }

          const lastMonthOfYear = '12';
          if (month === lastMonthOfYear) {
            arrayYear.push({
              formatted: year,
              pure: subMonths(pure, 11),
            });
          }

          arrayMonths.push({ formatted: monthFormatted, pure });
        });
      });

      setPeriods({
        month: arrayMonths,
        quarter: arrayQuarter,
        year: arrayYear,
      });
    });
  };

  useEffect(getMonthArrayData, []);

  const toLastDayOfYear = (date: Date) => {
    const year = parseInt(format(date, 'yyyy'));
    return formatPeriodToDate(year + '12' + '01');
  };

  const filterDataThroughPeriod = (
    initialPeriod: Date | string,
    finalPeriod: Date | string
  ) => {
    const { filterDataUnitsEconomics } = props;

    if (filterType === Filters.year) {
      finalPeriod = toLastDayOfYear(finalPeriod as Date);
    }

    filterDataUnitsEconomics(
      initialPeriod as Date,
      finalPeriod as Date,
      filterType
    );
  };

  const toggleFilterTypeDrawer = () => {
    setFilterTypeOpen(!filterTypeOpen);
  };

  const toggleInitialPeriodDrawer = () => {
    setInitialPeriodOpen(!initialPeriodOpen);
  };

  const toggleFinalPeriodDrawer = () => {
    setFinalPeriodOpen(!finalPeriodOpen);
  };

  const changeFilterTypeHandler = (value: Filter) => {
    setFilterType(value);

    if (value === Filters.year) {
      setInitialPeriodArray(periods.year);
      setFinalPeriodArray(periods.year);
    } else if (value.includes(Filters.quarter)) {
      setInitialPeriodArray(periods.quarter);
      setFinalPeriodArray(periods.quarter);
    } else {
      setInitialPeriodArray(periods.month);
      setFinalPeriodArray(periods.month);
    }

    setInitialPeriodValue('Período inicial');
    setFinalPeriodValue('Período final');
    setFilterSelected(true);
  };

  const changeInitialPeriodHandler = (value: Date) => {
    const newFinalPeriodArray = initialPeriodArray.filter((date: IDateItem) => {
      const after = isAfter(date.pure, value) || isEqual(date.pure, value);
      // Verifica se o Quarter é igual
      const month = date.pure.getMonth();
      const quarterMatches = filterType.includes(Filters.qoq)
        ? month == value.getMonth()
        : true;
      return after && quarterMatches;
    });

    const actualFinalPeriod = finalPeriodValue;

    // Percorre os valores da nova lista de períodos finais
    // e filtra apenas o valor que está selecionado
    const verifyList = newFinalPeriodArray
      .slice(0, 13)
      .filter((item: any) => item.pure == actualFinalPeriod);

    const listLenght = verifyList.length;

    setInitialPeriodValue(value);
    setFinalPeriodArray(newFinalPeriodArray.slice(0, 13));

    // Se for um valor impossível na nova lista
    // muda o valor para 'Período final'
    listLenght == 0 && setFinalPeriodValue('Período final');
  };

  const changeFinalPeriodHandler = (value: Date) => {
    setFinalPeriodValue(value);
  };

  const [versionSelected, setVersionSelected] = useState<UnitsVersions>(
    DEFAULT_UNITS_VERSION
  );
  const [versionHasChanged, setVersionHasChanged] = useState(false);

  /**
   * Handler para lidar com as mudanças da versão no `VersionSelect`
   * @param version Nova versão selecionada no `VersionSelect`
   */
  const handleUnitsVersionChange: VersionSelectProps['onVersionChange'] = version => {
    const hasChanged = version !== versionSelected;

    if (hasChanged) {
      changeUnitsVersion(version);
      setVersionSelected(version);
      setVersionHasChanged(true);
    }
  };

  return (
    <Grid
      item
      style={{
        paddingLeft: 8,
        textAlign: 'left',
      }}
    >
      <form
        autoComplete='off'
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <VersionSelect onVersionChange={handleUnitsVersionChange} />
        <FormControl style={{ minWidth: 120, marginRight: '10px' }}>
          <Select
            open={filterTypeOpen}
            onClose={toggleFilterTypeDrawer}
            onOpen={toggleFilterTypeDrawer}
            value={filterType}
            variant='outlined'
            style={{ height: '30px' }}
            onChange={e => changeFilterTypeHandler(e.target.value as Filter)}
          >
            <MenuItem value='Filtros' key='Filtros' disabled>
              Filtros
            </MenuItem>
            {filterTypes.map((filter: Filter) => (
              <MenuItem value={filter} key={filter}>
                <em>{filter}</em>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {filterSelected && (
          <>
            <FormControl style={{ minWidth: 120, marginRight: '10px' }}>
              <Select
                open={initialPeriodOpen}
                onClose={toggleInitialPeriodDrawer}
                onOpen={toggleInitialPeriodDrawer}
                value={initialPeriodValue}
                variant='outlined'
                style={{ height: '30px' }}
                onChange={e =>
                  changeInitialPeriodHandler(e.target.value as Date)
                }
                inputProps={{
                  id: 'year',
                  name: 'year',
                }}
              >
                <MenuItem
                  value='Período inicial'
                  key='Período inicial'
                  disabled
                >
                  Período inicial
                </MenuItem>
                {initialPeriodArray.map((element: any) => (
                  <MenuItem value={element.pure} key={element.pure}>
                    <em>{element.formatted}</em>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl style={{ minWidth: 120, marginRight: '10px' }}>
              <Select
                open={finalPeriodOpen}
                onClose={toggleFinalPeriodDrawer}
                onOpen={toggleFinalPeriodDrawer}
                value={finalPeriodValue}
                variant='outlined'
                style={{ height: '30px' }}
                onChange={e => changeFinalPeriodHandler(e.target.value as Date)}
                inputProps={{
                  id: 'year',
                  name: 'year',
                }}
              >
                <MenuItem key='Período final' value='Período final' disabled>
                  Período final
                </MenuItem>
                {finalPeriodArray.map((element: any) => (
                  <MenuItem value={element.pure} key={element.pure}>
                    <em>{element.formatted}</em>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </>
        )}
        <FormControl>
          <Button
            color='primary'
            variant='outlined'
            size='small'
            onClick={() =>
              filterDataThroughPeriod(initialPeriodValue, finalPeriodValue)
            }
            disabled={
              initialPeriodValue === 'Período inicial' ||
              finalPeriodValue === 'Período final' ||
              !filterSelected
            }
          >
            Filtrar
          </Button>
        </FormControl>
      </form>
    </Grid>
  );
};

export default YearSelect;
