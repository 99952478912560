import React, { useState } from 'react'

import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Avatar,
  Grid,
  CardActions,
  Button,
  IconButton,
  Collapse,
  CardActionArea,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  makeStyles,
} from '@material-ui/core'
import { Assignment, ExpandMore, ExpandLess } from '@material-ui/icons'
import {
  DatePicker,
  MuiPickersUtilsProvider,
  DatePickerProps,
} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { ptBR } from 'date-fns/locale'
import { FiltersType } from '../../../Types/NotificationFormTypes'

const useStyles = makeStyles({
  historyCard: {
    borderRadius: 0,
    border: '1px solid #e8e8e8',
    boxShadow: 'none',
  },
  inputPaddingLeft: {
    paddingLeft: 10,
  },
  inputPaddingRight: {
    paddingRight: 10,
  },
  m10: {
    margin: 10,
  },
  mt10: {
    marginTop: 10,
  },
  mt15: {
    marginTop: 15,
  },
  formControl: {
    display: 'flex',
  },
})

const buildMenuItems = (items: string[]): JSX.Element[] => {
  return items.map((itemVal, key) => {
    return (
      <MenuItem key={key} value={itemVal}>
        {itemVal}
      </MenuItem>
    )
  })
}

const DateRangePicker: React.SFC<DatePickerProps> = props => (
  <DatePicker
    {...props}
    autoOk
    fullWidth
    disableToolbar
    variant="inline"
    inputVariant="outlined"
    format="dd/MM/yyyy"
    openTo="date"
  />
)

const HistoryFilter: React.SFC<{
  onFilter: React.Dispatch<React.SetStateAction<FiltersType>>
  filters: FiltersType
  expansion: [boolean, React.Dispatch<React.SetStateAction<boolean>>]
}> = ({ onFilter, filters, expansion }) => {
  const classes = useStyles()

  const [expanded, setExpanded] = expansion

  const [initialDate, handleInitialDate] = useState<Date | undefined>(
    filters.initalDate,
  )
  const [finalDate, handleFinalDate] = useState<Date | undefined>(
    filters.finalDate,
  )
  const [group, setGroup] = useState(filters.group || 'Todos')
  const [type, setType] = useState(filters.type || 'Todos')

  const groupNames = [
    'Todos',
    'Portuguese General',
    'Portuguese Android',
    'Portuguese iOS',
    'English General',
    'English Android',
    'English iOS',
  ]

  const typeNames = [
    'Todos',
    'Normal',
    'Link Interno',
    'Link Externo',
    'Mensagem em Libras',
    'Mensagem em Libras com Tela Interna',
  ]

  const handleExpande = () => setExpanded(!expanded)

  const handleFilterDocs = () => {
    onFilter({
      initalDate: initialDate || undefined,
      finalDate: finalDate || undefined,
      group: group != 'Todos' ? group : undefined,
      type: type != 'Todos' ? type : undefined,
    })
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
      <Grid container>
        <Grid item xs={12}>
          <Card elevation={0} className={classes.historyCard}>
            <CardActionArea onClick={handleExpande}>
              <CardHeader
                avatar={
                  <Avatar variant="rounded" aria-label="recipe">
                    <Assignment />
                  </Avatar>
                }
                action={
                  <IconButton
                    disableRipple
                    aria-expanded={expanded}
                    aria-label="Mostrar mais"
                  >
                    {!expanded ? <ExpandMore /> : <ExpandLess />}
                  </IconButton>
                }
                title="Adicionar Filtros"
                subheader="Filtrar pushs disparados"
              />
            </CardActionArea>

            <Collapse in={expanded} timeout="auto" unmountOnExit>
              <Divider />
              <CardContent className={classes.mt10}>
                <Grid container spacing={5}>
                  <Grid item xs={6}>
                    <DateRangePicker
                      label="Data Inicial"
                      // className={classes.inputPaddingRight}
                      value={initialDate}
                      onChange={value => handleInitialDate(value as Date)}
                      maxDate={finalDate}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <DateRangePicker
                      label="Data Final"
                      // className={classes.inputPaddingLeft}
                      value={finalDate}
                      onChange={value => handleFinalDate(value as Date)}
                      minDate={initialDate}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={5}>
                  <Grid item xs={6}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel id="group-filter-label">Grupo</InputLabel>
                      <Select
                        labelId="group-filter-label"
                        id="group-filter"
                        label="Grupo"
                        value={group}
                        onChange={event =>
                          setGroup(event.target.value as string)
                        }
                      >
                        {buildMenuItems(groupNames)}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={6}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel id="group-type-label">Tipo</InputLabel>
                      <Select
                        labelId="group-type-label"
                        id="group-type"
                        label="Tipo"
                        value={type}
                        onChange={event =>
                          setType(event.target.value as string)
                        }
                      >
                        {buildMenuItems(typeNames)}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />

              <CardActions className={classes.m10}>
                <Button
                  onClick={handleFilterDocs}
                  size="medium"
                  color="primary"
                >
                  Filtrar
                </Button>
              </CardActions>
            </Collapse>
          </Card>
        </Grid>
      </Grid>
    </MuiPickersUtilsProvider>
  )
}

export default HistoryFilter
