import React from 'react'

import Grid from '@material-ui/core/Grid'
import hugoTop from '../../images/hugo-blink.png'
import GlobalTypography from '../../components/GlobalTypography'
import styled from 'styled-components'

const ContainerStyled = styled.div`
  padding: 24px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

interface INotFoundProps {
  admin: boolean
}

const NotFound: React.FC<INotFoundProps> = ({ admin }) => (
  <ContainerStyled>
    <Grid item style={{ textAlign: 'center' }}>
      <img src={hugoTop} alt="Hugo" title="Hugo" />
      <GlobalTypography
        variant="h5"
        style={{ color: '#9e6d5f', fontSize: '1.6em' }}
      >
        {admin
          ? 'A rota acessada não existe. :/'
          : 'O token informado é inválido ou o acesso foi expirado. :/'}
      </GlobalTypography>
      <GlobalTypography component="p" style={{ color: '#9e6d5f' }}>
        Desculpe ;p
      </GlobalTypography>
    </Grid>
  </ContainerStyled>
)

export default NotFound
