import { fbRef } from './firebase'

const aslSignsRef = fbRef.child('signs/asl/infoCommunity')
const bzsSignsRef = fbRef.child('signs/bzs/infoCommunity')

export const signsMemoryAsl: any = {}
export const signsMemoryBzs: any = {}

const addSignAsl = (snap: any) => {
  signsMemoryAsl[snap.key] = snap.val()
}

const removeSignAsl = (snap: any) => {
  delete signsMemoryAsl[snap.key]
}

const addSignBzs = (snap: any) => {
  signsMemoryBzs[snap.key] = snap.val()
}

const removeSignBzs = (snap: any) => {
  delete signsMemoryBzs[snap.key]
}

aslSignsRef.on('child_added', addSignAsl)
aslSignsRef.on('child_removed', removeSignAsl)
bzsSignsRef.on('child_added', addSignBzs)
bzsSignsRef.on('child_removed', removeSignBzs)
