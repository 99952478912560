import React from 'react';
import styled from 'styled-components';
import DashboardBarBlock, {
  IDashboardBarBlockProps,
} from './DashboardBarBlock';
import { Paper, Typography } from '@material-ui/core';

interface IDashboardBarProps {
  data?: IDashboardBarBlockProps[];
  ready?: boolean;
}

const MainContainer = styled(Paper)`
  border: 1px solid #e8e8e8;
  border-radius: 0;
  box-shadow: none;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled(Typography)`
  font-weight: 600;
  margin-bottom: 8px;
  color: #777;
`;

const Bar = (props: IDashboardBarProps) => {
  const { data = [], ready } = props;

  return (
    <div style={{ padding: 8 }}>
      <MainContainer>
        <Title variant='body1'>Units Economics</Title>
        {data.map(props => (
          <DashboardBarBlock key={props.title} {...props} ready={ready} />
        ))}
      </MainContainer>
    </div>
  );
};

export default Bar;
