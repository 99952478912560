import React from 'react';

import Typography from '@material-ui/core/Typography';
import { ThreeBounce } from 'better-react-spinkit';
import styled from 'styled-components';
import { colors } from '../../../../../services/helper';
import StyledTooltip from '../../../../../components/Tooltip/Tooltip';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';

export interface IDashboardBarBlockProps {
  color?: string;
  title?: string;
  value?: string;
  percentage?: string;
  tooltip?: string;
  ready?: boolean;
}

const Title = styled(Typography)`
  font-weight: 500;
  color: #777;
`;

const ContentContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100px;
`;

const percentageColor = (percentage: string) =>
  percentage.includes('+') ? colors.blueBaby : colors.orange;

const DashboardBarBlock = (props: IDashboardBarBlockProps) => {
  const { color, title, percentage = ' ', tooltip = '', ready } = props;
  let { value = '' } = props;

  if (ready && value == '...') value = '?';

  const MainContainer = styled.div`
    display: flex;
    width: 100%;
    margin: 4px;
    margin-bottom: 12px;
    padding: 12px;
    align-items: center;
    justify-content: space-between;
    border-left: 3px solid ${color};
  `;

  return (
    <MainContainer>
      <Title variant='body1'>{title}:</Title>
      {value === '?' ? (
        <div style={{ width: 100 + 'px' }}>
          <StyledTooltip title={tooltip} placement='right' arrow>
            <HelpOutlineOutlinedIcon
              style={{
                fontSize: '25px',
                color: 'rgb(92, 107, 192)',
                width: 20,
              }}
            />
          </StyledTooltip>
        </div>
      ) : value === '...' ? (
        <ThreeBounce
          size={5}
          color='rgb(92, 107, 192)'
          style={{ marginRight: 8 }}
        />
      ) : (
        <ContentContainer>
          <Typography
            variant='body1'
            style={{
              color: 'rgb(92, 107, 192)',
              fontSize: 18,
            }}
          >
            {value.toString()}
            <Typography
              style={{
                color: percentageColor(percentage),
                display: 'inline',
                fontSize: 14,
              }}
            >
              {percentage}
            </Typography>
          </Typography>
        </ContentContainer>
      )}
    </MainContainer>
  );
};

export default DashboardBarBlock;
