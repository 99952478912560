import {
  IFormData,
  IScheduleInfo,
  SnapTypes,
  FilteredSchedulesType,
} from '../Types/NotificationFormTypes'
import { fbRef, fbAuth } from '../../../../services/firebase'

const scheduledPath = 'app/pushs/scheduled'

const originGMT = (date: Date): number => {
  return date.getTimezoneOffset() / 60
}

export const schedulePush = async (
  dateTZInfo: IScheduleInfo,
  body: Omit<IFormData, 'emails' | 'emailData'>,
) => {
  const { uid, displayName } = fbAuth.currentUser!
  const sender = {
    id: uid,
    name: displayName,
  }

  const { dateTime, timezone } = dateTZInfo
  const schDate = dateTime!.toUTCString()

  const scheduleInfo = {
    dateTime: schDate,
    originGMT: originGMT(dateTime!),
    timezone,
  }

  return fbRef.child(scheduledPath).push({
    body,
    sender,
    scheduleInfo,
    createdAt: new Date().toString(),
  })
}

export const listSchedules = async (
  onValue: (value: React.SetStateAction<FilteredSchedulesType>) => void,
) => {
  return fbRef.child(scheduledPath).on('value', snap => {
    const result: FilteredSchedulesType = {
      english: {},
      portuguese: {},
    }

    if (snap) {
      const values: SnapTypes = snap.val()

      if (values) {
        const ptReg = new RegExp('((Portuguese)[ ]?[w]*)')
        const enReg = new RegExp('((English)[ ]?[w]*)')

        Object.keys(values).forEach(key => {
          const schData = values[key as keyof SnapTypes]
          const lang = schData.body.group

          if (ptReg.test(lang)) {
            result.portuguese[key] = schData
          } else if (enReg.test(lang)) {
            result.english[key] = schData
          }
        })
      }
    }

    onValue(result)
  })
}

export const cancelScheduled = async (key: string) => {
  return fbRef
    .child(scheduledPath)
    .child(key)
    .remove()
}

export const updateSchedule = async (
  id: string,
  data: Omit<IFormData, 'emails' | 'emailData'>,
  scheduleInfo: IScheduleInfo,
) => {
  return new Promise((resolve, reject) => {
    try {
      const pushRef = fbRef.child(scheduledPath).child(id)
      pushRef
        .child('body')
        .update(data)
        .then(() => {
          const { dateTime, timezone } = scheduleInfo
          resolve(
            pushRef.child('scheduleInfo').update({
              dateTime: dateTime!.toUTCString(),
              originGMT: originGMT(dateTime!),
              timezone,
            }),
          )
        })
    } catch (err) {
      reject(err)
    }
  })
}
