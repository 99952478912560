import styled from 'styled-components'

export const DesktopContainer = styled.div`
  @media (max-width: 720px) {
    display: none;
  }
`

export const MobileContainer = styled.div`
  display: none;
  @media (max-width: 720px) {
    display: block;
  }
`

export const DesktopBarContainer = styled.div`
  @media (max-width: 840px) {
    display: none;
  }
`

export const MobileBarContainer = styled.div`
  display: none;
  @media (max-width: 840px) {
    display: block;
  }
`

export const MobileBlockContainer = styled.div`
  display: none;
  @media (max-width: 720px) {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
`

export const DesktopBlockContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(${(1 / 3) * 100}% - 28px);
  height: 140px;
  margin-bottom: 10px;
  @media (max-width: 780px) {
    height: 160px;
  }
  @media (max-width: 720px) {
    display: none;
  }
`
