import React from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import { BrowserRouter, Switch } from 'react-router-dom';

import { withAuth } from '../services/containers/AuthContainer';

interface IStyles {
  preloaderWrapper: React.CSSProperties;
}

const styles: IStyles = {
  preloaderWrapper: {
    height: '100%',
    position: 'fixed',
    width: '100%',
  },
};

const Router: React.SFC<{}> = withAuth(({ auth, children }) => {
  // Renderiza um preloader no meio da página até o Firebase inicializar
  if (!auth.state.isLoaded) {
    return (
      <Grid
        container
        justify='center'
        alignItems='center'
        style={styles.preloaderWrapper}
      >
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <BrowserRouter>
      <Switch>{children}</Switch>
    </BrowserRouter>
  );
});

export default Router;
