import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
} from '@material-ui/core'
import React, { Fragment, ReactNode } from 'react'

interface IVideoGeneratorProps {
  children: ReactNode
  open: boolean
  onClose: () => void
}

/** Componente responsavel por gerar um vídeo do canvas */
const VideoGenerator: React.FC<IVideoGeneratorProps> = ({
  children,
  open,
  onClose,
}) => {
  return (
    <div>
      {/** Não utilizamos `open` para impedir que o elemento do Core seja destruido */}
      <Dialog open={open}>
        <DialogTitle>Gerando vídeo</DialogTitle>
        <DialogContent>
          <Fragment>
            <LinearProgress variant="indeterminate" />
            {children}
          </Fragment>
        </DialogContent>
        <DialogActions>
          <Button color="default" onClick={onClose}>
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default VideoGenerator
