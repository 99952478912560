import React from "react";
import styled from "styled-components";

import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

const StyledRow = styled(TableRow)`
  cursor: default;
`;

// Parte superior da lista, legenda dos dados
const ListHead = () => (
  <TableHead>
    <StyledRow>
      <TableCell>Token</TableCell>
      <TableCell>Empresa</TableCell>
      <TableCell>Data inicial</TableCell>
      <TableCell>Data de validade</TableCell>
      <TableCell>Tipo</TableCell>
      <TableCell>Descrição</TableCell>
      <TableCell>Status</TableCell>
    </StyledRow>
  </TableHead>
);

export default ListHead;