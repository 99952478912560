import md5 from 'md5'

import { fbRef } from './firebase'
import logger from './logger'
import {
  CueReviewStatus,
  ISignCue,
  ITableCuesEntry,
  TranslationSignType,
} from './types'
import { defaultInterpreterSpeed } from './constants'
import { signsMemoryAsl, signsMemoryBzs } from './infoCommunity'

// caminho do firebase
const captionsRef = fbRef.child('captions')
const memoryRef = captionsRef.child('memory')
const projectsRef = captionsRef.child('projects')

const debug = logger('firebaseCaptions')

/**
 * Pega a glosa da memoria de tradução exclusiva de legendas
 * @param text - Texto em português
 */
export const getMemoryGlosa = async (cue: any, lang: string) => {
  var mappedGlosas: ISignCue[]

  for(let text in cue.text) {
    // Salvamos a chave em md5 para evitar problemas com caracteres não aceitos pelo firebase
    const textHash = md5(cue.text)
    const snap = await memoryRef.child(lang).child(textHash).once('value')
    const glosas = snap.val()

    // Senão existerem glosas retornamos
    if (!glosas) return

    mappedGlosas = (glosas).map((glosa: any) => ({
      text: glosa.text.replace('#', ''),
      id: glosa.signId || '',
      type: (glosa.signId
        ? 'simple-sign'
        : 'fingerspell') as TranslationSignType,
    }))

    debug.log(`Glosas obtidas na memoria de tradução: "${glosas}"`)

    return mappedGlosas
  }
}

/**
 * Seta ou sobrescreve as glosas de um texto na memoria de tradução
 * @param text - Texto em português
 * @param glosas - Array de glosas
 */
export const setMemoryGlosa = async (text: string, glosas: string[], lang: string) => {
  // Salvamos a chave em md5 para evitar problemas com caracteres não aceitos pelo firebase
  const textHash = md5(text)
  const signsMemory = lang === 'bzs' ? signsMemoryBzs: signsMemoryAsl
  const signs = glosas.map((glosa: string) => {
    const signId = signsMemory[glosa] ? glosa : null
    return { signId, text: signId ? signsMemory[glosa].displayName : glosa }
  })

  await memoryRef.child(lang).child(textHash).set(signs)
  debug.log(`Salvo na memoria de tradução: ${text}} = ${glosas}`)
}

/**
 * Consome todas as captions do firebase e retorna como array de objetos
 */
export const getCaptions = async (lang: string) => {
  const res = await projectsRef.child(lang).once('value')
  const captions = res.val()
  return Object.keys(captions).map(prop => ({ ...captions[prop], key: prop }))
}

/**
 * Retorna a referencia da caption
 */
export const getCuesRefFromKey = async (key: string, lang: string) => {
  return await projectsRef.child(lang).child(key).once('value')
}

/**
 * Cria novo vídeo na pasta de projetos dentro de captions
 */

export const createNewCaption = async (name: string, lang: string) => {
  const snap = await projectsRef.child(lang).push({ name, lang }).once('value')
  return snap.key as string
}

/**
 * Cria nova linha na lista de cues
 */
export const addNewCue = async (key: string, index: number, language: string) => {
  const cue = {
    startTime: 0,
    endTime: 0,
    glosas: '',
    text: '',
    interpretationSpeed: defaultInterpreterSpeed,
    reviewStatus: 'reviewPending' as CueReviewStatus,
    animationCodes: [],
  }

  await projectsRef
    .child(language)
    .child(key)
    .child('cues')
    .child(index.toString())
    .set(cue)
}

// TODO - Adicionar formatação de texto
const formatCues = (cue: any) =>
  cue.glosas.map((sign: ISignCue) => ({
    text:
      sign.type === 'simple-sign'
        ? '#' + sign.text.replace('#', '').toUpperCase()
        : '' + sign.text,
    id: sign.id || '',
  }))
/**
 * Salva as cues no firebase de um arquivo convertido vtt ou txt
 */
export const saveImportedCues = async (
  key: string,
  cues: ITableCuesEntry[],
  language: string
) => {
  const formattedCues = cues.map(cue => ({
    endTime: cue.endTime,
    glosas: formatCues(cue),
    startTime: cue.startTime,
    text: cue.text,
    interpretationSpeed: cue.interpretationSpeed,
    reviewStatus: cue.reviewStatus || 'reviewPending',
  }))

  await projectsRef
    .child(language)
    .child(key)
    .child('cues')
    .set(formattedCues)
}

/**
 * Atualiza os dados de uma cue através da key
 */
export const saveTableRow = async (key: string, index: number, cue: any, language: string) => {
  await projectsRef
    .child(language)
    .child(key)
    .child('cues')
    .child(index.toString())
    .update({
      endTime: cue.endTime,
      glosas: formatCues(cue),
      startTime: cue.startTime,
      text: cue.text,
      interpretationSpeed: cue.interpretationSpeed,
      reviewStatus: cue.reviewStatus || ('reviewPending' as CueReviewStatus),
    })
}

/**
 * Cria novo vídeo na pasta de projetos dentro de captions
 */
export const deleteCue = async (key: string, cues: ITableCuesEntry[], language: string) => {
  const newCues = [...cues]
  const formattedCues = newCues.map((cue: any) => ({
    startTime: cue.startTime,
    endTime: cue.endTime,
    interpretationSpeed: cue.interpretationSpeed,
    reviewStatus: cue.reviewStatus || ('reviewPending' as CueReviewStatus),
    text: cue.text,
    glosas: formatCues(cue),
  }))

  await projectsRef
    .child(language)
    .child(key)
    .child('cues')
    .set(formattedCues)
}
