import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@material-ui/core';
import { colors } from '../../../../services/helper';

interface IDialog {
  open: boolean;
  handleClose: () => void;
  title: string;
  description: string;
}

const DialogAlert = (props: IDialog) => {
  return (
    <Dialog
      {...props}
      aria-labelledby='dialog-title'
      aria-describedby='dialog-description'
    >
      <DialogTitle id='dialog-title'>{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText id='dialog-description'>
          {props.description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} style={{ color: colors.orange }}>
          Entendi
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogAlert;
