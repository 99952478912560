// Importar e criar uma função de log
// TODO: Substituir debug pelo logger do shared (pergunte ao Thadeu)
import debug from 'debug';
import * as process from 'process';

const log = debug('config.ts');

interface IFirebaseConfig {
  apiKey: string;
  authDomain: string;
  databaseURL: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
}

interface IConfig {
  environment: string;
  firebaseConfig: IFirebaseConfig;
}

/**
 * Esta variável indica em que ambiente o bundle está rodando, e em que configurações usar:
 *
 * development: Estamos em localhost, na máquina do desenvolvedor.
 * Use as configurações projeto staging do firebase. Esta é a opção
 * padrão
 *
 * staging: Estamos fazendo um bundle para mandar para staging.
 * Use as configurações projeto staging do firebase.
 *
 * production: Estamos fazendo um bundle para mandar para production.
 * Use as configurações projeto production do firebase.
 */
const environment = process.env.HT_ENVIRONMENT || 'development;';

log(`Inicializano configurações do projeto ${environment}`);

const firebaseProductionConfig: IFirebaseConfig = {
  apiKey: 'AIzaSyBdCX57yAb6S1xzQ8OrZ-7mo8Gw6-E0A4U',
  authDomain: 'ht-fb-e3130.firebaseapp.com',
  databaseURL: 'https://ht-fb-e3130.firebaseio.com',
  messagingSenderId: '831388365335',
  projectId: 'ht-fb-e3130',
  storageBucket: 'ht-fb-e3130.appspot.com',
  appId: '1:831388365335:web:380440a98a241369002252',
};

const firebaseStagingConfig: IFirebaseConfig = {
  apiKey: 'AIzaSyANpee27e903MdBhTMrHZjUlGC1sHyV7zw',
  authDomain: 'handtalk-firebase-dev.firebaseapp.com',
  databaseURL: 'https://handtalk-firebase-dev.firebaseio.com',
  messagingSenderId: '1042895598744',
  projectId: 'handtalk-firebase-dev',
  storageBucket: 'handtalk-firebase-dev.appspot.com',
  appId: '1:1042895598744:web:16f5ceda19a34e5f354ced',
};

const config: IConfig = {
  environment,
  firebaseConfig:
    environment === 'production'
      ? firebaseProductionConfig
      : firebaseStagingConfig,
};

export default config;
