import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import {
  CircularProgress,
  Typography,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Avatar,
} from '@material-ui/core'
import { List } from '@material-ui/icons'
import { listHistory } from '../../Functions/HistoryList'
import {
  SnapTypes,
  FiltersType,
  IScheduledData,
} from '../../Types/NotificationFormTypes'
import CardItem from './Content/CardItem'
import HistoryFilter from './Content/HistoryFilter'
import { between } from '../../Utils'

const useStyles = makeStyles({
  loadingView: {
    display: 'flex',
    justifyContent: 'center',
    margin: 30,
  },
  history: {
    display: 'flex',
    flexDirection: 'column-reverse',
  },
})

const LoadingView = () => {
  const classes = useStyles()

  return (
    <div className={classes.loadingView}>
      <CircularProgress variant="indeterminate" />
    </div>
  )
}

const HistoryScreen = () => {
  const [loading, setLoading] = useState(true)
  const [hDocs, setHistDocs] = useState<SnapTypes>()
  const [filters, setFilters] = useState<FiltersType>({})
  const filterExpansion = useState(false)

  useEffect(() => {
    listHistory()
      .then(history => {
        setHistDocs(history)
        setLoading(false)
      })
      .catch(err => console.log(err))
  }, [])

  const matchFilter = (push: IScheduledData) => {
    const { body, scheduleInfo } = push
    const { dateTime } = scheduleInfo
    const dateInTime = new Date(dateTime).setHours(0, 0, 0, 0)
    if (filters) {
      const { initalDate, finalDate, group, type } = filters

      if (
        initalDate &&
        finalDate &&
        !between(dateInTime, initalDate.getTime(), finalDate.getTime())
      ) {
        return false
      }

      if (group && body.group !== group) return false
      if (type && body.type !== type) return false
    }

    return true
  }

  const buildDocElements = () => {
    console.log('Build Doc')
    if (!hDocs) return

    const vals = Object.values(hDocs)

    return vals.map((val, index) => {
      if (matchFilter(val)) return <CardItem key={index} push={val} />
    })
  }

  const HistoryCards = () => {
    const classes = useStyles()

    return (
      <div>
        <HistoryFilter
          filters={filters}
          onFilter={setFilters}
          expansion={filterExpansion}
        />
        <Card
          elevation={0}
          style={{
            marginTop: 20,
            borderRadius: 0,
            border: '1px solid #e8e8e8',
            boxShadow: 'none',
          }}
        >
          <CardHeader
            avatar={
              <Avatar variant="rounded" aria-label="recipe">
                <List />
              </Avatar>
            }
            title="Histórico de Pushs"
            subheader="Lista das notificações enviadas"
          />
          <Divider />
          <CardContent>
            {loading ? (
              <LoadingView />
            ) : (
              <div className={classes.history}>
                {hDocs ? (
                  buildDocElements()
                ) : (
                  <Typography
                    style={{ margin: 10 }}
                    align="center"
                    variant="body1"
                    color="textSecondary"
                  >
                    Sem Registros
                  </Typography>
                )}
              </div>
            )}
          </CardContent>
        </Card>
      </div>
    )
  }

  return <HistoryCards />
}

export default HistoryScreen
