import { IFormData } from '../Types/NotificationFormTypes'
import Axios from 'axios'
import { sendNotificationUrl } from '../../../../services/constants'

export const throwNotification = (push: IFormData) => {
  const url = sendNotificationUrl
  return Axios.post(url, {
    token: 'e7p3Ddm8aeZ8ZJ5349J1IQM3UySErWGk',
    ...push,
  })
}
