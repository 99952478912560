import Paper from '@material-ui/core/Paper';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { ThreeBounce } from 'better-react-spinkit';
import React, { useState } from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { CustomTooltip } from './CustomTooltip';
import StyledTooltip from '../../../../../components/Tooltip/Tooltip';
import styled from 'styled-components';

const threeBounce = (
  <div style={{ margin: '28%', textAlign: 'center' }}>
    <ThreeBounce size={10} color='rgb(92, 107, 192)' />
  </div>
);

export interface IDashboardBlockProps {
  data?: any[];
  title?: string;
  text?: string[];
  color?: string[];
  classes?: any;
}

const styles = (theme: any) => ({
  root: {
    margin: 'auto 10px 0px',
    [theme.breakpoints.up(1550)]: {
      margin: '0 auto',
    },
  },
});

const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px;
`;

const EmptyDiv = styled.div`
  height: 20px;
  width: 20px;
`;

const SignaturesLegendContainer = styled.div`
  margin-left: 4px;
`;

const SignatureContainer = styled.span`
  display: inline-flex;
  align-items: center;
  padding: 6px;
  &:hover {
    background-color: rgba(0, 0, 0, 0.07);
    transition: background-color 150ms ease-in-out;
    cursor: pointer;
  }
`;

const GrossMarginContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LegendText = styled.span`
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -ms-user-select: none;
`;

const Square = styled.div`
  display: inline-block;
  height: 12px;
  width: 14px;
  margin-right: 2px;
  ${(props: { active: boolean; color: string }) =>
    props.active
      ? `background-color: ${props.color}`
      : `
    webkit-box-shadow:inset 0px 0px 0px 2px ${props.color};
    -moz-box-shadow:inset 0px 0px 0px 2px ${props.color};
    box-shadow:inset 0px 0px 0px 2px ${props.color};
    `};
`;

const ChartContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const DashboardBlock = (props: IDashboardBlockProps) => {
  const { color = [], data = [], text = [], title, classes } = props;

  const [disabled, setDisabled] = useState<string[]>([]);

  // Calcula o total de assinaturas ignorando aquelas
  // que não foram selecionadas no filtro de legendas
  if (data.length > 0 && data[0].totalCurrentSignatures) {
    data.map(data => {
      data.totalCurrentSignatures = 0;
      text.map(name => {
        !disabled.includes(name) && (data.totalCurrentSignatures += data[name]);
      });
    });
  }

  /**
   * Cuida do filtro com o clique nos botões da legenda
   * @param dataKey Nome da key do gráfico. Ex: 'Gold1'
   */
  const handleClick = (dataKey: string) => {
    const active = !disabled.includes(dataKey);

    active
      ? // Não permite desabilitar se apenas existe um dado ativo
        disabled.length !== text.length - 1 &&
        setDisabled(disabled.concat([dataKey]))
      : setDisabled(disabled.filter(key => key !== dataKey));
  };

  /**
   * Legenda que permite filtragem de dados por clique
   */
  const CustomLegend = () => (
    <SignaturesLegendContainer>
      {text.map((dataKey: any, i: number) => {
        const active = !disabled.includes(dataKey);

        return (
          <SignatureContainer onClick={() => handleClick(dataKey)} key={i}>
            <Square color={color[i]} active={active} />
            <LegendText>{dataKey}</LegendText>
          </SignatureContainer>
        );
      })}
    </SignaturesLegendContainer>
  );

  const RenderLegend = () => {
    return (
      <GrossMarginContainer>
        <span style={{ marginLeft: 32 }}>Gross Margin: 90%</span>
      </GrossMarginContainer>
    );
  };

  const contentBar = () =>
    title === 'Churn'
      ? text.map((key, i) => {
          // Se desabilitado, muda nome da key para vazio
          // Isso faz com que o dado suma do gráfico
          disabled.includes(key) && (key = '');
          return <Bar dataKey={key} fill={color[i]} key={i} />;
        })
      : text.map((key, i) => {
          // Se desabilitado, muda nome da key para vazio
          // Isso faz com que o dado suma do gráfico
          disabled.includes(key) && (key = '');

          return (
            <Bar dataKey={key} stackId='a' fill={color[i]} key={i}>
              {// Se for CAC Payback ou LTV/CAC
              key.includes('CAC') &&
                key !== 'CAC' &&
                data.map(item => {
                  return (
                    // Dá cor individualizada para cada célula
                    <Cell fill={item.color[0]} key={item.text[0]} />
                  );
                })}
            </Bar>
          );
        });

  const hasLegend =
    text.length > 1 ? (
      <Legend wrapperStyle={{ left: 30 }} content={CustomLegend} />
    ) : (
      <> </>
    );
  const isLTV = title === 'LTV' ? <Legend content={RenderLegend} /> : <> </>;

  const ContentChart = () => {
    return !data ? (
      threeBounce
    ) : (
      <ChartContainer>
        <BarChart
          className={classes.root}
          width={360}
          height={210}
          data={data}
          style={{
            color: 'rgb(153, 153, 153)',
            fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
            fontSize: 12,
            fontWeight: 400,
            transform: 'translateX(-24px)',
          }}
        >
          <CartesianGrid strokeDasharray='3 3' />

          <XAxis
            dataKey='name'
            tick={{ fill: 'rgb(153, 153, 153)', x: '12' }}
          />
          <YAxis tick={{ fill: 'rgb(153, 153, 153)' }} />
          <Tooltip content={<CustomTooltip />} />
          {hasLegend}
          {contentBar()}
          {isLTV}
        </BarChart>
      </ChartContainer>
    );
  };

  return (
    <Paper
      style={{
        border: '1px solid #e8e8e8',
        borderRadius: 0,
        boxShadow: 'none',
      }}
    >
      <TopContainer>
        <EmptyDiv /> {/**Div vazia para posicionar a grid */}
        <Typography
          style={{
            color: '#777',
            fontSize: 16,
            paddingBottom: 4,
            fontWeight: 600,
            textAlign: 'center',
          }}
        >
          {title}
        </Typography>
        <StyledTooltip title={data[0]?.description} placement='top' arrow>
          <HelpOutlineOutlinedIcon
            style={{
              fontSize: '25px',
              color: '#999',
              width: 20,
            }}
          />
        </StyledTooltip>
      </TopContainer>
      <ContentChart />
    </Paper>
  );
};

export default withStyles(styles)(DashboardBlock);
