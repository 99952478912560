import Paper from '@material-ui/core/Paper'
import styled from 'styled-components'

const SquaredPaper = styled(Paper)`
  border: 1px solid #e8e8e8;
  border-radius: 0;
  box-shadow: none;
`

export default SquaredPaper
