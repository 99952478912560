import React from 'react';

import DashboardBlock, { IDashboardBlockProps } from './DashboardBlock';
import styled from 'styled-components';

interface IDashboardProps {
  data: IDashboardBlockProps[];
}

const MainContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  overflow-y: auto;
  padding: 8px 0 8px 8px;

  @media (max-width: 1795px) {
    padding: 8px;
  }
`;

const StyledGrid = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(380px, 1fr));
  grid-template-rows: repeat(auto-fit, 260px);
  grid-gap: 8px;
`;

const Index = (props: IDashboardProps) => {
  const Blocks = () => {
    const { data } = props;
    return (
      <>
        {data.map((props: IDashboardBlockProps) => (
          <DashboardBlock {...props} key={props.title} />
        ))}
      </>
    );
  };
  return (
    <MainContainer>
      <StyledGrid>
        <Blocks />
      </StyledGrid>
    </MainContainer>
  );
};

export default Index;
