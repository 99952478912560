import styled from "styled-components";
import SquaredPaper from "../../../../components/SquaredPaper";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";

export const StyledInput = styled(TextField)`
  min-width: 140px;
  max-width: 174px;
  font-style: italic;
  position: fixed;
  top: 11px;
  margin-left: 8px;

  @media (max-width: 848px) {
    top: 58px;
  }
`;

export const TableContainer = styled(SquaredPaper)`
  min-width: 980px;
  margin: 8px;
`;

export const StyledRow = styled(TableRow)`
  cursor: default;
`;

export const Inactive = styled(FormControlLabel)`
  position: fixed;
  top: 11px;
  margin-left: 179px;

  @media (max-width: 848px) {
    top: 104px;
    margin-left: -1px;
  }
`;