// TODO: Substituir debug pelo logger do shared (pergunte ao Thadeu)
import debug from "debug";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/remote-config";
import "firebase/storage";

const log = debug("firebase.ts");

log("Inicializando firebase para front-end");

import config from "./config";

export const fbApp = firebase.initializeApp(config.firebaseConfig);

export const fbAuth = fbApp.auth();
export const fbRef = fbApp.database().ref();
export const fbRemote = fbApp.remoteConfig();

export const { TIMESTAMP } = firebase.database.ServerValue;
export const storage = fbApp.storage();

export default firebase;
