import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import React, { Component } from 'react'
import { coinPtbr } from '../../../../services/helper'

const styles = () => ({
  root: {
    padding: 10,
    width: '100%',
  },
})

export interface ISubscriptionsAnalyticsBlockTableProps {
  classes?: any
  data: any[]
}

class SimpleTable extends Component<ISubscriptionsAnalyticsBlockTableProps> {
  public tablebody = () => {
    const { data } = this.props
    return data.map((row, index) => {
      const value = parseFloat(coinPtbr(row.translations))

      const translations =
        row.translations >= 1000000
          ? `${value.toFixed(1)}mi`
          : `${Math.round(value)}k`
      return (
        <TableRow key={row.id} style={{ height: 30 }}>
          <TableCell align="right" style={{ border: 'none', padding: 0 }}>
            {`${index + 1}º`}
          </TableCell>
          <TableCell
            component="th"
            scope="row"
            style={{
              border: 'none',
              fontSize: 12,
              padding: '4px 5px 4px 24px',
            }}
          >
            <a
              href={`http://${row.name}`}
              target={`_blank`}
              style={{ textDecoration: 'none' }}
            >
              <Typography>{row.name}</Typography>
            </a>
          </TableCell>
          <TableCell align="right" style={{ border: 'none' }}>
            {translations}
          </TableCell>
        </TableRow>
      )
    })
  }
  public render() {
    const { classes } = this.props

    return (
      <Paper
        className={classes.root}
        style={{
          border: '1px solid #e8e8e8',
          boxShadow: 'none',
          overflowX: 'auto',
        }}
      >
        <Typography
          style={{
            paddingBottom: 20,
            paddingTop: '1%',
            textAlign: 'center',
          }}
        >
          Ranking de Traduções
        </Typography>
        <Table
          style={{
            fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
            fontWeight: 400,
          }}
        >
          <TableBody> {this.tablebody()}</TableBody>
        </Table>
      </Paper>
    )
  }
}

export default withStyles(styles)(SimpleTable)
