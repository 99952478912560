import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  height: 64px;
  background: white;
  border: 1px solid #e8e8e8;
  border-radius: 0;
  box-shadow: none;

  @media (max-width: 848px) {
    padding-top: 45px;
    height: 150px;
  }
`;