import React, { Component } from 'react'

import {
  Grid,
  Paper,
  Tab,
  Table,
  TableBody,
  TableRow,
  Tabs,
  Typography,
} from '@material-ui/core'
// import Check from "@material-ui/icons/Check";
import TrendingUP from '@material-ui/icons/TrendingUp'
import { ThreeBounce } from 'better-react-spinkit'

const threeBounceGraphic = (
  <Grid
    style={{
      margin: '28%',
      textAlign: 'center',
    }}
  >
    <ThreeBounce size={10} color="rgb(92, 107, 192)" />
  </Grid>
)

class SampleDashboard extends Component {
  public render() {
    const colorBlocks = 'rgb(92, 107, 192)'
    return (
      <Grid container item xs direction="row" style={{ margin: 4 }}>
        <Grid container item xs={4}>
          {/* <Grid item xs={6} style={{ padding: 4, margin: "0 auto" }}>
            <Paper
              style={{
                border: "1px solid #e8e8e8",
                borderRadius: 0,
                boxShadow: "none",
                flexGrow: 1,
                height: "100%"
              }}
            >
              <Typography
                style={{
                  color: colorBlocks,
                  fontSize: 50,
                  lineHeight: "normal",
                  paddingTop: "10%",
                  textAlign: "center"
                }}
              >
                <ThreeBounce size={10} color="rgb(92, 107, 192)" />
              </Typography>
              <Typography
                style={{
                  fontSize: 12,
                  fontWeight: 400,
                  lineHeight: "normal",
                  paddingBottom: 4,
                  textAlign: "center"
                }}
              >
                Assinaturas Ativas
              </Typography>
              <Typography
                style={{
                  color: colorBlocks,
                  lineHeight: "normal",
                  textAlign: "center"
                }}
              >
                <Check style={{ fontSize: 30 }} />
              </Typography>
            </Paper>
          </Grid> */}
          <Grid item xs={12} style={{ padding: 4, margin: '0 auto' }}>
            <Paper
              style={{
                border: '1px solid #e8e8e8',
                borderRadius: 0,
                boxShadow: 'none',
                flexGrow: 1,
                height: '100%',
              }}
            >
              <Typography
                style={{
                  color: colorBlocks,
                  fontSize: 50,
                  lineHeight: 'normal',
                  paddingTop: '10%',
                  textAlign: 'center',
                }}
              >
                <ThreeBounce size={10} color="rgb(92, 107, 192)" />
              </Typography>
              <Typography
                style={{
                  fontSize: 12,
                  fontWeight: 400,
                  lineHeight: 'normal',
                  textAlign: 'center',
                }}
              >
                Consumo Médio Mensal
              </Typography>
              <Typography
                style={{
                  color: colorBlocks,
                  lineHeight: 'normal',
                  textAlign: 'center',
                }}
              >
                <TrendingUP style={{ fontSize: 30 }} />
              </Typography>
            </Paper>
          </Grid>
          <Grid container item xs={12} style={{ padding: 4, height: '100%' }}>
            <Paper
              style={{
                border: '1px solid #e8e8e8',
                boxShadow: 'none',
                overflowX: 'auto',
                padding: 10,
                width: '100%',
              }}
            >
              <Typography
                style={{
                  paddingBottom: 20,
                  paddingTop: '1%',
                  textAlign: 'center',
                }}
              >
                Ranking de Traduções
              </Typography>
              <Table
                style={{
                  fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                  fontWeight: 400,
                }}
              >
                <TableBody>
                  <TableRow key={1} style={{ textAlign: 'center' }}>
                    <ThreeBounce size={10} color="rgb(92, 107, 192)" />
                  </TableRow>
                </TableBody>
              </Table>
            </Paper>
          </Grid>
        </Grid>
        <Grid container item xs={8}>
          <Grid container item xs style={{ padding: 4, marginRight: 8 }}>
            <Paper
              style={{
                border: '1px solid #e8e8e8',
                borderRadius: 0,
                boxShadow: 'none',
                flexGrow: 1,
                height: '100%',
                overflowX: 'auto',
                paddingBottom: '3%',
              }}
            >
              <Grid
                item
                xs
                style={{
                  padding: 4,
                }}
              >
                <Tabs
                  value="flapsOne"
                  indicatorColor="primary"
                  textColor="primary"
                  centered
                  style={{
                    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                    fontWeight: 400,
                  }}
                >
                  <Tab
                    value="flapsOne"
                    label="Assinaturas x Ramo"
                    style={{ textTransform: 'none', fontWeight: 400 }}
                  />
                  <Tab
                    value="flapsTwo"
                    label="MRR x Ramo"
                    style={{ textTransform: 'none', fontWeight: 400 }}
                  />
                </Tabs>
                {threeBounceGraphic}
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

export default SampleDashboard
