import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import React from 'react';

interface ICustomTooltipProps {
  active?: boolean;
  label?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload?: any[];
  type?: string;
}
interface IKeys {
  [key: string]: string;
}

export const CustomTooltip = (props: ICustomTooltipProps) => {
  const { active, payload = [] } = props;
  const [toolTip] = payload;

  if (active && toolTip) {
    return (
      <Grid
        className='custom-tooltip'
        style={{
          background: '#ffffffde',
          padding: 6,
        }}
      >
        <Typography
          style={{
            color: 'rgb(153, 153, 153)',
            fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
            fontSize: '12px',
            fontWeight: 800,
            lineHeight: '1.46429em',
            padding: 6,
          }}
        >{`${toolTip.payload.label} 
          ${
            toolTip.payload.year &&
            // Se for quarter não mostra o ano
            toolTip.payload.label[0] != 'Q'
              ? toolTip.payload.year
              : ''
          }`}</Typography>
        {payload.map((d: any, i: any) => {
          let value = d.value;
          toolTip.payload.formatted && (value = toolTip.payload.formatted);
          return (
            <Typography
              style={{
                color: 'rgb(153, 153, 153)',
                fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                fontSize: '12px',
                fontWeight: 400,
                lineHeight: '1.46429em',
                padding: 6,
              }}
              key={i}
              className='label'
            >{`${d.dataKey} : ${d.payload.symbolLeft || ''} ${value} ${d.payload
              .symbolRight || ''}`}</Typography>
          );
        })}
        <Typography
          className='label'
          style={{
            color: 'rgb(153, 153, 153)',
            fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
            fontSize: '12px',
            fontWeight: 400,
            lineHeight: '1.46429em',
            padding: 6,
          }}
        >
          {toolTip.payload.totalCurrentSignatures
            ? `Total: ${toolTip.payload.totalCurrentSignatures}`
            : ''}
          {toolTip.payload.legendColor ? toolTip.payload.legendColor : ''}
        </Typography>
      </Grid>
    );
  }

  return <span></span>;
};
