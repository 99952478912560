import React, { useState } from 'react'

import { TextField } from '@material-ui/core'
import { TextFieldProps } from '@material-ui/core/TextField'

interface ITextInput {
  hidden?: boolean
  validator: { required: boolean; maxLength?: number }
  errors: string[]
}

const TextInput: React.SFC<TextFieldProps & ITextInput> = props => {
  const [textOverflow, setTextOverflow] = useState(false)

  const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (props.validator.maxLength) {
      const valueArray = event.target.value.match(/./gu)

      if (valueArray) {
        setTextOverflow(valueArray.length > props.validator.maxLength)
      }
    }

    if (props.onChange) props.onChange(event)
  }

  return (
    <div
      style={{
        marginBottom: 25,
        display: props.hidden ? 'none' : 'block',
      }}
    >
      <TextField
        {...props}
        error={textOverflow || props.errors.length != 0}
        fullWidth={true}
        multiline={true}
        onChange={handleValueChange}
        variant="outlined"
      />

      <ul style={{ color: '#F44336' }}>
        {(() => {
          if (textOverflow) {
            return (
              <li>Tamanho máximo de {props.validator.maxLength} caracteres</li>
            )
          } else if (props.errors.length != 0) {
            return props.errors.map((error, index) => (
              <li key={index}>{error}</li>
            ))
          }
        })()}
      </ul>
    </div>
  )
}

export default TextInput
