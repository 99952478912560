import Divider from '@material-ui/core/Divider'
import Drawer from '@material-ui/core/Drawer'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import { createStyles, Theme, withStyles } from '@material-ui/core/styles'
import withWidth from '@material-ui/core/withWidth'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import classNames from 'classnames'
import React, { Component } from 'react'
import { compose } from '../../services/utils'

import Brand from '../../images/brand.svg'
import UserMenu from './UserMenu'

import DrawerList from './DrawerList'

const drawerWidth = 240
const closedDrawerWidth = 56

const styles = (theme: Theme) =>
  createStyles({
    content: {
      backgroundColor: theme.palette.background.default,
      height: '100%',
      overflow: 'hidden',
    },
    drawerPaper: {
      position: 'relative',
      transition: theme.transitions.create('width', {
        duration: theme.transitions.duration.enteringScreen,
        easing: theme.transitions.easing.sharp,
      }),
      whiteSpace: 'nowrap',
      width: drawerWidth,
    },
    drawerPaperClose: {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        duration: theme.transitions.duration.leavingScreen,
        easing: theme.transitions.easing.sharp,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(7),
      },
    },
    drawerPaperXs: {
      height: '100%',
      left: 0,
      position: 'absolute',
      top: 0,
    },
    userMenuWrapper: {
      paddingTop: 10,
      position: 'fixed',
      right: 5,
      top: 0,
      zIndex: 1200,
    },

    toolbar: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'flex-end',
      padding: '0 8px',
      ...theme.mixins.toolbar,
    },
  })

interface ILayoutProps {
  toolbar?: React.ReactNode
  children: React.ReactNode
  width: string
  classes: any
  minWidth?: any
  history?: any
}

interface ILayoutState {
  open: boolean
}

let statusOpen = false

class Layout extends Component<ILayoutProps, ILayoutState> {
  public state = {
    open: false,
  }

  public toggleDrawer = () => {
    const { open } = this.state
    statusOpen = !open
    this.setState({ open: !open })
  }

  public render() {
    const { toolbar, children, classes, width, minWidth } = this.props

    return (
      <Grid container direction="row" style={{ height: '100%' }}>
        <Drawer
          variant="permanent"
          open={statusOpen}
          classes={{
            paper: classNames({
              [classes.drawerPaper]: true,
              [classes.drawerPaperClose]: statusOpen,
              [classes.drawerPaperXs]: width === 'xs',
            }),
          }}
        >
          <div className={classes.toolbar}>
            <Brand style={{ marginRight: 20 }} />
            <IconButton onClick={this.toggleDrawer}>
              {statusOpen ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
          </div>
          <Divider />
          <DrawerList />
        </Drawer>
        <Grid
          container
          direction="column"
          item
          xs
          style={{
            overflowX: 'auto',
            paddingLeft: width === 'xs' ? closedDrawerWidth : 0,
          }}
        >
          <div className={classes.userMenuWrapper}>
            <UserMenu />
          </div>
          {toolbar}
          <Grid
            container
            direction="column"
            item
            xs
            className={classes.content}
            style={{ minWidth }}
          >
            {children}
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

export default compose(withWidth(), withStyles(styles))(Layout)
