import React from "react";
import { endOfDay, addDays } from "date-fns";
import md5 from "md5";
import Swal from "sweetalert2";

import * as S from "./TokenModalStyles";
import Button from "@material-ui/core/Button";
import { MenuItem, TextField } from "@material-ui/core";

import { fbRef, fbAuth } from "../../../../services/firebase";

function TokenModal() {
  //Validação da mudança de estado do modal
  const [modalIsOpen, setIsOpen] = React.useState(false);

  //Validação dos inputs do form
  const [type, setType] = React.useState("");
  const handleChangeType = (event: React.ChangeEvent<{ value: unknown }>) => {
    setType(event.target.value as string);
  };

  const [tokenPeriod, setTokenPeriod] = React.useState("");
  const handleChangeTokenPeriod = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setTokenPeriod(event.target.value as string);
  };

  const [company, setCompany] = React.useState("");
  const handleChangeCompany = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setCompany(event.target.value as string);
  };

  const [description, setDescription] = React.useState("");
  const handleChangeDescription = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setDescription(event.target.value as string);
  };

  //Data que o token é gerado
  const timeToken = null as any;
  const [startAt, setStartAt] = React.useState(timeToken);
  const handelChangeTime = () => {
    const timeToken = endOfDay(new Date()).getTime();
    setStartAt(timeToken);
  };

  //Prazo do token
  const startAtDate = new Date(startAt);
  const tokenPeriodNumber = parseInt(tokenPeriod);
  const endAt = addDays(startAtDate, tokenPeriodNumber).getTime();

  //UID do usuario logado
  const uid = fbAuth.currentUser!.uid;

  //Envia os dados do form para o firebase
  const addFirebase = async () => {
    //Gera o token: UID do usuario + o timestamp inicial
    const currentDate = new Date().getTime();
    const tokenTest = md5(uid + currentDate);

    // Status do token
    const dateExpire = startAtDate.setDate(
      startAtDate.getDate() + tokenPeriodNumber + 1
    );

    const status = currentDate < dateExpire;

    //Referencia pro firebase
    const fbManagerTokens = fbRef.child("tokens");

    const objFirebase = {
      company,
      description,
      endAt,
      startAt,
      status,
      type,
      uid,
    };

    await fbManagerTokens.child(tokenTest).set(objFirebase);

    return true;
  };

  const handleChangeSubmit = async (event: { preventDefault: () => void }) => {
    event.preventDefault();

    addFirebase();
    // Fecha o modal e limpa os campos do form
    setIsOpen(false);
    setType("");
    setTokenPeriod("");
    setCompany("");
    setDescription("");

    //Pop-up de confirmação
    Swal.fire({
      icon: "success",
      title: "Token gerado!",
      showConfirmButton: false,
      timer: 1500,
    });
  };

  return (
    <>
      <S.But
        color="primary"
        variant="outlined"
        size="small"
        onClick={() => setIsOpen(true)}
      >
        Gerar novo token
      </S.But>
      <S.StyledModal
        isOpen={modalIsOpen}
        onRequestClose={() => setIsOpen(false)}
        contentLabel="Gerador de token teste"
        ariaHideApp={false}
      >
        <form noValidate autoComplete="off" onSubmit={handleChangeSubmit}>
          <TextField
            label="Empresa"
            variant="standard"
            required
            name="company"
            aria-label="Adicionar nome da empresa"
            value={company}
            onChange={handleChangeCompany}
            fullWidth
          />
          <br />
          <br />
          <TextField
            label="Descrição"
            variant="standard"
            name="description"
            aria-label="Adicionar descrição"
            value={description}
            onChange={handleChangeDescription}
            fullWidth
          />
          <br />
          <br />
          <TextField
            label="Periodo"
            required
            select
            name="period"
            aria-label="Escolher o periodo do token"
            value={tokenPeriod}
            onChange={handleChangeTokenPeriod}
            fullWidth
          >
            <MenuItem value={7}>7 dias</MenuItem>
            <MenuItem value={15}>15 dias</MenuItem>
            <MenuItem value={20}>20 dias</MenuItem>
          </TextField>
          <br />
          <br />
          <TextField
            label="Tipo"
            required
            select
            name="type"
            aria-label="Escolher o tipo de token"
            value={type}
            onChange={handleChangeType}
            fullWidth
          >
            <MenuItem value="Estudante">Estudante</MenuItem>
            <MenuItem value="Prospect">Prospect</MenuItem>
            <MenuItem value="Domínio Cliente">Domínio cliente</MenuItem>
            <MenuItem value="Upsell">Upsell </MenuItem>
          </TextField>
          <br />
          <br />
          <br />
          <S.WrapperButton>
            <Button
              color="primary"
              aria-label="Fechar o modal"
              variant="outlined"
              size="small"
              onClick={() => setIsOpen(false)}
            >
              Fechar
            </Button>
            <Button
              color="primary"
              aria-label="Gerar o token"
              variant="outlined"
              size="small"
              type="submit"
              onClick={handelChangeTime}
              disabled={company === "" || tokenPeriod === "" || type === ""}
            >
              Gerar
            </Button>
          </S.WrapperButton>
        </form>
      </S.StyledModal>
    </>
  );
}

export default TokenModal;