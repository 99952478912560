import React, { useState, useEffect } from 'react';

import { IDashboardBarBlockProps } from '../Content/Bar/DashboardBarBlock';
import { IDashboardBlockProps } from '../Content/Blocks/DashboardBlock';
import { cardData } from '../Metrics/Blocks';
import { graphicSample, titleGraphic } from '../Metrics/Graphics';

import DashboardBar from '../Content/Bar';
import DashboardBlocks from '../Content/Blocks';
import styled from 'styled-components';

const MainContainer = styled.div`
  display: grid;
  grid-template-columns: auto 400px;
  width: 100%;
  height: 100%;

  @media (max-width: 1050px) {
    grid-template-columns: auto 320px;
  }
`;

const SampleUnits = () => {
  const [dataBar, setDataBar] = useState<IDashboardBarBlockProps[]>([]);
  const [dataBlock, setDataBlock] = useState<IDashboardBlockProps[]>([]);

  const dataDashboard = () => {
    const range: any = [];
    const dataMonth: any = [];

    const dataSample = {
      value: 0,
    };

    dataMonth.push(dataSample);

    const dataCard = new Object();

    range.push(
      graphicSample(dataMonth),
      graphicSample(dataMonth),
      graphicSample(dataMonth),
      graphicSample(dataMonth),
      graphicSample(dataMonth),
      graphicSample(dataMonth)
    );
    // alimentando os dados dos gráficos
    const dataGraphic: IDashboardBlockProps[] = range.map(
      (graphic: any, i: any) => {
        return {
          color: graphic[0].color,
          data: '',
          text: graphic[0].text,
          title: titleGraphic[i],
        };
      }
    );

    setDataBar(cardData(dataCard));
    setDataBlock(dataGraphic);
  };

  useEffect(dataDashboard, []);

  return (
    <MainContainer>
      <DashboardBlocks data={dataBlock} />
      <DashboardBar data={dataBar} />
    </MainContainer>
  );
};

export default SampleUnits;
