/**
 * 🚧🚧🚧 ATENÇÃO 🚧🚧🚧
 * Este arquivo está originalmente em:
 * ./tools/shared/services/errors.ts
 * Se você encountrou em outro lugar, ele é um link-simbólico. Ao modificar
 * este arquivo, tome cuidado para que outros projetos não sejam quebrados!
 */

// Este arquivo exporta todos os erros que podemos jogar em nosso servidor.
// Erros de outros formatos devem ser considerados como 500 - Erro de servidor.

export class HtError extends Error {
    public status: number
    public safeMessage: string
  
    public constructor(safeMessage: string, status = 500) {
      super(safeMessage)
      this.status = status
      this.safeMessage = safeMessage
    }
  
    public toJson = () => {
      return { status: this.status, message: this.safeMessage }
    }
  }
  
  export const erroTokenObrigatorio = new HtError('Token Obrigatório', 401)
  export const erroTokenInvalido = new HtError('Token Inválido', 403)
  
  export const erroTokenDeAnimadorObrigatorio = new HtError(
    'Token de animador Obrigatório',
    401,
  )
  
  export const erroTokenDeAnimadorInvalido = new HtError(
    'Token de animador Inválido',
    403,
  )
  
  export const errorTranslationToken = new HtError('Token não autorizado', 403)
  
  export const getErroGlosaNaoTemCaractereUnderline = (glosa: string) =>
    new HtError(`Glosa inválida, não tem caractere "_": "${glosa}"`, 403)
  
  export const getErroGlosaNaoTemLinguaNoInicio = (glosa: string) =>
    new HtError(
      `Glosa inválida, não inicia com BZS ou outra língua: "${glosa}"`,
      403,
    )
  
  export const getErroGlosaNaoEstaEmMaiuscula = (glosa: string) =>
    new HtError(`Glosa inválida, não está em maiúscula: "${glosa}"`, 403)
  
  export const getErroSinalNaoTemBlend = (glosa: string) =>
    new HtError(`Sinal não tem blend: "${glosa}"`, 404)
  
  export const erroGifObrigatorio = new HtError('Gif Obrigatório', 400)
  export const erroBlendObrigatorio = new HtError('Blend Obrigatório', 400)
  export const erroHtaObrigatorio = new HtError('Hta Obrigatório', 400)
  
  export const erroNomeNaoEncontradoNoAirtable = new HtError(
    'Glosa não encontrada no Airtable',
    404,
  )
  
  export const erroEncontradoXSinaisComGlosaNoAirtable = (x: number) =>
    new HtError(`Encontrados ${x} sinais com essa glosa no Airtable.`)
  
  export const erroSinalNaoPodeSerAnimado = new HtError(
    'Este sinal não pode ser animado',
  )
  
  export const erroEncontradoXDemandasParaSinalNoAirtable = (x: number) =>
    new HtError(`Encontrados ${x} demandas para este sinal no Airtable.`)
  
  export const errorPlaybackNotInstantiated = new HtError(
    'Playback ainda não foi carregado',
  )
  
  export const errorVttBadFormat = new HtError(
    'Nenhuma entrada de tempo localizada. Provavalmente o arquivo de legenda não é um VTT',
  )
  
  export const errorWaitForEventTimeout = new HtError(
    'O tempo limite foi excedido e nenhum evento foi chamado',
  )
  
  // ------------------ Erros do community -------------------
  
  export const errorVideoOrTagNotFound = new HtError('Vídeo or tags not found')
  
  export const errorIsNotMasterOrAdmin = new HtError(
    'Ação não permitida! você precisa ser master ou administrador de um workspace para fazer isso',
  )
  
  export const errorEmailNotProvided = new HtError(
    'O e-mail não foi informado.',
    400,
  )
  
  export const errorOriginNotAllowed = new HtError('Origem não permitida.', 400)