import * as process from "process";

/** Tempo maxima de espera por um evento no dom */
export const waitForEventTimeout = 15000;

/** API de tradução e animação vinda do functions */
export const functionsApi =
  "https://us-central1-ht-fb-e3130.cloudfunctions.net/api/";

/** Caminho para o script do core da HT */
export const coreVersion = "1.0.12";
export const htcoreScriptUrl = `https://plugin.handtalk.me/corejs/${coreVersion}/core.min.js`;

/** Caminho para o script do ccapture */
export const ccaptureScriptUrl =
  "https://unpkg.com/ccapture.js@1.1.0/build/CCapture.all.min.js";

/** Caminho para obtenção de um usuário do app através de seu e-mail */
export const mobileUserByEmailUrl =
  "https://us-central1-ht-fb-e3130.cloudfunctions.net/getMobileUserByEmail";

/** Caminho para disparar um push notification para os usuários do app */
export const sendNotificationUrl =
  process.env.HT_ENVIRONMENT === "production"
    ? "https://us-central1-ht-fb-e3130.cloudfunctions.net/throwPushNotification"
    : "https://us-central1-handtalk-firebase-dev.cloudfunctions.net/throwPushNotification";

/** Caminho para capturar os dados iniciais do dash */
export const getInitialClientDashData =
  process.env.HT_ENVIRONMENT === "production"
    ? "https://us-central1-ht-fb-e3130.cloudfunctions.net/getClientdashData"
    : "https://us-central1-handtalk-firebase-dev.cloudfunctions.net/getClientdashData";

/** URL da function de contador de acessos do dashboard do cliente */
export const dashboardAccessCounterFunctionUrl =
  process.env.HT_ENVIRONMENT === "production"
    ? "https://us-central1-ht-fb-e3130.cloudfunctions.net/dashboardAccessCounter"
    : "https://us-central1-handtalk-firebase-dev.cloudfunctions.net/dashboardAccessCounter";

/** Caminho para filtrar os dados do analytics no dashboard do cliente */
export const getAnalyticsClientDashData =
  process.env.HT_ENVIRONMENT === "production"
    ? "https://us-central1-ht-fb-e3130.cloudfunctions.net/getAnalyticsFilteredData"
    : "https://us-central1-handtalk-firebase-dev.cloudfunctions.net/getAnalyticsFilteredData";

/** Caminho para filtrar os dados do firebase no dashboard do cliente */
export const getFirebaseClientDashData =
  process.env.HT_ENVIRONMENT === "production"
    ? "https://us-central1-ht-fb-e3130.cloudfunctions.net/getFirebaseFilteredData"
    : "https://us-central1-handtalk-firebase-dev.cloudfunctions.net/getFirebaseFilteredData";
/** Token de acesso ao HT Recorder */
export const htRecorderToken = "894ad581fb6306c7363cb9c32a5dd9dd";

/** Token de acesso para tradução texto para glosa */
export const glosaApiToken = "99b20495349ba8dc99b4e865659eb407";

/** Token de acesso para consulta de sinais */
export const signsApiToken = "71376e0bbdde2c16fa1790fffe98f9f5";

/** Valor padrão da velocidade de interpretação do HUGO */
export const defaultInterpreterSpeed = 1;
