// TODO - Este arquivo ja existe em plugin/services, remover daqui quando a refatoração do plugin terminar
import { glosaApiToken, functionsApi } from './constants'
import { errorVttBadFormat } from './errors'
import { ICue, ITableCuesEntry } from './types'

/** Serviço responsavel por obter as cues (tempo + frase) de um determinado arquivo de legenda */

// Importante: Considere estar utilizando node10 + es2018 para o reconhecimento e funcionamento das regex
// Demo para a regex de validação https://regex101.com/r/ShLA7I/5
const validadeRegex = /^\d{2}:\d{2}:\d{2}[.,]\d{3}\s-->\s\d{2}:\d{2}:\d{2}[.,]\d{3}[\r\n]/gm
// Demo para a regex de filtragem https://regex101.com/r/ShLA7I/4
const filterRegex = /^(?<startTime>\d{2}:\d{2}:\d{2}[.,]\d{3})\s-->\s(?<endTime>\d{2}:\d{2}:\d{2}[.,]\d{3})[\r\n](?<text>.*(?:\r?[\r\n](?!\r?[\r\n]).*)*)/gm
// Demo para a regex de coleta de tempo https://regex101.com/r/ShLA7I/9
const timeRegex = /(?<hours>\d{2}):(?<mins>\d{2}):(?<seconds>\d{2}\.\d{3})/

const hoursToSecondsFactor = 3600
const minsToSecondsFactor = 60

/** Duração média de execução de uma palavra */
const wordDuration = 0.3
/** Margem de erro para iniciar a execução de uma cue */
const lineOverlap = 0.01

/**
 *  Normaliza o texto removendo quebras de linha e espaços em branco no final
 * @param text - Texto que será normalizado
 */
export const normalize = (text: string) => {
  return text.replace(/[\r\n]/, ' ')
}

/** Normaliza o tempo trocando virgula por ponto e convertendo para segundos
 * @param text - Texto com o tempo no formato 00:00:00.000
 */
export const timeToSeconds = (text: string) => {
  // Trocamos virgula por pontos
  const normalizedText = text.replace(/,/, '.')
  // A regex contém os grupos hours, mins e seconds
  const match = timeRegex.exec(normalizedText)

  let timeInSeconds = 0

  // Verificamos se os grupos foram encontrados
  if (match && match.groups) {
    const hours = parseInt(match.groups.hours, 10)
    const mins = parseInt(match.groups.mins, 10)
    const seconds = parseFloat(match.groups.seconds)
    // Convertemos o tempo em segundos
    timeInSeconds =
      hours * hoursToSecondsFactor + mins * minsToSecondsFactor + seconds
  }
  return timeInSeconds
}

/**
 * Obtem a lista de cues de um determinado texto
 * @param plainText - Texto puro
 */
export const getCues = (plainText: string) => {
  // TODO - Verificar mudanças necessarias para o merge do ITableCuesEntry e o ICue
  const cues: any[] = []
  // A regex possui os grupos startTime, endTime e text
  const match = filterRegex.exec(plainText)

  if (match && match.groups) {
    // Dados da cue
    const startTime = timeToSeconds(match.groups.startTime)
    const endTime = timeToSeconds(match.groups.endTime)
    const text = normalize(match.groups.text)

    const cue: ICue = { endTime, startTime, text }
    // recursão para obter as proximas cues
    const nextCues = getCues(plainText)
    // Concatenamos tudo
    cues.push(cue, ...nextCues)
    return cues
  }
  return []
}

/**
 * Checa se o texto está em vtt ou não
 * @param text - Texto puro
 */
export const validate = (text: string) => {
  if (!validadeRegex.test(text)) throw errorVttBadFormat
  return true
}

/** Recebe um texto e retorna um array de cues */
export const getCuesFromText = (text: string) => {
  const lines = text.split(/[\r\n]/)

  const cleanedLines = lines.map(line => line.trim()).filter(Boolean)

  let currentTime = 0

  const cues: ICue[] = cleanedLines.map((line: string) => {
    const words = line.split(' ')
    const lastTime = currentTime
    currentTime += words.length * wordDuration

    return {
      // Tempo anterior+ quantidade de palavras * duração "prevista"
      endTime: lastTime + words.length * wordDuration,
      // Ultimo tempo + margem de erro
      startTime: lastTime + lineOverlap,
      // Texto da linha
      text: line,
    }
  })

  return cues
}

/**
 * Recebe as cues e adiciona os códigos de animações referentes as glosas, se o status de revisão da cue for "reviewed"
 * @param cues - Array de cues para adicionar os códigos de animações
 */

export const getAnimationCodesFromCuesReviewed = async (
  cues: ITableCuesEntry[],
  lang = 'bzs',
) => {
  for (const cue of cues) {
    if (cue.reviewStatus === 'reviewed' && !!cue.glosas.length) {
      try {
        // Converte uma array de glosas para uma string de glosas
        const text = cue.glosas
          .map((x: any) => `${x.type === 'simple-sign' ? x.id : x.text}`)
          .join(' ')

        // Codifica a string da glosa
        const encodedGlosa = encodeURIComponent(text)

        const postData = new URLSearchParams(`q=${encodedGlosa}&lang=${lang}`)
        // Consome os códigos de animações correspondentes as glosas da frase
        const res = await fetch(
          `${functionsApi}translations/glosaToAnimation`,
          {
            body: postData,
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
              token: glosaApiToken || '',
            },
            method: 'POST',
          },
        )

        // Pega a resposta em plainText
        const plainTextResponse = await res.text()

        // Cada pedaço da animação vem separado pelo delimitador $
        const animations = plainTextResponse.split('$')
        // Adicionamos repouso como ultimo sinal
        animations.push('@repouso')
        // Coloca os códigos de animações na cue
        cue.animationCodes = animations
      } catch {
        cue.animationCodes = []
      }
    }
  }

  return cues
}

/**
 * Extrai as cues de um texto puro
 * @param text - Texto do qual extrairemos as frases e tempos
 */
export const extract = (text: string) => {
  validate(text)
  return getCues(text)
}
