import React from "react";
import * as S from "./SearchStyles";

import TokenModal from "../TokenModal";

const Search = () => (
  <S.Wrapper>
    <TokenModal />
  </S.Wrapper>
);

export default Search;