import React, { useEffect, useState } from 'react'

import { Paper, List, ListItem } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import Downshift from 'downshift'

import {
  createNewCaption,
  getCaptions,
} from '../../../services/firebaseCaptions'

interface ICaptionsSearcherProps {
  setLoading: (state: boolean) => void
  setKey: (state: string) => void
  loading: boolean
  languageSelect: () => JSX.Element,
  language: string
}

/**
 * Componente de pesquisa de captions
 */
const CaptionsSearcher: React.FC<ICaptionsSearcherProps> = ({
  setLoading,
  setKey,
  loading,
  languageSelect,
  language
}) => {
  const [captions, setCaptions] = useState<any[]>([])

  useEffect(() => {
    getCaptionsForAutocomplete()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language])

  /** Consome todas as captions do firebase e coloca no state de captions para ser usado pelo autocomplete */
  const getCaptionsForAutocomplete = async () => {
    try {
      const res = await getCaptions(language)

      setCaptions(res)
      setLoading(false)
    } catch {
      setCaptions([])
      setLoading(false)
    }
  }

  /** Cria novo vídeo e redireciona para a tabela referente */
  const createCaption = async (name: string) => {
    setLoading(true)
    const key = await createNewCaption(name, language)
    setKey(key)
    setLoading(false)
  }

  return (
    <Downshift
      onChange={selection =>
        selection.inputValue
          ? createCaption(selection.inputValue)
          : setKey(selection.key)
      }
      itemToString={item =>
        item && item.inputValue ? item.inputValue : item ? item.name : ''
      }
    >
      {({
        getInputProps,
        getItemProps,
        getMenuProps,
        isOpen,
        inputValue,
        highlightedIndex,
        selectedItem,
      }) => (
        <div>
          <div
            style={{
              display: 'flex',
            }}
          >
            <TextField
              {...getInputProps()}
              placeholder="Digite um título para pesquisar ou criar"
              disabled={loading}
              fullWidth
              style={{ marginRight: '10px' }}
            />

            {languageSelect()}
          </div>
          <Paper
            style={{
              position: 'absolute',
              width: isOpen && !!inputValue ? '50%' : '',
            }}
          >
            <List {...getMenuProps()}>
              {isOpen
                ? [
                    ...captions,
                    { name: `Criar vídeo: "${inputValue}"`, inputValue },
                  ]
                    .filter(
                      item =>
                        !!inputValue && item.name &&
                        (item.name
                          .toLowerCase()
                          .includes(String(inputValue).toLowerCase()) ||
                          item.inputValue),
                    )
                    .map((item, index) => (
                      <ListItem
                        key={item.name}
                        {...getItemProps({
                          key: item.name + index,
                          index,
                          item,
                          style: {
                            backgroundColor:
                              highlightedIndex === index
                                ? 'lightgray'
                                : 'white',
                            fontWeight:
                              selectedItem === item || item.inputValue
                                ? 'bold'
                                : 'normal',
                            cursor: 'pointer',
                          },
                        })}
                      >
                        <div>{item.name}</div>
                      </ListItem>
                    ))
                : null}
            </List>
          </Paper>
        </div>
      )}
    </Downshift>
  )
}

export default CaptionsSearcher
