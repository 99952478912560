import { CircularProgress, Switch, Typography } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { fbAuth } from "../../../../services/firebase";
import { getAndListenUserFirebaseTokens } from "../../../../services/model/getAndListenUserFirebaseTokens";
import ListHead from "../ListHead";
import * as S from "./ContentStyles";

export interface ITokenFirebase {
  uid: string;
  token: string;
  company: string;
  startAt: any;
  endAt: any;
  type: string;
  description: string;
  status: boolean;
}

interface TableContentProps {
  items: ITokenFirebase[];
  filterIsActive: boolean;
  showInactive: boolean;
}

const TableContent: React.FC<TableContentProps> = ({
  items,
  filterIsActive,
  showInactive,
}) => {
  return (
    <>
      {items.length > 0 ? (
        items.map((content) => {
          const {
            token,
            company,
            startAt,
            endAt,
            type,
            description,
            status,
          } = content;

          const startDate = format(startAt, "dd/MM/yy");
          const endDate = format(endAt, "dd/MM/yy");
          const setStatus = status ? "Ativo" : "Inativo";

          const tokenDisplay =
            status || filterIsActive || showInactive ? "" : "none";

          return (
            <S.StyledRow
              key={token}
              style={{
                display: tokenDisplay,
              }}
            >
              <TableCell>{token}</TableCell>
              <TableCell>{company}</TableCell>
              <TableCell>{startDate}</TableCell>
              <TableCell>{endDate}</TableCell>
              <TableCell>{type}</TableCell>
              <TableCell>{description}</TableCell>
              <TableCell>{setStatus}</TableCell>
            </S.StyledRow>
          );
        })
      ) : (
        <Typography
          variant="subtitle1"
          gutterBottom
          component="div"
          style={{
            margin: "16px",
          }}
        >
          Ops! Não encontramos nenhum token.
        </Typography>
      )}
    </>
  );
};

const Content = () => {
  // Estado para checar se está carregando algo (mostra/esconde o loader)
  const [loading, setLoading] = useState(true);
  //Filtragem pelo nome da empresa
  const [filter, setFilter] = useState("");
  const [items, setItems] = useState<ITokenFirebase[]>([]);
  const [itemsToDisplay, setItemsToDisplay] = useState<ITokenFirebase[]>([]);

  // Vamos pegar os tokens ao carregar a página
  useEffect(() => {
    try {
      // Vamos começar a pegar os tokens, então colocamos `loading` como true
      setLoading(true);

      /* Pegamos o id do usuário e passamos para a função que é 
         responsável por pegar e filtrar os dados do firebase. */
      const uid = fbAuth.currentUser!.uid;

      getAndListenUserFirebaseTokens(uid, (tokens) => {
        // Atribuímos o retorno da função ao estado `items`
        setItems(tokens);
        // Aqui já pegamos os dados então não está mais carregando
        setLoading(false);
      });
    } catch (err) {
      console.error("Ocorreu um erro ao buscar os items", err);
    }
  }, []);

  // Vamos observar se o estado filter muda
  useEffect(() => {
    // Caso filter mude, vamos atualizar o estado `itemsToDisplay`
    // Caso não exista filtro, todos os itens serão exibidos
    if (!filter) {
      return setItemsToDisplay(items);
    }

    // Caso exista filtro, vamos mostrar somente os itens filtrados
    const filteredItems = items.filter((content) =>
      content.company.toLowerCase().startsWith(filter.toLowerCase())
    );

    setItemsToDisplay(filteredItems);
  }, [filter, items]);

  let registeredTokens = items.length == 0 ? false : true;

  //Tokens Inativos
  const [showInactiveTokens, setShowInactiveTokens] = useState(false);
  const handleInactiveSwitch = (event: any) => {
    setShowInactiveTokens(event.target.checked);
  };

  return (
    <>
      <S.StyledInput
        placeholder="Hand Talk"
        label="Filtrar pela empresa"
        variant="outlined"
        size="small"
        value={filter}
        onChange={(e) => setFilter(e.target.value)}
        disabled={!registeredTokens}
      />
      <S.TableContainer>
        <Table>
          <ListHead />
          <TableBody>
            {loading ? (
              <CircularProgress size={20} style={{ marginLeft: 12 }} />
            ) : (
              <TableContent
                items={itemsToDisplay}
                filterIsActive={Boolean(filter)}
                showInactive={showInactiveTokens}
              />
            )}

            <S.Inactive
              disabled={!registeredTokens}
              control={
                <Switch
                  checked={showInactiveTokens}
                  color="primary"
                  onChange={handleInactiveSwitch}
                />
              }
              label="Tokens Inativos"
            />
          </TableBody>
        </Table>
      </S.TableContainer>
    </>
  );
};

export default Content;
