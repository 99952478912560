import React, { Component } from 'react'

import Avatar from '@material-ui/core/Avatar'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import LogoutIcon from '@material-ui/icons/PowerSettingsNew'

import Divider from '@material-ui/core/Divider'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Fab from '@material-ui/core/Fab'

import AuthContainer, {
  withAuth,
} from '../../services/containers/AuthContainer'

interface IUserMenuProps {
  auth: AuthContainer
}

interface IUserMenuState {
  anchorEl?: JSX.Element
}

class UserMenu extends Component<IUserMenuProps, IUserMenuState> {
  public state = {
    anchorEl: undefined,
  }

  public handleClick = (event: any) => {
    this.setState({ anchorEl: event.currentTarget })
  }

  public handleClose = () => {
    this.setState({ anchorEl: undefined })
  }

  public render() {
    const { logOut, state } = this.props.auth
    const { user, isLoggedIn } = state
    const { anchorEl } = this.state

    const open = Boolean(anchorEl)

    // Se não estiver logado, retorna imagem cinza
    if (!isLoggedIn || !user) {
      return <Avatar alt="Usuário não autenticado">?</Avatar>
    }

    return (
      <Fab
        size="small"
        aria-label="Menu do usuário"
        style={{ boxShadow: 'none' }}
      >
        <Avatar
          alt={user.displayName || ''}
          src={user.photoURL || ''}
          onClick={this.handleClick}
        />
        <Menu
          id="fade-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={this.handleClose}
          style={{ top: 40 }}
        >
          <MenuItem onClick={logOut}>
            {/* TODO: Érica - veja como eu fiz aqui: substitui o Avatar e o texto por elementos certos, como na documentação. Agora o botão tem "ink" (procure em material.io) e o menu tem ícone e espaçamentos corretos */}
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText inset primary="Sair" />
          </MenuItem>
          <Divider />
        </Menu>
      </Fab>
    )
  }
}

export default withAuth(UserMenu)
