import React, { Component } from "react";

import Grid from "@material-ui/core/Grid";

import copy from "copy-to-clipboard";
import hugoTop from "../../../images/hugo-blink.png";

import Layout from "../../../components/Layout";
import {
  IWithAuthProps,
  requireAuth,
} from "../../../services/containers/AuthContainer";

// TODO: Substituir debug pelo logger do shared (pergunte ao Thadeu)
import debug from "debug";
import { fbAuth } from "../../../services/firebase";
import GlobalTypography from "../../../components/GlobalTypography";

const log = debug("Home:index.tsx");

interface IHomeState {
  token: string;
}

class Home extends Component<IWithAuthProps, IHomeState> {
  public state = {
    token: "",
  };

  public componentDidMount() {
    const user = fbAuth.currentUser;
    if (user) {
      user.getIdToken().then((token: string) => {
        log("Token adquirido");
        this.setState({ token });
      });
    }
  }

  public copy = () => {
    const { token } = this.state;
    if (token) {
      copy(token);
      alert("Token copiado");
    }
  };

  public render() {
    const { auth } = this.props;
    const user = auth.state.user;

    if (!user) throw new Error("Usuário inexistente!");

    return (
      <Layout>
        <Grid
          item
          style={{
            textAlign: "center",
            height: "100%",
            overflow: "auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img src={hugoTop} alt="Hugo" title="Hugo" />
          <GlobalTypography
            variant="h5"
            style={{ color: "#9e6d5f", fontSize: "1.6em" }}
          ></GlobalTypography>
          <GlobalTypography component="p" style={{ color: "#9e6d5f" }}>
            Você não tem permissão para acessar os módulos dessa plataforma.
          </GlobalTypography>
        </Grid>
      </Layout>
    );
  }
}

export default requireAuth(Home);
