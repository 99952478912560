import { fbRef } from "../firebase"
import { ITokenFirebase } from "../../pages/Admin/ManagerTokens/Content/index"

const fbManagerTokens = fbRef.child("tokens")

export const getAndListenUserFirebaseTokens = async (
  uid: string,
  callback: (tokens: ITokenFirebase[]) => void
) => {
  await fbManagerTokens
    .orderByChild("uid")
    .equalTo(uid)
    .on("value", (snapshot) => {
      const tokens: ITokenFirebase[] = []
      snapshot.forEach((child) => {
        const info = child.val()
        const token = child.key!

        const tokenData: ITokenFirebase = {
          ...info,
          token,
        }

        tokens.push(tokenData)
      })

      callback(tokens)
    })
}