import React from "react";
import styled from "styled-components";

import Layout from "../../../components/Layout";
import Search from "./Search";
import Content from "./Content";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  overflow: auto;
  height: calc(100% - 65px);

  @media (max-width: 848px) {
    height: calc(100% - 150px);
  }
`;

const ManagerTokens = () => {
  return (
    <Layout>
      <Search />
      <Wrapper>
        <Content />
      </Wrapper>
    </Layout>
  );
};

export default ManagerTokens;