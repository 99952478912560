import React from 'react'

import {
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  FormHelperText,
} from '@material-ui/core'

interface ISelectInput {
  id: string
  label: string
  helper: string
  value: string
  children: React.ReactNode
  errors: string[]
  name: string
  onChange: (
    event: React.ChangeEvent<{
      name?: string | undefined
      value: unknown
    }>,
    child: React.ReactNode,
  ) => void
  validator: { required: boolean }
}

const SelectInput: React.SFC<ISelectInput> = props => {
  return (
    <div
      style={{
        marginBottom: 25,
      }}
    >
      <FormControl
        error={props.errors.length != 0}
        variant="outlined"
        fullWidth={true}
      >
        <InputLabel id={props.id + '-label'}>{props.label}</InputLabel>
        <Select
          fullWidth={true}
          id={props.id}
          value={props.value}
          onChange={props.onChange}
          name={props.name}
          input={<OutlinedInput labelWidth={props.label.length * 7.8} />}
        >
          {props.children}
        </Select>
        <FormHelperText>{props.helper}</FormHelperText>
      </FormControl>
      <ul style={{ color: '#F44336' }}>
        {(() => {
          if (props.errors.length != 0) {
            return props.errors.map((error, index) => (
              <li key={index}>{error}</li>
            ))
          }
        })()}
      </ul>
    </div>
  )
}

export default SelectInput
