import React from 'react'
import { render } from 'react-dom'

import CssBaseline from '@material-ui/core/CssBaseline'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { Route } from 'react-router-dom'
import { Provider } from 'unstated'

import UnitsEconomics from './pages/Admin/UnitsEconomics'
import SubscriptionsAnalytics from './pages/Admin/SubscriptionsAnalytics'
import Home from './pages/Admin/Home'
import Auth from './pages/Auth'
import Pushs from './pages/Admin/Pushs'
import ManagerTokens from './pages/Admin/ManagerTokens'
import NotFound from './pages/NotFound'

import Router from './components/Router'
import Videos from './pages/Admin/Videos'
import theme from './services/theme'

const App: React.SFC<{}> = () => {
  return (
    <React.Fragment>
      <CssBaseline />
      <Provider>
        <MuiThemeProvider theme={theme}>
          <Router>
            <Route exact path="/auth" component={Auth} />
            <Route exact path="/" component={Home} />
            <Route exact path="/unitsEconomics" component={UnitsEconomics} />
            <Route
              exact
              path="/subscriptionsAnalytics"
              component={SubscriptionsAnalytics}
            />
            <Route exact path="/videos" component={Videos} />
            <Route exact path="/pushs" component={Pushs} />
            <Route exact path="/managerTokens" component={ManagerTokens} />
            {/* <Route
              exact
              path="/externalClientDash"
              component={ExternalClientDash}
            /> */}
            <Route component={() => <NotFound admin />} />
          </Router>
        </MuiThemeProvider>
      </Provider>
    </React.Fragment>
  )
}

render(<App />, document.getElementById('root'))
