import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import Typography from '@material-ui/core/Typography'
// import Check from "@material-ui/icons/Check";
import PauseIcon from '@material-ui/icons/Pause'
import PlayIcon from '@material-ui/icons/PlayArrow'
import TrendingUP from '@material-ui/icons/TrendingUp'
import { ThreeBounce } from 'better-react-spinkit'
import React, { Component } from 'react'
import SubscriptionsAnalyticsBlockTable from '../Table/SimpleTable'
import Chart, { ISubscriptionsAnalyticsBlock } from './Chart'

const threeBounce = (
  <Grid
    style={{
      margin: '28%',
      textAlign: 'center',
    }}
  >
    <ThreeBounce size={10} color="rgb(92, 107, 192)" />
  </Grid>
)

interface IDataSubscriptionsAnalytics {
  graphics: any
  table: any
  consumedFormat: number
  subscriptionsActive: number
}
interface IDashboardProps {
  data: IDataSubscriptionsAnalytics[]
}

// variável global
let isPaused = false
let index = 0
let counterms: any
const tabs = ['flapsTwo', 'flapsOne']

export default class Index extends Component<IDashboardProps> {
  public state = {
    intervalName: <PauseIcon />,
    value: 'flapsOne',
  }

  public handleChange = (_event: any, value: any) => {
    this.setState({ value })
    clearInterval(counterms) // limpa o intervalo
    tabs.forEach((flap: any, i: any) => {
      // atualiza o index global
      if (flap === value) {
        index = i
      }
    })
    // chama a função do intervalo
    this.automaticFlaps()
  }

  public selectFlaps = () => {
    this.setState({ value: tabs[index] })
    index = index >= tabs.length - 1 ? (index = 0) : (index += 1)
  }

  // função para alterar as abas
  public automaticFlaps = () => {
    if (isPaused) this.selectFlaps()
    else {
      counterms = setInterval(() => {
        this.selectFlaps()
      }, 10000)
    }
  }

  public toggleDrawer = () => {
    isPaused = !isPaused

    if (isPaused) {
      this.setState({ intervalName: <PlayIcon /> })
      clearInterval(counterms)
    } else {
      this.setState({ intervalName: <PauseIcon /> })
      clearInterval(counterms) // limpa o intervalo
      this.automaticFlaps()
    }
  }

  public render() {
    const { value, intervalName } = this.state
    const { data } = this.props
    const colorBlocks = 'rgb(92, 107, 192)'
    let consumedFormat: any = 0
    // let subscriptionsActive = 0;

    if (data.length > 0) {
      // subscriptionsActive = data[0].subscriptionsActive;

      consumedFormat = data[0].consumedFormat
      const blocks = (title: string) =>
        data[0].graphics.map((props: ISubscriptionsAnalyticsBlock) => {
          if (props.title === title) {
            const content = !title ? (
              threeBounce
            ) : (
              <Chart key={props.title} {...props} />
            )
            return content
          }
        })

      return (
        <Grid
          container
          item
          xs
          direction="row"
          style={{ margin: 4, overflow: 'auto' }}
        >
          <Grid container item xs={4}>
            {/* <Grid item xs={6} style={{ padding: 4, margin: "0 auto" }}>
              <Paper
                style={{
                  border: "1px solid #e8e8e8",
                  borderRadius: 0,
                  boxShadow: "none",
                  flexGrow: 1,
                  height: "100%"
                }}
              >
                <Typography
                  style={{
                    color: colorBlocks,
                    fontSize: 50,
                    lineHeight: "normal",
                    paddingTop: "10%",
                    textAlign: "center"
                  }}
                >
                  {subscriptionsActive}
                </Typography>
                <Typography
                  style={{
                    fontSize: 12,
                    fontWeight: 400,
                    lineHeight: "normal",
                    paddingBottom: 4,
                    textAlign: "center"
                  }}
                >
                  Assinaturas Ativas
                </Typography>
                <Typography
                  style={{
                    color: colorBlocks,
                    lineHeight: "normal",
                    textAlign: "center"
                  }}
                >
                  <Check style={{ fontSize: 30 }} />
                </Typography>
              </Paper>
            </Grid> */}
            <Grid item xs={12} style={{ padding: 4, margin: '0 auto' }}>
              <Paper
                style={{
                  border: '1px solid #e8e8e8',
                  borderRadius: 0,
                  boxShadow: 'none',
                  flexGrow: 1,
                  height: '100%',
                }}
              >
                <Typography
                  style={{
                    color: colorBlocks,
                    fontSize: 50,
                    lineHeight: 'normal',
                    paddingTop: '10%',
                    textAlign: 'center',
                  }}
                >
                  {consumedFormat}
                </Typography>
                <Typography
                  style={{
                    fontSize: 12,
                    fontWeight: 400,
                    lineHeight: 'normal',
                    textAlign: 'center',
                  }}
                >
                  Consumo Médio Mensal
                </Typography>
                <Typography
                  style={{
                    color: colorBlocks,
                    lineHeight: 'normal',
                    textAlign: 'center',
                  }}
                >
                  <TrendingUP style={{ fontSize: 30 }} />
                </Typography>{' '}
              </Paper>
            </Grid>
            <Grid container item xs style={{ padding: 4 }}>
              <SubscriptionsAnalyticsBlockTable data={data[0].table} />
            </Grid>
          </Grid>
          <Grid container item xs style={{ padding: 4, marginRight: 8 }}>
            <Paper
              style={{
                border: '1px solid #e8e8e8',
                borderRadius: 0,
                boxShadow: 'none',
                flexGrow: 1,
                height: '100%',
                overflowX: 'auto',
                paddingBottom: '3%',
              }}
            >
              <Grid
                item
                xs
                style={{
                  padding: 4,
                }}
              >
                <Tabs
                  value={this.state.value}
                  onChange={this.handleChange}
                  action={this.automaticFlaps}
                  indicatorColor="primary"
                  textColor="primary"
                  centered
                  style={{
                    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                    fontWeight: 400,
                  }}
                >
                  <Tab
                    value="flapsOne"
                    label="Assinaturas x Ramo"
                    style={{ textTransform: 'none', fontWeight: 400 }}
                  />
                  <Tab
                    value="flapsTwo"
                    label="MRR x Ramo"
                    style={{ textTransform: 'none', fontWeight: 400 }}
                  />
                  <Button
                    color="primary"
                    style={{
                      float: 'left',
                      height: '20px',
                      marginLeft: '45% ',
                      minWidth: '20px',
                      paddingBottom: 'inherit',
                      paddingTop: 'inherit',
                      position: 'absolute',
                      top: '6px',
                      width: '20px',
                    }}
                    onClick={this.toggleDrawer}
                    variant="outlined"
                  >
                    {intervalName}
                  </Button>
                </Tabs>
                {value === 'flapsOne' && blocks('Assinaturas x Ramo')}
                {value === 'flapsTwo' && blocks('MRR x Ramo')}
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      )
    }
    return null
  }
}
