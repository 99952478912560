import { FormControl, Select, MenuItem, SelectProps } from '@material-ui/core';
import React, { useState } from 'react';
import {
  UnitsVersions,
  DEFAULT_UNITS_VERSION,
} from '../../services/model/UnitsEconomics';

export type VersionSelectProps = {
  onVersionChange: (version: UnitsVersions) => void;
};

const VersionSelect: React.FC<VersionSelectProps> = props => {
  const [unitsVersion, setUnitsVersion] = useState<UnitsVersions>(
    DEFAULT_UNITS_VERSION
  );

  /**
   * Função para lidar com os eventos de mudança do componente select.
   * @param event Evento que é passado pelo componente select
   */
  const handleChange: SelectProps['onChange'] = event => {
    const value = event.target.value as UnitsVersions;

    if (value) {
      setUnitsVersion(value);
      props.onVersionChange(value);
    }
  };

  return (
    <FormControl style={{ minWidth: 120, marginRight: '10px' }}>
      <Select
        value={unitsVersion}
        onChange={handleChange}
        variant='outlined'
        style={{ height: '30px' }}
      >
        <MenuItem value='Versão 1'>
          {' '}
          <em>Versão 1</em>
        </MenuItem>
        <MenuItem value='Versão 2' selected>
          <em>Versão 2</em>
        </MenuItem>
      </Select>
    </FormControl>
  );
};

export default VersionSelect;
