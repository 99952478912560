import styled from "styled-components";
import Modal from "react-modal";
import Button from "@material-ui/core/Button";

export const But = styled(Button)`
  position: absolute;
  top: 12px;
  padding: 7.5px 20px;
  z-index: 2;
  right: 60px;
`;

export const StyledModal = styled(Modal)`
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  padding: 20px;
  max-width: 400px;
  width: 100%;
  max-height: 366px;
  transition: all 0.3s ease-in-out;
  transform: translate(-50%, -50%);
  border-radius: 4px;
  border: 2px solid rgb(204, 204, 204);
  background: white;
`;

export const WrapperButton = styled.div`
  display: grid;
  justify-content: end;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
`;