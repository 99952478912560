import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import React, { Component } from 'react'
import ReactPlaceholder from 'react-placeholder/lib'
import Layout from '../../../components/Layout'
import { requireAuth } from '../../../services/containers/AuthContainer'
import { coinPtbr } from '../../../services/helper'
import { getDataSubscriptionsAnalytics } from '../../../services/model/SubscriptionsAnalytics'
import Content from './Content'
import ISubscriptionsAnalyticsBlock from './Content/index'
import {
  graphicBrachRecipe,
  graphicBranch,
  titleGraphic,
} from './Metrics/Graphics'
import SampleDashboard from './SampleData'

class Index extends Component {
  public state = {
    dataBlock: [],
    ready: false,
  }

  // coletando os dados para alimentar os gráficos
  public dataSubscriptionsAnalytics = async () => {
    const graphics: any = []
    const rankingTopFifteen: any = []
    const subscriptionsAnalyticsBlock = await getDataSubscriptionsAnalytics()
    const subscriptionsAnalytics =
      subscriptionsAnalyticsBlock[subscriptionsAnalyticsBlock.length - 1]
    const consumed = Math.round(
      Number(coinPtbr(subscriptionsAnalytics.metrics.consumed)),
    )
    const consumedFormat =
      subscriptionsAnalytics.metrics.consumed > 1000 ? `${consumed}k` : consumed
    // const subscriptionsActive = subscriptionsAnalytics.subscriptionsActive;
    const topFifteen = subscriptionsAnalytics.metrics.topfifteen
    let categories = new Object()
    categories = subscriptionsAnalytics.metrics.branchs.categories

    // alimentando o objeto rankingTopFifteen
    Object.keys(topFifteen).forEach(key => {
      rankingTopFifteen.push({
        id: key,
        name: topFifteen[key].domain,
        translations: topFifteen[key].consumed,
      })
    })

    // coletando os dados dos gráficos e adicionando no objeto graphics
    graphics.push(graphicBranch(categories), graphicBrachRecipe(categories))

    const dataGraphics: ISubscriptionsAnalyticsBlock = graphics.map(
      (graphic: any, index: number) => {
        return {
          data: graphic,
          keyPie: undefined,
          title: titleGraphic[index],
        }
      },
    )

    this.setState({
      dataBlock: [
        {
          consumedFormat,
          graphics: dataGraphics,
          subscriptionsActive: 0,
          table: rankingTopFifteen,
        },
      ],
      ready: true,
    })
  }

  public componentDidMount = () => {
    this.dataSubscriptionsAnalytics()
  }
  public render() {
    const { dataBlock } = this.state

    const toolbar: JSX.Element = (
      <AppBar
        color="default"
        style={{
          background: 'white',
          borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
          boxShadow: 'none',
          minWidth: 1200,
          position: 'relative',
        }}
      >
        <Toolbar style={{ padding: 0 }}>
          <Typography variant="h1" color="inherit" />
        </Toolbar>
      </AppBar>
    )

    return (
      <Layout toolbar={toolbar} minWidth={1200}>
        <ReactPlaceholder
          ready={this.state.ready}
          customPlaceholder={<SampleDashboard />}
        >
          <Content data={dataBlock} />
        </ReactPlaceholder>
      </Layout>
    )
  }
}

export default requireAuth(Index)
