import { yearCurrent } from '../helper'
import { fbRef } from '../firebase'
// ERICA criar symlink das funções compartilhadas entre functions e o web
// import { getStatusSubscriptions, getSubscriptionsByPlan } from "./Queries";
const fbDashboards = fbRef.child('dashboards')
const fbSubscriptionsAnalytics = fbDashboards.child('subscriptionsAnalytics')

/**
 * objeto do unitsEconomics
 */
interface ISubscriptionsAnalytics {
  branchs: IBranchs
  consumed: number
  topfifteen: ITopFifteen
}

/**
 * objeto das branchs
 */
interface IBranchs {
  categories: Record<string, ICategories>
  totalMrr: number
  totalSubscription: number
}

/**
 * objeto das categorias
 */
interface ICategories {
  branch: string
  mrr: string
  mrrPercentage: string
  percentage: string
  price: string
  subscriptions: number
}

/**
 * objeto dos top 15
 */
interface ITopFifteen {
  consumed: number
  domain: string
  keysSubscriptions: string
  title: string
}

/**
 * coleta os dados da coleção subscriptionsAnalytics no firebase para adicionar em um objeto no
 * formato que front end espera :P
 * @param selectedYear
 */
export const getDataSubscriptionsAnalytics = async (paramYear?: number) => {
  const year = paramYear !== undefined ? paramYear : yearCurrent()
  const snap = await fbSubscriptionsAnalytics
    .child(year.toString())
    .once('value')
  const dataGraphic: Record<string, ISubscriptionsAnalytics> = snap.val() || {}
  let keysDataGraphic = Object.keys(dataGraphic)
  keysDataGraphic = keysDataGraphic.sort()
  // const dataDashboard = await getSubscriptionsByPlan("htSitesGold");
  // const statusSignatures = getStatusSubscriptions(dataDashboard);
  const subscriptionsAnalytics: any = []

  // const subscriptionsActive =
  //   statusSignatures.htSitesGold1.active +
  //   statusSignatures.htSitesGold2.active +
  //   statusSignatures.htSitesGold3.active +
  //   statusSignatures.htSitesGoldEspecial1.active +
  //   statusSignatures.htSitesGoldEspecial2.active +
  //   statusSignatures.htSitesGoldEspecial3.active;

  // salvando os dados do dashboard no array
  keysDataGraphic.forEach(key => {
    subscriptionsAnalytics.push({
      metrics: dataGraphic[key],
      month: key,
      subscriptionsActive: 0,
    })
  })

  return subscriptionsAnalytics
}
