import { numberFormatter, coinPtbr, colors } from '../../../../services/helper';

const whichSign = (data: string) => {
  if (data == '...') {
    return ' ';
  }
  const dataInt = parseInt(data);
  return dataInt > 0 ? ' +' : ' ';
};

/**
 * alimenta o objeto cards
 * @param data
 */
export const cardData: any = (data: any, yearly?: boolean) => {
  const {
    averageMonthlyTicket = '',
    churnCustomer = '',
    churnRevenue = '',
    customerLifetime = '',
    mrr = '',
    ltv = '',
    percentageSubscriptions = '',
    cac = '',
  } = data;

  const cards = [
    {
      color: colors.yellowLight,
      percentage: !percentageSubscriptions
        ? '...'
        : whichSign(percentageSubscriptions) + percentageSubscriptions + '%',
      title: 'Assinaturas Vigentes',
      value: data.currentSignatures || '...',
    },
    {
      color: colors.orange,
      title: 'LTV',
      value: !ltv ? '...' : 'R$ ' + numberFormatter(ltv),
    },
    {
      color: colors.indigo,
      title: yearly ? 'ARR' : 'MRR',
      value: !mrr ? '...' : 'R$ ' + numberFormatter(mrr),
    },
    {
      color: colors.yellow,
      title: 'Customer Lifetime',
      value: !customerLifetime ? '...' : coinPtbr(customerLifetime) + ' meses',
    },
    {
      color: colors.lightblue,
      title: 'Ticket Médio Mensal',
      value: !averageMonthlyTicket
        ? '...'
        : 'R$ ' + coinPtbr(parseFloat(averageMonthlyTicket)),
    },
    {
      color: colors.blueBaby,
      title: 'MRR Churn',
      value: churnRevenue || '...',
    },
    {
      color: colors.teal,
      title: 'Customer Churn',
      value: churnCustomer || '...',
    },
    {
      color: colors.yellowLight,
      title: 'CAC',
      value: cac ? 'R$ ' + coinPtbr(cac) : '...',
      tooltip: 'Não há dados referente ao intervalo selecionado',
    },
    {
      color: colors.orange,
      title: 'LTV/CAC Ratio',
      value: cac ? (ltv / cac).toFixed(1) : '...',
      tooltip: 'Não há dados referente ao intervalo selecionado',
    },
    {
      color: colors.green,
      title: 'CAC Payback',
      value: cac ? (cac / averageMonthlyTicket).toFixed(1) : '...',
      tooltip: 'Não há dados referente ao intervalo selecionado',
    },
  ];
  return cards;
};
