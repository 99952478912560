import {
  AppBar,
  LinearProgress,
  Paper,
  Toolbar,
  Button,
  Snackbar,
} from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import React, { useState, useEffect } from 'react'
import Layout from '../../../components/Layout'
import RotateLeftIcon from '@material-ui/icons/RotateLeft'
import BrushIcon from '@material-ui/icons/Brush'
import Fade from '@material-ui/core/Fade'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'

import { htRecorderToken } from '../../../services/constants'
import {
  IWithAuthProps,
  requireAuth,
} from '../../../services/containers/AuthContainer'
import logger from '../../../services/logger'
import HTRecorder from '../../../services/HTRecorder'
import {
  ISignLanguageEditorData,
  ICoreCustom,
  VideoOutputFormat,
} from '../../../services/types'
import { differenceInSeconds } from 'date-fns'

import CueEditor from './CaptionsEditor'
import VideoGenerator from './VideoGenerator'
import CoreCustomizer from './CoreCustomizer'
import CaptionsSearcher from './CaptionsSearcher'

const debug = logger('Videos:index.tsx')

const maxResolution = 4000
const minResolution = 250

/** Estilo padrão para os papers */
const paperStyle = {
  border: '1px solid #e8e8e8',
  borderRadius: 0,
  boxShadow: 'none',
  flexGrow: 1,
  height: '100%',
  padding: '4px 5px',
}

const coreViewStyle = {
  background: '#eee',
  height: 400,
  width: '100%',
}

const captionsEditorGridStyle = {
  display: 'flex',
  maxHeight: '100%',
  overflow: 'auto',
  padding: 4,
}

const coreRecordStyle = {
  background: '#eee',
  height: 500,
  width: 500,
}

const buttonsStyle = {
  padding: 5,
  display: 'flex',
  justifyContent: 'center',
}

const timerStyle = {
  bottom: 0,
  left: 25,
}

interface IVideosProps extends IWithAuthProps {
  /** Historico de navegação */
  history: any
  /** Dados da rota */
  match: any
}

type AvatarName = 'IRIS' | 'HUGO' | 'MAYA'

let AvatarRecorder: HTRecorder
let recorderTimeout: any
let timerInterval: any
let recorderCoreElem: HTMLElement = document.createElement('div')
let recordData = {
  cues: [] as ISignLanguageEditorData[],
  format: 'webm' as VideoOutputFormat,
}

/** Página de geração de vídeos, aqui damos opções de gerenciamento de vídeos, tradução, troca de avatar e exportação */
const Videos: React.FC<IVideosProps> = ({ auth }) => {
  const [key, setKey] = useState('')
  const [isRecording, setIsRecording] = useState(false)
  const [loading, setLoading] = useState(true)
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [openCustomizer, setOpenCustomizer] = useState(false)
  const [resolution, setResolution] = useState(480)
  const [backgroundColor, setBackgroundColor] = useState('#00ff00')
  const [timer, setTimer] = useState(0)
  const [avatar, setAvatar] = useState<AvatarName>('HUGO')
  const [language, setLanguage] = useState('bzs')
  const isCustomizableAvatar = avatar === 'IRIS'

  useEffect(() => {
    recorderCoreElem.style.width = '100%'
    recorderCoreElem.style.height = '100%'
    initHTRecorder()

    return () => {
      clearInterval(timerInterval)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   * Carrega scripts e inicia o HTRecorder
   * */
  const initHTRecorder = async () => {
    await HTRecorder.injectScripts()
    await loadHTRecorder()

    updateCoreElem(isRecording)
  }

  const loadAvatar = async (newAvatar: AvatarName = avatar) => {
    await AvatarRecorder.loadAvatar(newAvatar)
  }

  const loadHTRecorder = async (newAvatar: AvatarName = avatar) => {
    setAvatar(newAvatar)
    const config = {
      parentElement: recorderCoreElem,
      token: htRecorderToken,
      width: 400,
      height: 400,
    }

    if (recorderCoreElem.firstElementChild){
      recorderCoreElem.firstElementChild.remove()
    }

    AvatarRecorder = new HTRecorder(config)

    await AvatarRecorder.loadHTRecorder()
  }

  /**
   * Seleciona em qual elemento o core deverá aparecer
   */
  const updateCoreElem = (recording: boolean) => {
    const elemView = document.getElementById('AvatarHTView')
    const elemRecorder = document.getElementById('AvatarRecorder')
    const currentElem = recording ? elemRecorder : elemView

    if (currentElem) currentElem.appendChild(recorderCoreElem)
  }

  useEffect(() => {
    // Esperamos o dialog ser rendizado para colocar o avatar
    setTimeout(() => {
      updateCoreElem(isRecording)
      if (isRecording) {
        const { cues, format } = recordData
        // Esperamos 1 segundo para o já não aparecer sinalizando no modal
        if (cues && format) {
          recorderTimeout = setTimeout(() => {
            // Essa função, no useEffect, está executando mais de uma vez
            AvatarRecorder!.record(cues, format, {
              onSignalized: handleVideoGenerated,
            })
          }, 1000)
        }
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRecording])

  const handleOpenCustomizer = () => {
    setOpenCustomizer(true)
  }

  const handleCustomizerClosed = () => {
    setOpenCustomizer(false)
  }

  const handleCustomizerApply = (custom: ICoreCustom) => {
    if (AvatarRecorder) {
      AvatarRecorder.setCoreCustom(custom)
    }
    setOpenCustomizer(false)
  }

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false)
  }

  /**
   * Comportamento para aplicar a resolução no core
   */
  const handleApplyResolution = () => {
    // Limita a resolução
    const limitedResolution = Math.min(
      Math.max(resolution, minResolution),
      maxResolution,
    )

    // Caso a resolução tenha sido limitada, redefinimos
    if (limitedResolution !== resolution) setResolution(limitedResolution)

    if (AvatarRecorder) AvatarRecorder.resize(limitedResolution)
  }

  /**
   * Comportamento para alterar a resolução do core
   */
  const handleChangeResolution = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const resolution = parseInt(event.target.value)

    setResolution(resolution)
  }

  /**
   * Comportamento para altera a cor de fundo do core
   */
  const handleChangeBackgroundCoreColor = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const backgroundColor = event.target.value as string
    setBackgroundColor(backgroundColor)

    if (AvatarRecorder) AvatarRecorder.setCoreBackground(backgroundColor)
  }

  /**
   * Comportamento para rotacionar o core no eixo y
   */
  const handleRotateCore = () => {
    if (AvatarRecorder) AvatarRecorder.rotateCore()
  }

  const handleVideoGeneratorClose = () => {
    clearTimeout(recorderTimeout)
    if (AvatarRecorder) {
      AvatarRecorder.stop()
      AvatarRecorder.stopRecording(true)
    }

    setIsRecording(false)
  }

  /**
   * Cria um intervalo de para contabilizar o tempo de animação das frases
   */
  const setTimerInterval = () => {
    let time = 0
    const firstDate = new Date()
    timerInterval = setInterval(() => {
      time = differenceInSeconds(new Date(), firstDate)
      setTimer(time)
    }, 500)
  }

  /**
   * Comportamento para sinzalizar uma única cue, geralmente é chamado para previsualizar uma parte da legenda
   * @param cue - Cue que será reproduzida
   */
  // TODO - Analisar sinalização
  const handleSingleSignCue = (glosas: any[], interpretationSpeed: number) => {
    clearInterval(timerInterval)
    debug.log(
      `Reproduzindo ${glosas.length} glosas na velocidade ${interpretationSpeed}`,
    )

    if (glosas.length) {
      setTimerInterval()
      AvatarRecorder.signGlosa(
        glosas.map((x: any) => `${x.type === 'simple-sign' ? x.id : x.text}`),
        interpretationSpeed,
        { onSignalized: () => clearInterval(timerInterval) },
        language,
      )
    }
  }

  const signAllCues = (cues: ISignLanguageEditorData[]) => {
    clearInterval(timerInterval)
    if (AvatarRecorder && cues) {
      AvatarRecorder.stop()
      debug.log(`Sinalizando ${cues.length} cue(s)`)
      setTimerInterval()
      AvatarRecorder.signAllCues(cues, {
        onSignalized: () => clearInterval(timerInterval),
      })
    }
  }

  /**
   * Lida com o vídeo generado
   */
  const handleVideoGenerated = () => {
    updateCoreElem(false)
    setIsRecording(false)
    setOpenSnackbar(true)
  }

  const recordCues = async (
    cues: ISignLanguageEditorData[],
    format: VideoOutputFormat,
  ) => {
    if (AvatarRecorder && cues && format) {
      AvatarRecorder.stop()
      debug.log(`Gravando ${cues.length} cue(s)`)
      recordData = {
        cues,
        format,
      }
      setIsRecording(true)
    }
  }

  const selectLanguageHandler = (event: any) => {
    const value = event.target.value
    return setLanguage(value)
  }

  const languageSelect = (): JSX.Element => (
    <FormControl style={{ minWidth: 120 }} variant="outlined">
      <InputLabel id="demo-simple-select-standard-label">Língua</InputLabel>
      <Select
        value={language}
        label="Selecione uma língua"
        style={{ height: '30px' }}
        onChange={e => selectLanguageHandler(e)}
      >
        <MenuItem value="bzs">Libras</MenuItem>
        <MenuItem value="asl">ASL</MenuItem>
      </Select>
    </FormControl>
  )

  /** Barra que aparece no topo da página */
  const toolbar = (loading: boolean): JSX.Element => (
    <AppBar
      color="default"
      style={{
        background: 'white',
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        boxShadow: 'none',
        minWidth: 800,
        position: 'relative',
      }}
    >
      <Toolbar style={{ padding: 0 }}>
        <div style={{ marginLeft: '8px', width: '50%' }}>
          <CaptionsSearcher
            loading={loading}
            setKey={setKey}
            setLoading={setLoading}
            languageSelect={languageSelect}
            language={language || 'bzs'}
          />
        </div>
      </Toolbar>
    </AppBar>
  )

  const user = auth.state.user

  if (!user) throw new Error('Usuário inexistente!')

  return (
    <Layout toolbar={toolbar(loading)}>
      <Fade
        in={loading}
        style={{
          transitionDelay: loading ? '100ms' : '0ms',
        }}
      >
        <LinearProgress data-testid="progressbar" />
      </Fade>

      <Grid
        item
        container
        style={{
          padding: 4,
          overflow: 'auto',
        }}
      >
        {/** Tabela de edição de cues */}
        <Grid
          item
          lg={9}
          md={9}
          style={{
            ...captionsEditorGridStyle,
            flexWrap: 'wrap',
          }}
        >
          {!loading && key && (
            <CueEditor
              signCallback={handleSingleSignCue}
              signAllCues={signAllCues}
              recordCues={recordCues}
              captionKey={key}
              language={language || 'bzs'}
            />
          )}
        </Grid>

        {/** Visualização do vídeo e avatar  */}
        <Grid item lg={3} md={3} style={{ padding: 4 }}>
          <Paper style={paperStyle}>
            <div style={{ position: 'relative' }}>
              <div style={coreViewStyle} id="AvatarHTView" />
              <p
                style={{
                  ...timerStyle,
                  fontWeight: 'bold',
                  position: 'absolute',
                }}
              >
                {timer}s
              </p>
            </div>

            <div style={{ ...buttonsStyle, flexDirection: 'column' }}>
              <TextField
                label="Cor de fundo"
                inputProps={{ type: 'color' }}
                value={backgroundColor}
                onChange={handleChangeBackgroundCoreColor}
                fullWidth
                style={{ marginBottom: 10 }}
              />

              <TextField
                label="Resolução"
                value={resolution}
                inputProps={{ type: 'number' }}
                onChange={handleChangeResolution}
                onBlur={handleApplyResolution}
                fullWidth
              ></TextField>

              <FormControl style={{ marginTop: 10 }}>
                <InputLabel>Avatar</InputLabel>
                <Select
                  value={avatar}
                  onChange={({ target }) =>
                    target.value !== avatar &&
                    loadAvatar(target.value as AvatarName)
                  }
                >
                  <MenuItem value={'HUGO'}>Hugo</MenuItem>
                  <MenuItem value={'IRIS'}>Iris</MenuItem>
                  <MenuItem value={'MAYA'}>Maya</MenuItem>
                </Select>
              </FormControl>

              <div style={buttonsStyle}>
                <Button color="default" onClick={handleRotateCore}>
                  <RotateLeftIcon />
                  <span>Girar 90°</span>
                </Button>
                <Button
                  color="default"
                  disabled={isCustomizableAvatar}
                  onClick={handleOpenCustomizer}
                >
                  <BrushIcon />
                  <span>Aplicar customização</span>
                </Button>
              </div>
            </div>
          </Paper>
        </Grid>
      </Grid>

      {/** tela de geração de vídeo */}
      <VideoGenerator open={isRecording} onClose={handleVideoGeneratorClose}>
        <div style={coreRecordStyle} id="AvatarRecorder" />
      </VideoGenerator>

      <CoreCustomizer
        open={openCustomizer}
        onClose={handleCustomizerClosed}
        onApply={handleCustomizerApply}
        avatar={avatar}
      ></CoreCustomizer>

      <Snackbar
        open={openSnackbar}
        onClose={handleCloseSnackbar}
        message={<span>Mídia gerada com sucesso!</span>}
      />
    </Layout>
  )
}

export default requireAuth(Videos)
