import {
  ccaptureScriptUrl,
  glosaApiToken,
  htcoreScriptUrl,
  functionsApi,
} from './constants'
import logger from './logger'
import {
  ICoreCustom,
  ISignLanguageEditorData,
  VideoOutputFormat,
} from './types'
import { injectScript } from './utils'

const debug = logger('HTRecorder')

// eslint-disable-next-line @typescript-eslint/interface-name-prefix
interface HTMLCanvasElement extends Element {
  captureStream: (n: number) => MediaStream
}

interface IHTRecorderCallbacks {
  onSignalized?: () => void
}

/** Gerencia uma instancia do Recorder da Hand Talk com uma camada extra de captura de frames do canvas */
export default class HTRecorder {
  private core?: any
  private config: any
  private playingInterval: any
  private canvas?: HTMLCanvasElement
  private currentRotation = 0
  private capturer?: any
  private isRecording = false
  private onEndCallback?: () => void
  private cues: any[] = []

  private fps = 60

  /**
   * @param config - Configurações do HTRecorder
   */
  constructor(config: any) {
    this.config = config
  }

  /** Injeta scripts necessários para gravação */
  public static injectScripts = async () => {
    debug.log('Injetando ccapture.js')
    // TODO - Adicionar ccapture em formato de modulo, atualmente estamos tendo alguns problemas então fizemos do modo mais facil
    await injectScript(document.body, ccaptureScriptUrl)
    debug.log('Injetando htcore...')
    await injectScript(document.body, htcoreScriptUrl)
  }

  /** Sinaliza uma sequencia de glosas em uma determinada velocidade */
  public signGlosa = async (
    glosas: string[],
    speed: number,
    callbacks?: IHTRecorderCallbacks,
    lang = 'bzs',
  ) => {
    const animations = await this.glosaToAnimation(glosas.join(' '), lang)

    if (!animations.error) {
      this.core.setSpeed(speed)
      this.core.signAnimationCodes(animations, false, false, callbacks)
    }
  }

  public resize = (resolution: number) => {
    this.core.resize(resolution, resolution)
  }

  /** Carrega um array de glosas e sinaliza uma sequencia de glosas em um determinado tempo */
  public signAllCues = async (
    cues: ISignLanguageEditorData[],
    callbacks?: IHTRecorderCallbacks,
  ) => {
    this.cues = cues
    const animations = this.cues.reduce(
      (acc, x) => acc.concat(x.animationCodes),
      [],
    )
    this.core.signAnimationCodes(animations, true, false, callbacks)
  }

  /** Para a execuções de animações */
  public stop = () => {
    this.isRecording = false
    debug.log('Player interrrompido')
    clearInterval(this.playingInterval)

    if (this.core.loadStatus === 'LOADED') {
      // Resetamos a velocidade do Core para garantir evitar problemas futuros
      this.core.setSpeed(1)
      this.core.stop()
    }
  }

  /** Executa todas as cues gravando o canvas gerando o vídeo no formato webm */
  public record = (
    cues: ISignLanguageEditorData[],
    format: VideoOutputFormat,
    callbacks: IHTRecorderCallbacks,
  ) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    this.capturer = new CCapture({
      format,
      workersPath: 'js/',
      frameRate: this.fps,
    })

    this.stop()

    this.onEndCallback = callbacks.onSignalized

    this.signAllCues(cues, {
      onSignalized: () => setTimeout(this.stopRecording, 500),
    })

    this.isRecording = true
    this.capturer.start()
  }

  /** Aplica uma customização no core */
  public setCoreCustom = (coreCustom: ICoreCustom) => {
    if (!this.core) return

    this.core.applyCustomization(coreCustom)
  }

  /** Altera a cor de fundo do core */
  public setCoreBackground = (color: string) => {
    if (!this.core) return

    this.core.setBackgroundColor(color)
  }

  /** Aplica uma rotação de +90° no eixo y do core */
  public rotateCore = () => {
    if (!this.core) return

    this.currentRotation += 90

    if (this.currentRotation === 360) this.currentRotation = 0

    const radians = (this.currentRotation * Math.PI) / 180

    this.core.setRotation(radians)
  }

  public loadAvatar = async (newAvatar: string) => {
    await this.core.changeAvatar(newAvatar, (error: any) => {
      console.log(error)
    })
  }

  /** Carrega o Hand Talk Recorder */
  public loadHTRecorder = async () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    this.core = new HTCore({
      ...this.config,
      onRenderFrame: this.handleOnFrameRender,
    })
    await this.core.loadAllAvatars()

    this.core.setBackgroundColor('rgb(0,255,0)')
    this.canvas = this.core.canvas

    if (this.canvas) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      this.canvasStream = this.canvas.captureStream(60)
    }
  }

  /**
   * Requisita ao servidor da Hand Talk a animação de uma sequencia de glosas
   * @param glosas - Array de glosas
   */
  public glosaToAnimation = async (glosas: string, lang: string) => {
    const encodedGlosa = encodeURIComponent(glosas)

    const postData = new URLSearchParams(`q=${encodedGlosa}&lang=${lang}`)
    const response = await fetch(
      `${functionsApi}translations/glosaToAnimation`,
      {
        body: postData,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          token: glosaApiToken || '',
        },
        method: 'POST',
      },
    )
    // Pega a resposta em plainText
    const plainTextResponse = await response.text()
    try {
      // Transforma para json
      const jsonResponse = JSON.parse(plainTextResponse)

      // retorna um objeto com as informações do erros
      return jsonResponse
    } catch {
      // Cada pedaço da animação vem separado pelo delimitador $
      const animations = plainTextResponse.split('$')
      // Adicionamos repouso como ultimo sinal
      animations.push('@repouso')

      return animations
    }
  }

  /** Chamado sempre que um frame for renderizado pelo Core */
  private handleOnFrameRender = () => {
    if (this.isRecording && this.core.canvas) {
      this.capturer.capture(this.core.canvas)
    }
  }

  /** O recorder chegou ao fim da gravação e iremos salvar os dados */
  public stopRecording = (dontUseCallback = false) => {
    this.isRecording = false
    this.capturer.stop()
    if (this.onEndCallback && !dontUseCallback) {
      this.capturer.save()
      this.onEndCallback()
    }
    this.core.signAnimationCodes(['@repouso'])
  }
}
