import React from 'react'

import { Grid, Paper } from '@material-ui/core'

interface IGridProps {
  form: React.ReactNode
}

const FormGrid: React.SFC<IGridProps> = props => {
  return (
    <Grid direction="row" container>
      <Grid item xs={12}>
        <Paper
          style={{
            border: '1px solid #e8e8e8',
            borderRadius: 0,
            boxShadow: 'none',
            height: '100%',
            padding: 15,
            paddingTop: 30,
          }}
        >
          {props.form}
        </Paper>
      </Grid>
    </Grid>
  )
}

export default FormGrid
