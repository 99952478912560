import React from 'react'
import { Typography } from '@material-ui/core'
import {
  MobileContainer,
  DesktopContainer,
} from '../ResponsiveContainers/ResponsiveContainers'

const GlobalTypography = (props: any) => {
  return (
    <>
      <MobileContainer>
        <Typography {...props} style={{ fontSize: '1.3em', ...props.style }}>
          {props.children}
        </Typography>
      </MobileContainer>
      <DesktopContainer>
        <Typography {...props}>{props.children}</Typography>
      </DesktopContainer>
    </>
  )
}

export default GlobalTypography
