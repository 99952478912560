import { fbRef } from '../../../../services/firebase'
import { SnapTypes } from '../Types/NotificationFormTypes'

const historyPath = 'app/pushs/history'

export const listHistory = (): Promise<SnapTypes> => {
  return new Promise((resolve, reject) => {
    try {
      fbRef.child(historyPath).on('value', snap => {
        let result: SnapTypes = {}

        if (snap && snap.val()) {
          result = snap.val()
        }

        resolve(result)
      })
    } catch (err) {
      reject(err)
    }
  })
}
