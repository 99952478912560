import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Input,
  Grid,
  Card,
  Typography,
  CardMedia,
  CardContent,
  LinearProgress,
} from '@material-ui/core'
import React, { Fragment, useState, useEffect } from 'react'
import {
  ICoreCustom,
  IFunctionsSubscriptionCustom,
  ISubscriptionCustom,
} from '../../../services/types'
import { storage } from '../../../services/firebase'
import logger from '../../../services/logger'

const { error } = logger('CoreCustomizer')

// Utilizamos require para importar o json tipado
const customs: IFunctionsSubscriptionCustom[] = []

interface ICoreCustomizerProps {
  open: boolean
  onClose: () => void
  onApply: (custom: ICoreCustom) => void
  avatar: string
}

type CustomType =
  | 'shortSleeveShirt'
  | 'longSleeveShirt'
  | 'longSleeveShirtWithoutTie'
  | 'isShirt'

/** Este componente prepara o objeto de customização do ht-core */
const CoreCustomizer: React.FC<ICoreCustomizerProps> = ({
  open,
  onClose,
  onApply,
  avatar,
}) => {
  const [customType, setCustomType] = useState('longSleeveShirt')
  const [textureUrl, setTextureUrl] = useState('')
  const [downloadedCustoms, setDownloadedCustoms] = useState(false)
  const [customsError, setCustomsError] = useState(false)
  const [selectedPresetIndex, setSelectedPresetIndex] = useState<number | null>(
    null,
  )

  const cardDefaultStyle = {
    cursor: 'pointer',
  }

  const cardSelectedStyle = {
    background: 'lightblue',
  }

  const handleChangeCustomType = (event: React.ChangeEvent<{}>) => {
    setCustomType((event.target as HTMLInputElement).value as CustomType)
  }

  const handleChangeTextureUrl = (event: React.ChangeEvent<{}>) => {
    setTextureUrl((event.target as HTMLInputElement).value as string)
  }

  const handleApplyPreset = (
    custom: ISubscriptionCustom,
    presetindex: number,
  ) => {
    const textureUrl =
      (custom.manga_longa_sem_gravata &&
        custom.manga_longa_sem_gravata !== 'hide' &&
        custom.manga_longa_sem_gravata) ||
      (custom.manga_longa &&
        custom.manga_longa !== 'hide' &&
        custom.manga_longa) ||
      (custom.manga_curta &&
        custom.manga_curta !== 'hide' &&
        custom.manga_curta) ||
      ''

    const customType: CustomType =
      (custom.manga_longa_sem_gravata &&
        custom.manga_longa_sem_gravata !== 'hide' &&
        'longSleeveShirtWithoutTie') ||
      (custom.manga_longa &&
        custom.manga_longa !== 'hide' &&
        'longSleeveShirt') ||
      'shortSleeveShirt'

    setCustomType(customType)
    setTextureUrl(textureUrl)
    setSelectedPresetIndex(presetindex)
  }

  const handleApply = () => {
    const custom: ICoreCustom = { [customType]: textureUrl }

    onApply(custom)
  }

  const handleLoadCustoms = async () => {
    setCustomsError(false)
    setDownloadedCustoms(false)

    try {
      const url = await storage
        .ref('utils/subscriptionsCustomInfo.json')
        .getDownloadURL()
      const response = await fetch(url)
      const data: IFunctionsSubscriptionCustom[] = await response.json()
      customs.push(...data)
      setDownloadedCustoms(true)
    } catch (_error) {
      setCustomsError(true)
      error(_error)
    }
  }

  useEffect(() => {
    if (!open) return
    if (customs.length === 0) handleLoadCustoms()
    else setDownloadedCustoms(true)
    // eslint-disable-next-line react/no-deprecated
  }, [open])

  return (
    <div>
      {/** Não utilizamos `open` para impedir que o elemento do Core seja destruido */}
      <Dialog open={open} fullWidth maxWidth="lg">
        <DialogTitle>Aplicar customização</DialogTitle>
        <DialogContent>
          <Fragment>
            <FormControl>
              <FormLabel component="caption">Tipo de camisa</FormLabel>
              <RadioGroup value={customType} onChange={handleChangeCustomType}>
                {avatar === 'MAYA' ? (
                  <FormControlLabel
                    value="isShirt"
                    control={<Radio />}
                    label="Camiseta da Maya"
                  />
                ) : (
                  <>
                    <FormControlLabel
                      value="shortSleeveShirt"
                      control={<Radio />}
                      label="Curta"
                    />
                    <FormControlLabel
                      value="longSleeveShirt"
                      control={<Radio />}
                      label="Longa"
                    />
                    <FormControlLabel
                      value="longSleeveShirtWithoutTie"
                      control={<Radio />}
                      label="Longa sem gravata"
                    />
                  </>
                )}
              </RadioGroup>
            </FormControl>
            <Input
              placeholder="URL da Textura"
              value={textureUrl}
              onChange={handleChangeTextureUrl}
              fullWidth
            />

            <Grid container spacing={8}>
              <Grid item lg={12}>
                <Typography variant="body1" paragraph></Typography>
                <Typography variant="body1" paragraph>
                  Presets
                </Typography>
              </Grid>
            </Grid>

            <Grid container spacing={8}>
              {downloadedCustoms &&
                avatar !== 'MAYA' &&
                !customsError &&
                customs.map(({ title, custom }, index) => (
                  <Grid
                    item
                    lg={2}
                    key={index}
                    onClick={() => handleApplyPreset(custom, index)}
                  >
                    <Card
                      style={
                        index === selectedPresetIndex
                          ? cardSelectedStyle
                          : cardDefaultStyle
                      }
                    >
                      <CardMedia
                        component="img"
                        image={
                          (custom.manga_longa_sem_gravata &&
                            custom.manga_longa_sem_gravata !== 'hide' &&
                            custom.manga_longa_sem_gravata) ||
                          (custom.manga_longa &&
                            custom.manga_longa !== 'hide' &&
                            custom.manga_longa) ||
                          (custom.manga_curta &&
                            custom.manga_curta !== 'hide' &&
                            custom.manga_curta) ||
                          ''
                        }
                      />
                      <CardContent>
                        <Typography noWrap variant="body1" component="h6">
                          {title}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}

              {!downloadedCustoms && !customsError && (
                <Grid item lg={12}>
                  <LinearProgress />
                </Grid>
              )}

              {!downloadedCustoms && customsError && (
                <Fragment>
                  <Grid item lg={12}>
                    <Typography variant="subtitle1" paragraph>
                      Falha ao carregar presets
                    </Typography>
                  </Grid>
                  <Grid item lg={12}>
                    <Button onClick={handleLoadCustoms}>
                      Clique aqui para tentar novamente
                    </Button>
                  </Grid>
                </Fragment>
              )}
            </Grid>
          </Fragment>
        </DialogContent>
        <DialogActions>
          <Button color="default" onClick={onClose}>
            Cancelar
          </Button>
          <Button
            color="default"
            disabled={textureUrl === ''}
            onClick={handleApply}
          >
            Aplicar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default CoreCustomizer
