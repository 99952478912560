import { IScheduledData, LangValues } from '../Types/NotificationFormTypes'

export const between = (target: number, init: number, end: number) => {
  return target <= end && target >= init
}

export const capitalize = (s: string) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

export const validateEmail = (email: string): boolean => {
  const emailRegex = /^[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+$/
  return emailRegex.test(email)
}

export const shortenName = (title: string, group: string) => {
  const timeStamp = new Date().getTime() // Number of ms since Jan 1, 1970
  const uid =
    group
      .split(' ')
      .join('_')
      .toLowerCase() +
    '_' +
    title
      .split(' ')
      .join('_')
      .toLowerCase() +
    '_' +
    timeStamp
  return uid
}

export const getPushLang = (push: IScheduledData): LangValues => {
  const ptReg = new RegExp('((Portuguese)[ ]?[w]*)')
  // const enReg = new RegExp('((English)[ ]?[w]*)')

  const group = push.body.group

  return ptReg.test(group) ? 'portuguese' : 'english'
}
