import React, { useState } from 'react'
import AuthContainer, { ILoginError, requireUnauth } from '../../services/containers/AuthContainer'
import styled from 'styled-components'
import { Button, TextField, Card } from '@mui/material';

interface AuthProps {
  auth: AuthContainer
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const StyledCard = styled(Card)`
  width: 100%;
  max-width: 360px;
  margin-inline: 12px;
  padding: 24px;
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Title = styled.h1`
  margin: 0;
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
`;

const ErrorText = styled.p`
  font-size: 14px;
  color: red;
  margin: 0;
  align-self: center;
`;

// @requireUnauth()
const Auth: React.FC<AuthProps> = ({ auth }) => {
  const [emailError, setEmailError] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [generalError, setGeneralError] = useState('')

  const handleError = ({ error, field, message }: ILoginError) => {
    if (!error) return
    if (!field || !message) {
      setGeneralError('Não autorizado')
      return
    }

    const setFieldErrorMap = {
      'email': setEmailError,
      'password': setPasswordError,
      'global': setGeneralError
    }

    const setFieldError = setFieldErrorMap[field]
    setFieldError(message)
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    setEmailError('')
    setPasswordError('')
    setGeneralError('')

    const email = event.currentTarget.email.value
    const password = event.currentTarget.password.value

    const loginResponse = await auth.login({ email, password })
    handleError(loginResponse)
  }

  return (
    <Wrapper>
      <StyledCard>
        <Form onSubmit={handleSubmit}>
          <Title>Entre com seu email e senha</Title>

          <TextField
            variant="standard"
            size="small"
            id="email"
            type='email'
            label="Email"
            error={!!emailError}
            helperText={emailError}
          />
          <TextField
            variant="standard"
            size="small"
            id="password"
            type='password'
            label="Senha"
            error={!!passwordError}
            helperText={passwordError}
          />

          <ErrorText>{generalError}</ErrorText>

          <Button variant="contained" type='submit'>Entrar</Button>
        </Form>
      </StyledCard>
    </Wrapper>
  )
}

export default requireUnauth(Auth)
