import { coinPtbr, colors, months } from '../../../../services/helper'

export const titleGraphic: any = [
  'Assinaturas x Ramo',
  'MRR x Ramo',
  'Consumo Médio Mensal',
]

// consumir o gráfico do Assinaturas x Ramo
export const graphicBranch = (data: any) => {
  const branchData: any = []
  const date = new Date()
  const monthCurrent = date.getMonth() + 1

  data.map((item: any) => {
    // essa condição descarta o objeto que não tem valor
    if (monthCurrent !== item.month) {
      branchData.push({
        name: item.branch,
        type: 'pizza',
        value: item.subscriptions,
      })
    }
  })

  // essa função sort serve para ordenar o array, a condição abaixo está ordenando pelo maior value
  branchData.sort((a: any, b: any) => {
    return a.value < b.value ? 1 : b.value < a.value ? -1 : 0
  })

  return branchData
}

// consumir o gráfico das mrr x Ramo
export const graphicBrachRecipe = (data: any) => {
  const branchDataRecipe: any = []
  const date = new Date()
  const monthCurrent = date.getMonth() + 1

  data.map((item: any) => {
    let mrr = item.mrr.replace('R$', '')
    mrr = mrr.replace('.', '')
    mrr = mrr.replace(',', '.')

    // essa condição descarta o objeto que não tem valor
    if (monthCurrent !== item.month) {
      branchDataRecipe.push({
        mrr: item.mrr,
        name: item.branch,
        percentage: item.mrrPercentage,
        type: 'pizzaRecipe',
        value: parseFloat(mrr),
      })
    }
  })

  // essa função sort serve para ordenar o array, a condição abaixo está ordenando pelo maior value
  branchDataRecipe.sort((a: any, b: any) => {
    return a.value < b.value ? 1 : b.value < a.value ? -1 : 0
  })

  return branchDataRecipe
}

// consumir o gráfico do Consumo Médio
export const graphicConsumed = (data: any) => {
  const consumedData: any = []
  const date = new Date()
  const monthCurrent = date.getMonth() + 1

  Object.values(data).map((item: any, i: any) => {
    // essa condição descarta o objeto que não tem valor
    if (monthCurrent !== item.month) {
      consumedData.push({
        color: [colors.blueBaby],
        consumed: coinPtbr(parseFloat(item.data.consumed)),
        label: 'consumo',
        month: months[i],
        name: months[i],
        text: ['consumed'],
        type: 'bar',
      })
    }
  })

  return consumedData
}
