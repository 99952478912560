import Grid from '@material-ui/core/Grid'
import React, { Component } from 'react'
import { Cell, Legend, Pie, PieChart, Tooltip } from 'recharts'
import { optionOne } from '../../../../services/helper'
import { CustomTooltipSubscriptions } from './CustomTooltipSubscriptions'

/**
 * objeto do SubscriptionsAnalyticsBlock
 */
export interface ISubscriptionsAnalyticsBlock {
  data?: any[]
  classes?: any
  keyPie: number
  title?: string
  text?: string[]
  color?: string[]
}

class Chart extends Component<ISubscriptionsAnalyticsBlock> {
  public state = {
    hasName: '',
    stroke: '',
    strokeWidth: 0,
  }

  // função para customizar o label do gráfico pie
  public renderCustomizedLabel = (props: any) => {
    const RADIAN = Math.PI / 180
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      percent,
      payload,
    } = props

    const radius = innerRadius + (outerRadius - innerRadius) * 0.84
    const x = cx + radius * Math.cos(-midAngle * RADIAN)
    const y = cy + radius * Math.sin(-midAngle * RADIAN)
    const textAnchor = x >= 0 ? 'middle' : 'end'

    const value =
      payload.type === 'pizza'
        ? `${(percent * 100).toFixed(0)}%`
        : payload.percentage

    return (
      <g>
        <text
          x={x}
          y={y}
          textAnchor={textAnchor}
          fontFamily={'Roboto, Helvetica, Arial, sans-serif'}
          fontSize={15}
          fontWeight={400}
          fill="#333"
        >
          {value}
        </text>
      </g>
    )
  }

  public render() {
    const { data = [] } = this.props
    return (
      <Grid
        item
        xs
        style={{
          padding: 4,
        }}
      >
        <PieChart
          width={720}
          height={580}
          style={{
            fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
            fontWeight: 400,
            margin: '0 auto',
          }}
        >
          <Pie
            dataKey="value"
            data={data}
            cx={470}
            cy={310}
            labelLine={false}
            label={this.renderCustomizedLabel}
            outerRadius={240}
            minAngle={10}
            fill="#8884d8"
            fontFamily={'Roboto, Helvetica, Arial, sans-serif'}
            fontWeight={400}
          >
            {data.map((_entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={optionOne[index % optionOne.length]}
              />
            ))}
          </Pie>
          <Tooltip content={<CustomTooltipSubscriptions />} />
          <Legend
            iconSize={10}
            width={250}
            layout="vertical"
            verticalAlign="middle"
            wrapperStyle={{
              color: 'black',
              fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
              fontWeight: 400,
              left: 15,
              lineHeight: '20px',
              top: 20,
              fontSize: '14px',
            }}
          />
        </PieChart>
      </Grid>
    )
  }
}

export default Chart
