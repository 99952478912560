/**
 * 🚧🚧🚧 ATENÇÃO 🚧🚧🚧
 * Este arquivo está originalmente em:
 * ./tools/shared/classes/User.ts
 * Se você encountrou em outro lugar, ele é um link-simbólico. Ao modificar
 * este arquivo, tome cuidado para que outros projetos não sejam quebrados!
 */

 import {
    erroTokenDeAnimadorInvalido,
    erroTokenDeAnimadorObrigatorio,
  } from '../services/errors'
  import { fbRef } from '../services/firebase'
  
  import logger from '../services/logger'
  import { IUserAnimator, IUserPrivate, UserRole } from '../services/types'
  
  const { log } = logger('User')
  
  class User {
    /**
     * Destrava um token de animador. Só funciona para admins.
     * @param authorization Token ou authorization de uma requisição.
     */
    public static async unlockAnimatorToken(tokenOrAuthorization = '') {
      const token = tokenOrAuthorization.startsWith('Bearer ')
        ? tokenOrAuthorization.split('Bearer ')[1]
        : tokenOrAuthorization
  
      // Joga erro se não existir
      if (!token) {
        throw erroTokenDeAnimadorObrigatorio
      }
  
      log('Destravando topublic ken do animador')
      const snap = await fbRef
        .child('users/animatorsTokens')
        .child(token)
        .once('value')
  
      const value = snap.val()
  
      if (!value) {
        throw erroTokenDeAnimadorInvalido
      }
  
      return snap.val() as IUserAnimator
    }
    public userId: string
  
    public constructor(userId: string) {
      this.userId = userId
    }
  
    /**
     * Retorna uma promessa do perfil privado do usuário.
     * @async
     */
    public async getPrivate(): Promise<IUserPrivate> {
      const snap = await fbRef
        .child('users/private')
        .child(this.userId)
        .once('value')
      return snap.val()
    }
  
    /**
     * Retorna o valor do papel do usuário.
     */
    public async getRole() {
      const snap = await fbRef
        .child('users/roles')
        .child(this.userId)
        .once('value')
  
      return (snap.val() || 'client') as UserRole
    }
  
    /**
     * Seta o papel do usuário.
     * @param role Papel do usuário
     */
    public async setRole(role: UserRole) {
      const roleRef = fbRef.child('users/roles').child(this.userId)
  
      // O papel padrão é 'client'. Se for setar para 'client', remova.
      if (role === 'client') {
        return await roleRef.remove()
      }
  
      return await roleRef.set(role)
    }
  }
  
  export default User