import {
  AppBar,
  Toolbar,
  LinearProgress,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
} from '@material-ui/core'
import React, { useState } from 'react'

import Layout from '../../../components/Layout'
import SchedulerScreen from './Content/SchedulerScreen'
import HistoryScreen from './Content/HistoryScreen'

/**
 * Todo: 
 * 
 * [x] Arrumar ação do botão de enviar push, tanto em enviar agora, quanto em enviar por email
//  * [ ] Refatorar a página de histórico, adicionando:
 *     [x] Card com botão de mais informações do Push
//  *     [ ] Pegar dados de push do big query, como quantas pessoas visualizaram e abriram
 *     [x] Criar um campo de pesquisa por; Data, Nome, Língua & GMT
 */

const Pushs = () => {
  const [formMode, setFormMode] = useState<'scheduler' | 'history'>('scheduler')
  const [progress, onProgress] = useState(false)

  const handleFormMode = ({ target }: any) => {
    setFormMode(target.value)
  }

  const toolbar = (
    <AppBar
      color="default"
      style={{
        background: 'white',
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        boxShadow: 'none',
        position: 'relative',
      }}
    >
      <Toolbar style={{ padding: 0 }}>
        <FormControl style={{ minWidth: 120, marginLeft: 10 }}>
          <InputLabel id="form-mode-select-label">Modo</InputLabel>
          <Select
            value={formMode}
            onChange={handleFormMode}
            displayEmpty={false}
            labelId="form-mode-select-label"
            id="form-mode-select"
            label="Modo"
          >
            <MenuItem value={'scheduler'}>Agendar/Disparar</MenuItem>
            <MenuItem value={'history'}>Histórico</MenuItem>
          </Select>
        </FormControl>
      </Toolbar>

      <LinearProgress style={{ display: progress ? 'block' : 'none' }} />
    </AppBar>
  )

  return (
    <Layout toolbar={toolbar}>
      <div style={{ padding: 10, overflow: 'auto' }}>
        {formMode == 'scheduler' ? (
          <SchedulerScreen onProgress={onProgress} />
        ) : (
          <HistoryScreen />
        )}
      </div>
    </Layout>
  )
}

export default Pushs
