import React from 'react'

import { AppBar, Tabs, Tab } from '@material-ui/core'

const tabsStyle = {
  borderLeft: '1px solid #e8e8e8',
  borderRight: '1px solid #e8e8e8',
  borderTop: '1px solid #e8e8e8',
}

type IFormOptions = {
  value: number
  onChange: (event: React.ChangeEvent<{}>, value: number) => void
  tabs: string[]
  color: 'primary' | 'secondary'
  elevation?: number
}

const FormOptions: React.SFC<IFormOptions> = ({
  value,
  onChange,
  tabs,
  color,
  elevation,
}) => {
  return (
    <AppBar
      position="static"
      color="default"
      elevation={elevation ? elevation : 0}
      style={elevation ? {} : tabsStyle}
    >
      <Tabs
        value={value}
        onChange={onChange}
        indicatorColor={color}
        textColor={color}
        variant="fullWidth"
      >
        {tabs.map((label, key) => (
          <Tab label={label} key={key} />
        ))}
      </Tabs>
    </AppBar>
  )
}

export default FormOptions
