import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core'
import React, { useState } from 'react'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'

interface IFormatSelectorProps {
  open: boolean
  onClose: () => void
  onSelect: (origin: string) => void
}

/** Componente para selecionar um formato ao exportar dados */
const FormatSelector: React.FC<IFormatSelectorProps> = ({
  open,
  onClose,
  onSelect,
}) => {
  const [contentOrigin, setContentOrigin] = useState('plugin')

  const handleChange = (event: React.ChangeEvent<any>) => {
    setContentOrigin((event.target as HTMLInputElement).value)
  }

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
      open={open}
    >
      <DialogTitle id="confirmation-dialog-title">
        Selecionar origem do JSON
      </DialogTitle>
      <DialogContent>
        <RadioGroup
          aria-label="ringtone"
          name="Origem"
          value={contentOrigin}
          onChange={handleChange}
        >
          <FormControlLabel
            value={'plugin'}
            key={0}
            control={<Radio />}
            label={'Plugin'}
          />
          <FormControlLabel
            value={'app'}
            key={1}
            control={<Radio />}
            label={'Aplicativo'}
          />
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onClose} color="primary">
          Cancelar
        </Button>
        <Button onClick={() => onSelect(contentOrigin)} color="primary">
          Gerar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default FormatSelector
