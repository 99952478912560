import { Typography } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import React, { Component } from 'react'
import { coinPtbr } from '../../../../services/helper'

interface ICustomTooltipSubscriptionsProps {
  active?: boolean
  label?: string
  payload?: any[]
  type?: string
}

export class CustomTooltipSubscriptions extends Component<
  ICustomTooltipSubscriptionsProps
> {
  public content = () => {
    const { payload = [] } = this.props

    return payload.map((tooltip: any, index: any) => {
      let convertValue = ''
      if (tooltip.payload.type === 'pizzaRecipe') {
        const real = Number(coinPtbr(Math.round(tooltip.value)))
        convertValue =
          tooltip.value > 1000 ? `R$ ${real.toFixed(0)} k` : `R$ ${real}`
      }

      return (
        <Typography
          className="label"
          key={index}
          style={{
            fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '1.46429em',
            margin: 10,
          }}
        >
          {tooltip.payload.type !== 'pizzaRecipe'
            ? `${tooltip.name}: ${tooltip.value}`
            : `${tooltip.name}: ${convertValue}`}
        </Typography>
      )
    })
  }

  public render() {
    const { payload = [] } = this.props

    if (payload) {
      return (
        <Grid
          className="custom-tooltip"
          style={{
            background: '#ffffffde',
            padding: 6,
          }}
        >
          {this.content()}
        </Grid>
      )
    }

    return null
  }
}
