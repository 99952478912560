import Axios from 'axios'
import { FetchedUser } from '../Types/NotificationFormTypes'
import { mobileUserByEmailUrl } from '../../../../services/constants'

export const fetchUserFromEmail = (email: string): Promise<FetchedUser> => {
  const url = mobileUserByEmailUrl

  return Axios.post(url, {
    email: email,
    token: 'e7p3Ddm8aeZ8ZJ5349J1IQM3UySErWGk',
  })
}
