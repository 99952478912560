import React, { useState, useEffect } from 'react'

import {
  Typography,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
  Avatar,
  Tooltip,
  Divider,
} from '@material-ui/core'
import { ExpandMore, ExpandLess } from '@material-ui/icons'
import { Notifications } from '@material-ui/icons'

import NotificationForm from './Content/NotificationForm'
import FormGrid from '../FormGrid'
import ScheduleList from '../Components/SchedulesList'
import { IScheduledData } from '../../Types/NotificationFormTypes'
import FormOptions from '../Components/FormOptions'
import { getPushLang } from '../../Utils'

interface IScheduler {
  progress?: boolean
  onProgress: React.Dispatch<React.SetStateAction<boolean>>
}

const SchedulerScreen: React.SFC<IScheduler> = ({ onProgress }) => {
  useEffect(() => onProgress(false), [onProgress])

  const [pushGroup, setPushGroup] = useState(0)
  const [listOpened, setListOpened] = useState(false)
  const [pushToEdit, setPushToEdit] = useState<IScheduledData>()

  const getGroupName = (): string => {
    return pushGroup == 0 ? 'Portuguese' : pushGroup == 1 ? 'English' : 'E-mail'
  }

  const handlePushToEdit = (uid: string, push: IScheduledData) => {
    const pushGroupName = getPushLang(push)
    setPushGroup(pushGroupName == 'portuguese' ? 0 : 1)

    push.id = uid
    setPushToEdit(push)
    setListOpened(false)
  }

  return (
    <div>
      <div style={{ marginBottom: 20 }}>
        <Card
          style={{
            // padding: 10,
            border: '1px solid #e8e8e8',
            borderRadius: 0,
            boxShadow: 'none',
          }}
        >
          <CardHeader
            avatar={
              <Avatar variant="rounded" aria-label="recipe">
                <Notifications />
              </Avatar>
            }
            title="Push Notifications"
            subheader="ALGUMAS INFORMAÇÕES"
          />
          <Divider />
          <CardContent>
            <Typography variant="body2" color="textSecondary" component="p">
              Esse formulário customizado será utilizado para o envio das
              notificações para o aplicativo mobile da Hand Talk, isso é,
              Android e iOS.
            </Typography>
            <br />
            <Typography variant="body2" color="textSecondary" component="p">
              Foi necessário abandonar o formulário do Google para as
              notificações, visando uma maior flexibilidade e autonomia do time
              de marketing. Nesse novo formulário será possível o agendamento de
              futuros pushs, isso é, não será necessário disparar o push na
              mesma hora, assim como uma liberdade para editar ou excluir as
              notificações já agendadas.
            </Typography>
          </CardContent>
          <CardActions>
            <Tooltip
              title="Listar pushs agendados"
              aria-label="list-scheduleds"
            >
              <Button
                onClick={() => setListOpened(!listOpened)}
                size="small"
                color="primary"
                endIcon={listOpened ? <ExpandLess /> : <ExpandMore />}
              >
                Agendamentos
              </Button>
            </Tooltip>
          </CardActions>
        </Card>

        <ScheduleList hidden={!listOpened} setPushToEdit={handlePushToEdit} />
      </div>

      <FormOptions
        color="primary"
        value={pushGroup}
        onChange={(event, value) => {
          setPushGroup(value)
        }}
        tabs={['Português', 'Inglês', 'E-mail']}
      />

      <FormGrid
        form={
          <NotificationForm
            pushToEdit={pushToEdit}
            cancelEditMode={() => setPushToEdit(undefined)}
            groupSelected={getGroupName()}
            onProgress={onProgress}
          />
        }
      />
    </div>
  )
}

export default SchedulerScreen
