import React, { useEffect, useState } from 'react'

import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  Avatar,
  IconButton,
  ListItemText,
  makeStyles,
  Theme,
  Divider,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
  LinearProgress,
  Typography,
} from '@material-ui/core'

import { format } from 'date-fns'
import { Create, Delete } from '@material-ui/icons'
import { listSchedules, cancelScheduled } from '../../Functions/Scheduler'
import {
  IScheduledData,
  FilteredSchedulesType,
  LangValues,
} from '../../Types/NotificationFormTypes'
import { ptBR } from 'date-fns/esm/locale'
import { capitalize } from '../../Utils'
import FormOptions from './FormOptions'

const PushsDialog: React.SFC<{
  open: boolean
  onAgree: () => void
  onClose: () => void
}> = props => {
  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {'Deseja excluir o agendamento?'}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Ao excluir o disparo do push, não será possível restaurar. Deseja
          prosseguir?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} color="primary" autoFocus>
          Cancelar
        </Button>
        <Button
          onClick={() => {
            props.onAgree()
            props.onClose()
          }}
          color="primary"
        >
          Excluir
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    border: '1px solid #e8e8e8',
    backgroundColor: theme.palette.background.paper,
  },
  mt15: {
    marginTop: 15,
  },
}))

const ScheduleList: React.SFC<{
  hidden: boolean
  setPushToEdit: (uid: string, push: IScheduledData) => void
}> = ({ setPushToEdit, hidden }) => {
  const classes = useStyles()
  const [dialog, showDialog] = useState(false)
  const [loading, setLoading] = useState(true)
  const [pushToCancel, setPushToCancel] = useState<string>()
  const [scheduled, setScheduled] = useState<FilteredSchedulesType>({
    portuguese: {},
    english: {},
  })

  const [strLang, setStrLang] = useState<LangValues>('portuguese')
  const [lang, selectLang] = useState(0)

  const handleScheduledCancel = async () => {
    if (pushToCancel) {
      setLoading(true)

      cancelScheduled(pushToCancel)
        .catch(err => {
          console.error(err)
        })
        .finally(() => setLoading(false))
    }
  }

  const buildScheduledList = (key: string, index: number, array: string[]) => {
    const data = scheduled[strLang][key as keyof {}]

    const { body, scheduleInfo } = data as IScheduledData
    const { dateTime, timezone } = scheduleInfo

    const formatedGMT = (): string => {
      if (timezone.toString().charAt(0) == '-') return 'GMT' + timezone
      return 'GMT+' + timezone
    }
    const formatedDate = capitalize(
      format(new Date(dateTime), "EEEE, dd 'de' MMMM 'de' yyyy 'às' HH:mm", {
        locale: ptBR,
      }),
    )

    const handleEdit = (key: string) => {
      setPushToEdit(key, scheduled[strLang][key as keyof {}])
    }

    return (
      <div>
        <ListItem key={index}>
          <ListItemAvatar>
            <Avatar variant="rounded" src={body.imageURL}>
              {body.title.charAt(0)}
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={body.title}
            secondary={formatedDate + ' | ' + formatedGMT()}
          />

          <ListItemSecondaryAction>
            <Tooltip title="Editar" aria-label="edit-push">
              <IconButton edge="start" onClick={() => handleEdit(key)}>
                <Create />
              </IconButton>
            </Tooltip>
            <Tooltip title="Cancelar" aria-label="cancel-push">
              <IconButton
                edge="start"
                style={{ marginLeft: 5 }}
                onClick={() => {
                  setPushToCancel(key)
                  showDialog(true)
                }}
              >
                <Delete />
              </IconButton>
            </Tooltip>
          </ListItemSecondaryAction>
        </ListItem>

        {index != array.length - 1 && (
          <Divider variant="inset" component="li" />
        )}
      </div>
    )
  }

  const buildListItems = () => {
    const sSchedules = scheduled[strLang]
    const sLen = Object.keys(sSchedules).length

    if (sLen > 0) {
      return Object.keys(sSchedules).map(buildScheduledList)
    } else {
      return (
        <Typography
          style={{ margin: 10 }}
          align="center"
          variant="body1"
          color="textSecondary"
        >
          Sem Registros
        </Typography>
      )
    }
  }

  useEffect(() => {
    listSchedules(setScheduled).then(() => {
      setLoading(false)
    })
  }, [])

  return (
    <div style={{ display: hidden ? 'none' : 'block' }}>
      <PushsDialog
        open={dialog}
        onClose={() => showDialog(false)}
        onAgree={() => handleScheduledCancel()}
      />
      {loading ? (
        <LinearProgress />
      ) : (
        (() => {
          if (scheduled) {
            return (
              <div className={classes.mt15}>
                <FormOptions
                  color="secondary"
                  value={lang}
                  onChange={(event, value) => {
                    setStrLang(value == 0 ? 'portuguese' : 'english')
                    selectLang(value)
                  }}
                  tabs={['Português', 'Inglês']}
                />
                <List dense={true} className={classes.root}>
                  {buildListItems()}
                </List>
              </div>
            )
          }
        })()
      )}
    </div>
  )
}

export default ScheduleList
