import React from 'react'

import { Grid, Button, Typography } from '@material-ui/core'
import { IScheduledData } from '../../../Types/NotificationFormTypes'

interface ISubmitButtons {
  pushToEdit: IScheduledData | undefined
  sendDisabled: boolean
  groupSelected: string
  handleUpdate: () => Promise<void>
  handleCancelEditMode: () => void
  setDialogVisible: (value: React.SetStateAction<boolean>) => void
}

const SubmitButtons: React.SFC<ISubmitButtons> = props => {
  return (
    <Grid
      container
      direction="row"
      style={{ marginTop: 20, position: 'relative' }}
    >
      {!props.pushToEdit ? (
        <Grid
          direction="row"
          style={{
            display: 'flex',
            flex: 1,
            justifyContent: 'space-between',
          }}
        >
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={props.sendDisabled}
            style={{
              display: props.groupSelected === 'E-mail' ? 'none' : 'block',
              marginRight: 15,
              paddingLeft: 32,
              paddingRight: 32,
            }}
          >
            Agendar
          </Button>

          <Button
            disabled={props.sendDisabled}
            onClick={() => props.setDialogVisible(true)}
            variant={
              props.groupSelected === 'E-mail' ? 'contained' : 'outlined'
            }
            color={props.groupSelected === 'E-mail' ? 'primary' : 'secondary'}
            style={{
              paddingLeft: 32,
              paddingRight: 32,
            }}
          >
            Disparar
          </Button>
        </Grid>
      ) : (
        <React.Fragment>
          <Button
            onClick={props.handleUpdate}
            variant="contained"
            color="primary"
            disabled={props.sendDisabled}
            style={{
              marginRight: 15,
              paddingLeft: 32,
              paddingRight: 32,
            }}
          >
            Salvar Modificações
          </Button>

          <Button
            onClick={() => props.handleCancelEditMode()}
            variant="contained"
            color="secondary"
            disabled={props.sendDisabled}
            style={{
              paddingLeft: 32,
              paddingRight: 32,
            }}
          >
            Cancelar
          </Button>

          <Grid item xs={12} style={{ marginTop: 2 }}>
            <Typography variant="caption" color="textSecondary">
              {props.pushToEdit.id}
            </Typography>
          </Grid>
        </React.Fragment>
      )}
    </Grid>
  )
}

export default SubmitButtons
